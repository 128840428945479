import { useState } from "react";
import TemplatesList from "./TemplatesList";
import TemplateSteps from "./TemplateSteps";
import "./BrowseTemplatesPage.scss";

function BrowseTemplatesPage(props) {
  const [selectedTemplate, setSelectedTemplate] = useState();

  function changeSelectedTemplate(template) {
    setSelectedTemplate(template);
  }

  return (
    <div className="scrollable columns is-gapless">
      <TemplatesList
        selectedTemplateId={selectedTemplate?.id}
        onChangeSelectedTemplate={changeSelectedTemplate}
      />

      {selectedTemplate && (
        <TemplateSteps
          selectedTemplate={selectedTemplate}
          handleAddTemplateSteps={props.handleAddTemplateSteps}
        />
      )}

      {!selectedTemplate && (
        <div className="block m-4">
          Select a template from the list to get inspired and add steps to your
          task.
        </div>
      )}
    </div>
  );
}

export default BrowseTemplatesPage;
