import { useState } from "react";
import "./CompleteTaskModal.scss";
import Confetti from "react-confetti";
import { Steps, Step, useSteps } from "react-step-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import SaveThoughtForm from "../Process/Strategies/utils/SaveThoughtForm";
import { history } from "../../util/router";

function CompleteTaskModal(props) {
  const { prev, next, hasNext, hasPrev } = useSteps();

  function handleCloseAndGoBack() {
    props.onDone();
    history.push("/app/tasks");
  }

  return (
    <div className="modal is-active">
      <Confetti recycle={false} gravity={0.3} />
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="CompleteTaskModal__card card">
        <header className="card-header">
          <p className="card-header-title">
            Task Completed:
            <span className="has-text-weight-light mx-1">
              {props.taskData.name}
            </span>
          </p>

          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>

        <div className="m-4 title is-flex is-justify-content-center">
          Let's celebrate by saving your learnings from this task!
        </div>
        {/* <div className="subtitle is-flex is-justify-content-center mx-3">
          You can save them just for future-you OR share them for your peers to
          benefit from them too.
        </div> */}

        <Steps>
          <div className="m-4">
            <div className="subtitle has-text-weight-semibold">
              What would you like to tell your future self to{" "}
              <span style={{ color: "#23d160" }}>remember for next time</span>?
            </div>
            <div className="has-text-weight-semibold">
              Situation or feeling blocking you{" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                size="s"
                className="mr-2 ml-1 mt-1"
              />
              What you did to get unblocked
            </div>
            <SaveThoughtForm
              placeholder="Whenever you are too tired to think, take a break and come back with fresh eyes."
              // showSharePeers={true}
            />
          </div>
          {/* <div className="m-5">
            <div className="subtitle has-text-weight-semibold">
              Are there any{" "}
              <span style={{ color: "#3273dc" }}>
                new resources that you discovered
              </span>{" "}
              that might be helpful next time?
            </div>
            <div className="m-2">
              <label className="label">Expert to Ask</label>
              <div className="control">
                <input
                  className="input"
                  autoComplete="off"
                  type="text"
                  placeholder="Diana W."
                  // onChange={onChangeHandler}
                  // value={nameInput}
                />
              </div>
            </div>
            <div className="m-2">
              <label className="label">Source to Research</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                                    autoFocus={true}
                  placeholder="doc website X"
                  // onChange={onChangeHandler}
                  // value={nameInput}
                />
              </div>
            </div>
            <div className="m-2">
              <label className="label">Action to Take</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  placeholder="Look at PRs from our previous implementation"
                  // onChange={onChangeHandler}
                  // value={nameInput}
                />
              </div>
            </div>
            <div className="is-flex is-align-items-center">
              <label className="checkbox m-2">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={true}
                  // onClick={handleClickCompleted}
                />
                {" Share with my peers"}
              </label>
              <button
                className="m-2 button is-primary"
                //onClick={() => setBrowsingTemplates(true)}
              >
                Save as Resources
              </button>
            </div>
          </div> */}

          {/* <div className="m-5">
            <div className="subtitle has-text-weight-semibold">
              Would you like to save your steps as a template?
            </div>
            <div className="p-2">
              {props.taskData.stepsData.map((step) => {
                return (
                  <div className="m-2 is-flex is-align-items-center">
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="s"
                      className="mx-2 mt-1"
                    />
                    <div className="control is-flex is-flex-grow-1">
                      <input
                        className="input"
                        type="text"
                        autoComplete="off"
                  autoFocus={true}
                        // onChange={onChangeHandler}
                        value={step.content}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="is-flex is-align-items-center">
              <label className="checkbox m-2">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={true}
                  // onClick={handleClickCompleted}
                />
                {" Share with my peers"}
              </label>
              <button
                className="m-2 button is-primary"
                //onClick={() => setBrowsingTemplates(true)}
              >
                Save as Template
              </button>
            </div>
          </div> */}
        </Steps>

        <div className="is=flex">
          <div className={"p-3 is-flex "}>
            <button
              className="button mx-1 is-light"
              onClick={prev}
              disabled={!hasPrev}
            >
              <i className="fa fa-chevron-left mx-2"></i>
              Prev
            </button>
            <button
              className="button mx-1 is-light"
              onClick={next}
              disabled={!hasNext}
            >
              Next
              <i className="fa fa-chevron-right mx-2"></i>
            </button>
          </div>
        </div>

        <footer className="modal-card-foot justify-end">
          {/* <button className="button">Cancel</button> */}
          <button className="button is-success" onClick={handleCloseAndGoBack}>
            Close and go back to task board
          </button>
        </footer>
      </div>
    </div>
  );
}

export default CompleteTaskModal;
