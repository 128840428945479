import styled from "styled-components";

interface Props {
  beingRemoved?: boolean;
  isSelectingDate?: boolean;
  checked?: boolean;
}

const StepStyles = styled.li<Props>`
  list-style: none;

  display: flex;
  flex-direction: row;

  width: 100%;

  align-items: center;
  justify-content: space-between;

  padding: 10px 0;
  padding-left: 20px;
  padding-right: 32px;

  margin-top: 10px;

  border-radius: 20px;

  background-color: ${(props) => props.theme.fg};

  transition: 0.25s ease;
  transition-property: background-color opacity transform;

  animation: ${(props) =>
      props.beingRemoved ? "taskSlideUp" : "taskSlideDown"}
    0.25s ease;

  .left {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: fit-content;

    align-items: center;

    gap: 20px;
    padding: 10px 0;

    label {
      width: 30px;
      height: 30px;

      cursor: pointer;

      input {
        display: none;
      }

      .infoStep {
        border-color: #66a6fa;
        border-style: solid;
      }
      .checkbox-div {
        display: grid;
        place-items: center;

        width: 30px;
        height: 30px;

        border-radius: 10px;

        background-color: ${(props) =>
          props.checked ? props.theme.black : props.theme.bold};

        transition: 0.25s ease;
        transition-property: transform background-color;

        animation: ${(props) =>
          props.checked ? "taskBounce .25s ease forwards" : ""};

        ::before {
          content: "";
          box-sizing: unset;
          display: inline-block;

          height: 3px;
          width: 6px;

          border-radius: 2px;

          border-bottom: 4px solid white;
          border-left: 4px solid white;

          opacity: ${(props) => (props.checked ? "1" : "0")};
          transform: ${(props) =>
            props.checked
              ? "rotate(-45deg) scale(1)"
              : "rotate(-45deg) scale(0)"};

          transition: 0.25s ease;
          transition-property: transform opacity;
        }
      }
    }

    h3 {
      position: relative;

      width: 100%;
      height: fit-content;

      word-break: break-word;
      white-space: normal;

      color: ${(props) => props.theme.text};

      ::before {
        content: "";

        position: absolute;

        top: 50%;
        left: 0;

        width: ${(props) => (props.checked ? "100%" : "0%")};
        height: 2px;

        border-radius: 1px;

        background-color: ${(props) => props.theme.text};

        transition: 0.25s ease 0.25s;
        transition-property: width;
      }
    }
  }

  .right {
    position: relative;

    display: flex;
    flex-direction: row;

    width: fit-content;

    white-space: nowrap;

    align-items: center;

    padding-left: 27px;
  }

  @media (max-width: 768px) {
    border-radius: 15px;

    padding-left: 15px;

    .left {
      gap: 15px;
      padding: 5px;

      label {
        width: 20px;
        height: 20px;

        .checkbox-div {
          width: 20px;
          height: 20px;

          border-radius: 5px;

          ::before {
            height: 2px;
            width: 4px;

            border-bottom: 3px solid white;
            border-left: 3px solid white;
          }
        }
      }
    }
  }

  @keyframes taskSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes taskSlideUp {
    0% {
      opacity: 1;
      transform: translateY(0px);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  @keyframes taskBounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export default StepStyles;
