import React from "react";
import PropTypes from "prop-types";

function EditableLabel(props) {
  // getText = (el) => {
  //   return el.innerText;
  // };

  // onPaste = (ev) => {
  //   ev.preventDefault();
  //   const value = ev.clipboardData.getData("text");
  //   document.execCommand("insertText", false, value);
  // };

  // const getClassName = () => {
  //   const placeholder =
  //     this.state.value === ""
  //       ? "comPlainTextContentEditable--has-placeholder"
  //       : "";
  //   return `comPlainTextContentEditable ${placeholder}`;
  // };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      console.log("enter");
      //enter
      props.onTitleEnter();
      e.preventDefault();
    }
  };
  //   if (e.keyCode === 27) {
  //     this.refDiv.value = this.props.value;
  //     this.setState({ value: this.props.value });
  //     // this.refDiv.blur()
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }
  // };
  return (
    <input
      className="input is-small"
      autoComplete="off"
      autoFocus={true}
      type="text"
      placeholder={props.placeholder}
      //className={getClassName()}
      // onPaste={onPaste}
      // onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={props.onChange}
    />
  );
}
export default EditableLabel;
