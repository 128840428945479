import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm";

function SWorriesIntoVision(props) {
  const [worryInput, setWorryInput] = useState(undefined);
  const onChangeWorryHandler = (event) => {
    setWorryInput(event.target.value);
  };

  const examplesWorries = [
    [
      "I am worried I will not be able to do this right, that I don't know how. That my incompetence will show through. That it will take too long. That I can't bring myself to do it.",
    ],
    [
      "I am worried that I will not find the source of the bug and take too long to send a PR and my team will think I am not capable enough.",
    ],
    [
      "I'm worried they will think it is too late to answer. That they will think I am dumb by not replying the right thing.",
    ],
  ];

  const examplesVisions = [
    [
      "I crushed it! I accepted that it had to be done and that other people also don't know how to do it when they first try. I wrote down all the possible steps that I could take to investigate and learn more. I took action right away and began focusing on one step at a time. Writing down my learnings as I went. Eventually, something clicked and I figured it out. I felt great to have accomplished it and get it out of the way.",
    ],
    [
      "I solved the issue fast! I wrote down all my hypothesis and tried them one by one. I stepped through the code with the debugger to understand the flow. If I got stuck, I asked for help as soon as it made sense. I figured it out in an appropriate amount of time.",
    ],
    [
      "It turned out ok. I apologized for being OOF. I gathered all the data I had from before. I sent what I had, I asked if they needed more from me. I was happy to be done.",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            Transform your worries into a visualization of success by using your
            imagination.
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Write down worries*/}
          <div className="boxStepSubtitle">
            Write down your worries and fears
          </div>
          <div className="columns">
            <div className="column is-6">
              Write about everything that you are <b>worried might go wrong</b>{" "}
              in this task, and <b>don't hold back</b>. Let it all out:
              <div className="control m-2">
                <textarea
                  autoFocus={true}
                  className="textarea"
                  type="text"
                  defaultValue={"I'm worried that..."}
                  // key={}
                  onChange={onChangeWorryHandler}
                  value={worryInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by examples from others in your industry:
              </div>
              <CarouselExamples examples={examplesWorries} />
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Rewrite success*/}
          <div className="boxStepSubtitle">
            Use your imagination to tell a different story
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              <div className="mb-3">
                Now, <b>use your imagination</b> and write (in past tense) about{" "}
                <b>how you were able to successfully accomplish the task</b>.
                Try to include as many details as possible about how you were
                able to avoid potential dangers and pitfalls and describe how
                success felt:
              </div>
              <div className="mb-4 mx-3 p-3 recall">{worryInput}</div>
              <SaveThoughtForm
                defaultValue={"It turned out a huge success. What I did is..."}
              />
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by stories from others in your industry:
              </div>
              <CarouselExamples examples={examplesVisions} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SWorriesIntoVision;
