import { useEffect } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import "./PricingSection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Mixpanel } from "../util/mixpanel";

function PricingSection(props) {
  const auth = useAuth();

  useEffect(() => {
    Mixpanel.track("pricingPage loaded", {
      user: auth.user?.email,
    });
  }, []); //run only once

  // If changing the products or price, remember to update it in stripe
  // and copy over the new ids to .env and on vercel for prod
  const items = [
    {
      id: "month",
      name: "Monthly",
      price: "15",
      // perks: [
      //   " Full range of emotions",
      //   " Access to all strategies",
      //   " New feelings and strategies added every week",
      //   " Increase your wellbeing and productivity at work",
      // ],
      perks: [
        "Access proven strategies to overcome emotional blocks while staying engaged in your work.",
        "Explore the full spectrum of emotions that are common productivity blockers in your industry.",
        //"Gain a deeper understanding of the emotions that are common productivity blockers in your industry.",
        "New strategies added to our library every week, based on the latest research and suggestions from your industry peers.",
      ],
    },
    // {
    //   id: "year",
    //   name: "Year",
    //   price: "120",
    //   perks: [
    //     " Create unlimited tasks and thoughts",
    //     " Access to all strategies",
    //     " New strategies added every week",
    //     " Save 33% vs paying monthly",
    //   ],
    // },
  ];

  return (
    <Section
      id="pricing"
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <header
          className={
            "SectionHeader" +
            ("has-text-centered" ? ` ${"has-text-centered"}` : "")
          }
        >
          {props.subtitle && (
            <p className={" SectionHeader__subtitle is-size-4"}>
              <div>{props.subtitle}</div>
            </p>
          )}
          <h1 className={"has-text-weight-bold is-size-3"}>{props.title}</h1>
          {props.subtitle2 && (
            <p className={"subtitle is-5"}>
              <div>{props.subtitle2}</div>
            </p>
          )}
        </header>
        <article class="message is-info mobile-only">
          <div class="message-body">
            <strong>FocusFeel may work better in a larger window.</strong> We
            are currently in beta and our layout works better in a desktop
            browser window for now. Improved mobile layout coming soon!
          </div>
        </article>
        <div className="columns is-centered is-variable is-4 is-desktop">
          {items.map((item, index) => (
            <div
              className="PricingSection__column column is-three-fifths-desktop"
              key={index}
            >
              <div className={"PricingSection__card card overflowHidden"}>
                <div className="PricingSection__card-content card-content">
                  {item.mostPopular && <div class="ribbon">Most Popular</div>}
                  <div className="PricingSection__name is-size-4 has-text-centered">
                    {item.name}
                  </div>
                  <div className="PricingSection__price has-text-weight-bold is-size-1 has-text-centered">
                    ${item.price}
                  </div>
                  {item.description && (
                    <p className="PricingSection__description">
                      {item.description}
                    </p>
                  )}
                  {item.perks && (
                    <ul className="PricingSection__perks px-5">
                      {item.perks.map((perk, index) => (
                        <li key={index}>
                          <i className="fas fa-check has-text-primary mr-2" />
                          {perk}
                        </li>
                      ))}
                    </ul>
                  )}
                  <Link
                    className="PricingSection__button button is-medium is-success is-fontcolor-light1"
                    to={
                      auth.user
                        ? `/purchase/${item.id}`
                        : `/auth/signup?next=/purchase/${item.id}`
                    }
                  >
                    Try for free
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      size="s"
                      className="mx-2 mt-1"
                    />
                  </Link>
                  <div className="has-text-centered is-size-7 mt-1">
                    Cancel anytime
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default PricingSection;
