import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../../util/auth.js";
import { updateTask } from "../../../util/db.js";
import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
  Footer,
} from "./styles/Base";
import InlineInput from "./widgets/InlineInput";
import Tag from "./Tag";
import DeleteButton from "./widgets/DeleteButton";
import { PushContext } from "../../../pages/tasks.js";
import { DoneTasksContext } from "./TaskBoardView.js";

function TaskCard(props) {
  const {
    showDeleteButton,
    style,
    tagStyle,
    onClick,
    //onDelete,
    onChange,
    className,
    id,
    title,
    label,
    description,
    tags,
    cardDraggable,
    editable,
    t,
  } = props;

  const auth = useAuth();

  const onDelete = (e) => {
    props.onDelete();
    e.stopPropagation();
  };

  const updateCard = (card) => {
    onChange({ ...card, id });
  };

  function handleUpdateCardTitle(value) {
    updateCard({ title: value });
    updateTask(auth.user.uid, id, { name: value });
  }

  let isPush = useContext(PushContext);
  let doneTasksIds = useContext(DoneTasksContext);
  function getDisplayForTag(tag, cardId) {
    if (
      (isPush && tag.key.startsWith("ease")) ||
      (!isPush && tag.key.startsWith("push"))
    ) {
      return "none";
    }
    // keep context with array of done tasks ids
    // if id is included in it, return none
    if (doneTasksIds.includes(cardId)) {
      return "none";
    }
  }

  function getColorForCard(cardId) {
    if (doneTasksIds.includes(cardId)) {
      return "#a19f9d";
    }
  }

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={onClick}
      style={{ ...style, color: getColorForCard(id) }}
      className={className}
    >
      <CardHeader>
        <CardTitle
          draggable={cardDraggable}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {editable ? (
            <InlineInput
              value={title}
              border
              //placeholder={"task title"}
              resize="vertical"
              onSave={(value) => handleUpdateCardTitle(value)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ) : (
            title
          )}
        </CardTitle>
        {showDeleteButton && <DeleteButton onClick={onDelete} />}
      </CardHeader>

      {tags && tags.length > 0 && (
        <Footer>
          {tags.map((tag) => (
            <Tag
              {...tag}
              tagStyle={{ ...tagStyle, display: getDisplayForTag(tag, id) }}
            />
          ))}
        </Footer>
      )}
    </MovableCardWrapper>
  );
}

TaskCard.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
};

TaskCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: "no title",
  description: "",
  label: "",
  tags: [],
  className: "",
};

export default TaskCard;
