import mixpanel from "mixpanel-browser";

// Always keep in production mode, change ONLY locally for testing!
const isProd = process.env.NODE_ENV === "production";
//const isProd = true; //for testing

mixpanel.init("75c21e7aaf47354580a31e8d3bc3bb4e", {
  // Use your project's URL, adding a slug for all Mixpanel requests
  api_host: "https://proxy-gnmgzsg3oq-nn.a.run.app",
});

export const Mixpanel = {
  identify: (id) => {
    if (isProd) mixpanel.identify(id);
  },
  alias: (id) => {
    if (isProd) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (isProd) {
      mixpanel.track(name, props);
      //console.log("mixpanel track name: ", name);
    }
  },
  track_links: (query, name) => {
    if (isProd)
      mixpanel.track_links(query, name, {
        referrer: document.referrer,
      });
  },
  people: {
    set: (props) => {
      if (isProd) mixpanel.people.set(props);
    },
  },
};
