import { useState } from "react";
import { updateTask, useTask } from "../../../../util/db.js";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../util/auth.js";
import { v4 as uuid } from "uuid";
import FocusThoughtModal from "../../../Thoughts/FocusThoughtModal.js";
import { Mixpanel } from "../../../../util/mixpanel.js";

function SaveThoughtForm(props) {
  Mixpanel.track("Thought form loaded", {
    defaultValue: props.defaultValue,
  });
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [thoughtInput, setThoughtInput] = useState(props.defaultValue);
  const [savedThought, setSavedThought] = useState("none");
  const [focusingThought, setFocusingThought] = useState(null);
  const [focusChecked, setFocusChecked] = useState(true);

  const { id } = useParams();
  const { data: taskData, status: taskStatus } = useTask(auth.user.uid, id);

  const isThoughtSaved = () => {
    return savedThought === thoughtInput;
  };

  const onChangeThought = (event) => {
    setThoughtInput(event.target.value);
    Mixpanel.track("Thought changed", {
      thought: event.target.value,
    });
  };

  // Changed to uncontrolled so we can know if thought typed is same as saved
  const onAddThought = () => {
    Mixpanel.track("Thought saved from strategy form", {
      thought: thoughtInput,
    });
    setPending(true); //to make button appear loading
    if (id && taskStatus !== "success") {
      return null;
    }

    let newThoughtsData = taskData?.thoughtsData || [];
    //adding new thought
    newThoughtsData?.push({ id: uuid(), content: thoughtInput });

    const query = updateTask(auth.user.uid, id, {
      thoughtsData: newThoughtsData,
    });

    query.then(() => {
      setSavedThought(thoughtInput);
      setPending(false);
      setFocusingThought(thoughtInput);
    });
  };

  return (
    <>
      <div className="m-3">
        <textarea
          className="textarea"
          placeholder={props.placeholder}
          type="text"
          autoComplete="off"
          autoFocus={true}
          onChange={onChangeThought}
          value={thoughtInput}
          defaultValue={props.defaultValue}
        />

        <div className="control is-flex flex-direction-row">
          <button
            className={`button is-small m-2 ${
              isThoughtSaved() ? "is-static" : "is-primary"
            } ${pending ? " is-loading" : ""}`}
            onClick={onAddThought}
          >
            {isThoughtSaved() ? "Thought Saved" : "Save Thought"}
          </button>
          <label className="is-flex is-flex-direction-row is-align-items-center ml-3">
            <input
              type="checkbox"
              defaultChecked={focusChecked}
              onChange={() => setFocusChecked(!focusChecked)}
            />
            <div className="mx-1 mt-1">Focus after save</div>
          </label>
        </div>
      </div>
      {isThoughtSaved() && focusingThought && focusChecked && (
        <FocusThoughtModal
          thoughtContent={focusingThought}
          onDone={() => setFocusingThought(null)}
        />
      )}
    </>
  );
}

export default SaveThoughtForm;
