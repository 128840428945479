import { getRelevantTagsForTask } from "./getTaskTags";

export function convertLegacyTasksToBoardData(tasksToShow) {
  // Split tasks by lanes depending on status property
  // To do, In Progress, Blocked waiting, Done
  // Add tags based on other properties
  // make it backwards compat?

  let tasksToShowCompleted = tasksToShow?.filter(
    (task) => task.completed === true
  );

  let tasksToShowNotCompletedToDo = tasksToShow?.filter(
    (task) => !task.completed && task.laneId !== "wip"
  );

  let tasksToShowNotCompletedWip = tasksToShow?.filter(
    (task) => !task.completed && task.laneId === "wip"
  );

  const cardArrayForDone = tasksToShowCompleted?.map((task, index) => {
    //actual conversion
    return {
      id: task.id,
      title: task.name,
      tags: getRelevantTagsForTask(task),
    };
  });

  const cardArrayForToDo = tasksToShowNotCompletedToDo?.map((task, index) => {
    return {
      id: task.id,
      title: task.name,
      tags: getRelevantTagsForTask(task),
    };
  });

  const cardArrayForWip = tasksToShowNotCompletedWip?.map((task, index) => {
    return {
      id: task.id,
      title: task.name,
      tags: getRelevantTagsForTask(task),
    };
  });

  const boardData = {
    lanes: [
      {
        id: "todo",
        title: "To Do",
        cards: cardArrayForToDo,
      },
      {
        id: "wip",
        title: "Work in Progress",
        cards: cardArrayForWip || [],
      },
      {
        id: "done",
        title: "Done",
        cards: cardArrayForDone,
        //disallowAddingCard: true,
      },
    ],
  };

  return boardData;
}
