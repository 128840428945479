import { useState, useEffect } from "react";
import TaskContent from "../components/TaskContent";
//import { requireAuthAndSubscription, useAuth } from "./../util/auth.js";
import { requireAuth, useAuth } from "./../util/auth.js";
import SidePanel from "./../components/SidePanel";
import { useParams } from "react-router-dom";
import { useTask, updateTask } from "../util/db.js";
import { Link } from "../util/router.js";
import ProcessFeelingModal from "../components/Process/ProcessFeelingModal";
import CompleteTaskModal from "../components/Tasks/CompleteTaskModal";
import { StepsProvider } from "react-step-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Mixpanel } from "../util/mixpanel";
import { moveCardToOrFromDone } from "../components/Tasks/BoardTasks/utils/moveCardToOrFromDone";

function TaskPage(props) {
  const auth = useAuth();
  const { id } = useParams();
  const { data: taskData, status: taskStatus } = useTask(auth.user.uid, id);

  useEffect(() => {
    Mixpanel.track("Single Task loaded");
  }, []); //empty dep to run only once

  const [processingFeeling, setProcessingFeeling] = useState(
    props.location.isExploring || false
  );
  const [preselectedFeeling, setPreselectedFeeling] = useState(null);
  const [preselectedStrategy, setPreselectedStrategy] = useState(null);
  const [stepToProcess, setStepToProcess] = useState(null);

  const [completingTask, setCompletingTask] = useState(false);
  const [pending, setPending] = useState(false);

  function handleProcessFeeling(value, stepName) {
    setProcessingFeeling(value);
    setStepToProcess(stepName);
  }

  function handlePreselectFeeling(feeling) {
    setPreselectedFeeling(feeling);
  }
  function handlePreselectStrategy(strategy) {
    setPreselectedStrategy(strategy);
  }

  const handleCompleteTask = (id) => {
    setPending(true);
    const query = updateTask(auth.user.uid, id, {
      completed: true,
      laneId: "done",
    });
    query
      .then(() => {
        setCompletingTask(true);
        setPending(false);
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
      });

    // Update project.boardData moving task card to done lane (true for isDone)
    moveCardToOrFromDone(id, taskData.projectId, auth.user.uid, true);
  };

  const handleUncompleteTask = (id) => {
    setPending(true);
    const query = updateTask(auth.user.uid, id, {
      completed: false,
    });

    query
      .then(() => {
        setPending(false);
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
      });

    // Update project.boardData moving task card to wip lane (false for isDone)
    moveCardToOrFromDone(id, taskData.projectId, auth.user.uid, false);
  };

  // If we are updating an existing task
  // don't show modal until task data is fetched.
  if (taskStatus !== "success" || taskData === null) {
    return null;
  }

  return (
    <>
      <article class="message is-info mobile-only">
        <div class="message-body">
          <strong>FocusFeel may work better in a larger window.</strong> We are
          currently in beta and our layout works better in a desktop browser
          window for now. Improved mobile layout coming soon!
        </div>
      </article>
      <div className="section py-2 px-3">
        <div className="is-flex is-flex-direction-column">
          <Link to="/app/tasks" className="icon-text mb-3">
            <span className="icon">
              <i className="fas fa-solid fa-arrow-left"></i>
            </span>
            <span>Back to Tasks</span>
          </Link>

          <div className="title is-dark mx-3">{taskData && taskData.name}</div>

          {/* <IndustryCategory
            taskId={taskData.id}
            taskCategory={taskData.category}
          /> */}

          <div className="columns my-1">
            <div className="column is-5 is-flex is-align-items-center">
              {!taskData.completed && (
                <button
                  className={
                    "button is-success is-outlined" +
                    (pending ? " is-loading" : "")
                  }
                  onClick={() => {
                    handleCompleteTask(id);
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} size="s" className="mr-2" />
                  Complete Task
                </button>
              )}

              {taskData.completed && (
                <button
                  className={
                    "button is-outlined mx-1 my-1" +
                    (pending ? " is-loading" : "")
                  }
                  onClick={() => {
                    handleUncompleteTask(id);
                  }}
                >
                  Mark task as Not Complete
                </button>
              )}
            </div>
            <div className="column ">
              <div className="is-flex is-align-items-flex-end is-flex-wrap-wrap">
                <button
                  className="button is-success mx-1 my-1"
                  onClick={() => {
                    Mixpanel.track(
                      "Explore Resistance clicked from Task Page top"
                    );
                    setProcessingFeeling(true);
                  }}
                >
                  Explore Resistance
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-gapless">
        <TaskContent
          color="white"
          size="medium"
          title="Task"
          subtitle="heyo"
          taskData={taskData}
          goal={props.goal}
          handleProcessFeeling={handleProcessFeeling}
          handlePreselectFeeling={handlePreselectFeeling}
          handlePreselectStrategy={handlePreselectStrategy}
        />

        <SidePanel
          taskData={taskData}
          handlePreselectStrategy={handlePreselectStrategy}
          handleProcessFeeling={handleProcessFeeling}
        />
      </div>

      {processingFeeling && (
        <ProcessFeelingModal
          onDone={() => setProcessingFeeling(false)}
          preselectedFeeling={preselectedFeeling}
          preselectedStrategy={preselectedStrategy}
          handlePreselectFeeling={handlePreselectFeeling}
          handlePreselectStrategy={handlePreselectStrategy}
          taskCategory={taskData.category}
          taskId={taskData.id}
          taskName={taskData.name}
          stepToProcess={stepToProcess}
        />
      )}

      {completingTask && (
        <StepsProvider>
          <CompleteTaskModal
            onDone={() => setCompletingTask(false)}
            taskData={taskData}
          />
        </StepsProvider>
      )}
    </>
  );
}

export default requireAuth(TaskPage);
