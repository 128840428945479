import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm";

function FCompassionate(props) {
  const [behaviorInput, setBehaviorInput] = useState(undefined);
  const onChangeBehaviorHandler = (event) => {
    setBehaviorInput(event.target.value);
  };

  const [understandingInput, setUnderstandingInput] = useState(undefined);
  const onChangeUnderstanding = (event) => {
    setUnderstandingInput(event.target.value);
  };

  const [planInput, setPlanInput] = useState(undefined);
  const onChangePlan = (event) => {
    setPlanInput(event.target.value);
  };

  // idea: switch between I and You - default is I
  const [youPhrased, setYouPhrased] = useState("I");

  const [defaultBehavior, setDefaultBehavior] = useState(
    props.defaultIBehavior
  );
  const [defaultUnderstanding, setDefaultUnderstanding] = useState(
    props.defaultIUnderstanding
  );
  const [defaultPlan, setDefaultPlan] = useState(props.defaultIPlan);

  function handleRadioChange(event) {
    setYouPhrased(event.target.value);

    const phrasedBehavior =
      event.target.value === "You"
        ? props.defaultYouBehavior
        : props.defaultIBehavior;
    setDefaultBehavior(phrasedBehavior);

    const phrasedUnderstanding =
      event.target.value === "You"
        ? props.defaultYouUnderstanding
        : props.defaultIUnderstanding;
    setDefaultUnderstanding(phrasedUnderstanding);

    const phrasedPlan =
      event.target.value === "You" ? props.defaultYouPlan : props.defaultIPlan;
    setDefaultPlan(phrasedPlan);
  }

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            <p>
              Practice compassionate self-talk to <b>reduce self-criticism</b>{" "}
              and anxiety. By being <b>kind</b> an <b>understanding</b> towards
              yourself, you can create a more positive and <b>productive</b>{" "}
              mindset.
            </p>
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify behavior*/}
          <div className="boxStepSubtitle">
            Step 1: Acknowledge the <b>behavior</b> and the <b>feeling</b> or
            emotion driving it
          </div>
          <div className="columns">
            <div className="column is-6">
              Remember to state your feelings in a balanced manner, neither
              suppressing nor exaggerating them.
              <div className="control m-2">
                <textarea
                  autoFocus={true}
                  className="textarea"
                  type="text"
                  defaultValue={defaultBehavior}
                  key={defaultBehavior}
                  onChange={onChangeBehaviorHandler}
                  value={behaviorInput}
                />
              </div>{" "}
              <div className="is-flex">
                <div className="mx-4 mb-3">
                  <input
                    type="radio"
                    value="I"
                    name="phrased"
                    checked={youPhrased === "I"}
                    onChange={handleRadioChange}
                  />{" "}
                  "I" phrased
                </div>
                <div className="mx-4 mb-3">
                  <input
                    type="radio"
                    value="You"
                    name="phrased"
                    checked={youPhrased === "You"}
                    onChange={handleRadioChange}
                  />{" "}
                  "You" phrased
                </div>
              </div>
            </div>{" "}
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Here are some examples from others in your industry:
              </div>
              <CarouselExamples examples={props.examplesBehaviors} />
            </div>
          </div>{" "}
        </div>,

        <div>
          {/* 2nd step: Be understanding*/}
          <div className="boxStepSubtitle">
            Step 2: Realize that your feelings and reaction to them are{" "}
            <b>normal</b> and a <b>human</b> universal experience
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              Speak kindly to yourself and acknowledge that feeling emotions and
              reacting/trying to avoid to them is human.
              <div className="mt-4 mx-3 p-3 recall">
                {behaviorInput ? behaviorInput : defaultBehavior}
              </div>
              <div className="control mx-2 my-4">
                <textarea
                  className="textarea"
                  defaultValue={defaultUnderstanding}
                  key={defaultUnderstanding}
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeUnderstanding}
                  value={understandingInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Here are some examples from others in your industry:
              </div>
              <CarouselExamples examples={props.examplesUnderstanding} />
            </div>
          </div>
        </div>,

        <div>
          {/* 3rd step: gentle redirect or game plan*/}
          <div className="boxStepSubtitle">
            Step 3: Come up with a <b>game plan</b> to gently redirect yourself
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6 mx-3">
              <div className="mb-3">
                Remember to be kind to yourself and set realistic expectations
                about what can be accomplished in a given situation.
              </div>
              <div className="mb-4 mx-3 p-3 recall">
                {` ${behaviorInput ? behaviorInput : defaultBehavior} ${
                  understandingInput ? understandingInput : defaultUnderstanding
                }`}
              </div>
              <div className="control mx-2 my-4">
                <textarea
                  className="textarea"
                  defaultValue={defaultPlan}
                  key={defaultPlan}
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangePlan}
                  value={planInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by game plans from others in your industry:
              </div>
              <CarouselExamples examples={props.examplesPlan} />
            </div>
          </div>
        </div>,
        <div>
          {/* 4th step: save complete thought and focus*/}
          <div className="boxStepSubtitle">
            Step 4: Use your <b>kind</b> self-talk for <b>refocusing</b>
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-8 mx-3">
              <div className="mb-3">
                If necessary, adjust your thought and save it. Then take a few
                moments to breathe deeply and redirect your focus to your game
                plan while showing yourself some compassion.
              </div>
              <SaveThoughtForm
                defaultValue={` ${
                  behaviorInput ? behaviorInput : defaultBehavior
                } ${
                  understandingInput ? understandingInput : defaultUnderstanding
                } ${planInput ? planInput : defaultPlan}`}
              />
            </div>
          </div>
          {/*FOCUS FOR SOME TIME*/}
        </div>,
      ]}
    />
  );
}

export default FCompassionate;
