import { updateTask } from "../../../../util/db.js";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useAuth } from "../../../../util/auth.js";
import { Mixpanel } from "../../../../util/mixpanel.js";

function AddStepInput(props) {
  Mixpanel.track("Step form loaded");
  const auth = useAuth();
  const [savedStep, setSavedStep] = useState("none");
  const [stepInput, setStepInput] = useState();
  const [pending, setPending] = useState(false);

  const handleChange = (event) => {
    Mixpanel.track("Step changed", {
      step: event.target.value,
    });
    setStepInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onAddStep();
      event.target.blur();
    }
  };

  const onAddStep = () => {
    Mixpanel.track("Step saved from strategy form", {
      step: stepInput,
    });
    setPending(true); //to make button appear loading

    const newStep = {
      checked: false,
      content: stepInput,
      id: uuid(),
      infoTrigger: false,
    };
    let newStepsData = props.taskData.stepsData.concat(newStep);

    const query = updateTask(auth.user.uid, props.taskData.id, {
      stepsData: newStepsData,
    });

    query.then(() => {
      setSavedStep(stepInput);
      setPending(false);
    });
  };

  const isStepSaved = () => {
    return savedStep === stepInput;
  };

  return (
    <div className="m-3">
      <input
        className={`input ${stepInput ? "" : "is-danger"}`}
        type="text"
        placeholder={props.placeholder}
        value={stepInput}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        autoFocus={true}
      />
      <div className="control">
        <button
          className={`button is-small m-2 ${
            isStepSaved() ? "is-static" : "is-primary"
          } ${pending ? " is-loading" : ""}`}
          onClick={onAddStep}
        >
          {isStepSaved() ? "Step Saved" : "Add as Step"}
        </button>
      </div>
    </div>
  );
}

export default AddStepInput;
