import FrameworkFlipIt from "./FrameworkFlipIt";

function SFlipItOverwhelmed(props) {
  const examplesFlips = [
    [
      "Once I start making progress on the first draft, I will feel less overwhelmed.",
    ],
    [
      "Once I start making progress on building the prototype, I will learn more.",
    ],
    [
      "Once I start making progress on writing the email to ask for help, I will feel braver.",
    ],
    [
      "Once I start making progress on rewriting the tests, I will feel less bored.",
    ],
  ];

  const conditions = [
    "know more",
    "feel less anxious",
    "feel more confident",
    "have more energy",
    "feel more interested",
    "feel braver",
    "feel ready",
    "feel less tired",
  ];

  const initialDesiredCondition = "feel less overwhelmed";

  return (
    <FrameworkFlipIt
      title={props.title}
      examplesFlips={examplesFlips}
      excuses={conditions}
      //   behavior={behavior}
      initialDesiredCondition={initialDesiredCondition}
      encourage={
        "Often, the best way to feel less overwhelmed is to break the task down into smaller pieces and start tackling them one by one, without worrying about the overall enormity of the task."
      }
    />
  );
}

export default SFlipItOverwhelmed;
