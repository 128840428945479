import { useState, useEffect, useRef, createContext } from "react";
//import { requireAuthAndSubscription } from "./../util/auth.js";
import { requireAuth } from "./../util/auth.js";

import Switch from "../controls/Switch.js";
import EditProjectModal from "../components/Tasks/EditProjectModal";
import {
  useProjectsByOwner,
  useLastSelectedByOwner,
  updateLastSelectedByOwner,
} from "../util/db.js";
import { useAuth } from "../util/auth.js";
import { history, useRouter } from "../util/router";
import { useMount, useSetState } from "react-use";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { Mixpanel } from "../util/mixpanel";
import WrapperTaskBoardView from "../components/Tasks/BoardTasks/WrapperTaskBoardView.js";

export const PushContext = createContext(true);

function TasksPage(props) {
  const auth = useAuth();

  if (auth.user && !auth.user.onboarded) {
    history.replace("/app/onboarding");
  }

  // Set up onboarding tour
  const router = useRouter();
  const isTouring = router.query.tour;

  // //hook that re-renders if tour param changes
  useEffect(() => {
    setState({ run: isTouring || false });
  }, [router.query.tour]);

  useEffect(() => {
    Mixpanel.track("My Tasks loaded");
  }, []); //empty dep to run only once

  const [{ run, stepIndex, steps }, setState] = useSetState({
    run: false,
    isModalOpen: false,
    stepIndex: 0,
    steps: [],
  });

  const createTaskButtonRef = useRef(null);

  useMount(() => {
    setTimeout(() => {
      setState({
        run: isTouring || false, //run only when param says to tour
        steps: [
          {
            content: "Add a new task by clicking here",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: true,
            placement: "right",
            spotlightClicks: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: createTaskButtonRef.current,
            title: "Add New Task",
          },
          {
            content:
              "Add a task that you have been avoiding or have some resistance to getting started",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: true,
            placement: "top",
            spotlightClicks: true,
            spotlightPadding: 20,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: ".EditTaskModal__card",
            title: "Fill in task details",
          },
          {
            content: "Click on the task to start working on it",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: true,
            placement: "right",
            spotlightClicks: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: ".tableTasksRows",
            title: "Open Task",
          },
        ],
      });
    }, 500);
  }); // run only once on mount

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false, stepIndex: 0 });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // go to next step
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      // Update state to advance the tour
      setState({
        stepIndex: nextStepIndex,
      });
    }
  };
  // End Onboarding

  //ACTUAL PAGE STUFF
  const {
    data: projects,
    status: projectsStatus,
    error: projectsError,
  } = useProjectsByOwner(auth.user.uid);

  const {
    data: lastSelected,
    status: lastSelectedStatus,
    error: lastSelectedError,
  } = useLastSelectedByOwner(auth.user.uid);

  const [selectedProjectId, setSelectedProjectId] = useState(
    lastSelected?.projectId ? lastSelected?.projectId : 0
  );

  function handleSelectProject(projectId) {
    setSelectedProjectId(projectId);
    updateLastSelectedByOwner(auth.user.uid, {
      projectId: projectId,
    });
  }

  //Hook to update projectId and boardDataForProject when data loads and whenever selection changes
  useEffect(() => {
    if (projectsStatus === "success" && lastSelectedStatus === "success") {
      setSelectedProjectId(lastSelected?.projectId);
      const selectedProjectInHook = projects?.find(
        (x) => x.id === lastSelected?.projectId
      );
      setBoardDataForProject(selectedProjectInHook?.boardData);
    }
  }, [lastSelected, projects]); //projects

  const [isPush, setIsPush] = useState(true);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [boardDataForProject, setBoardDataForProject] = useState();

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
      />
      <article class="message is-info mobile-only">
        <div class="message-body">
          <strong>FocusFeel may work better in a larger window.</strong> We are
          currently in beta and our layout works better in a desktop browser
          window for now. Improved mobile layout coming soon!
        </div>
      </article>
      <div className="is-flex is-flex-direction-column mb-2">
        <div className="is-size-5 has-text-weight-semibold mb-1 is-flex is-justify-content-center">
          Right now I need to
        </div>
        <div className="is-flex is-justify-content-center is-align-items-center">
          <Switch
            isPush={isPush}
            handleToggle={() => setIsPush(!isPush)}
            setPush={(v) => setIsPush(v)}
          />
        </div>
      </div>
      <div className="is-flex is-align-items-center is-justify-content-center m-3">
        <div
          className={`notification smallPadd ${
            isPush ? "is-danger" : "is-info"
          }`}
        >
          {isPush &&
            "I will accept some discomfort while I get started on the tasks that align with my goals."}
          {!isPush &&
            "I will be compassionate with myself and work on the path of least resistance until I am ready to push myself."}
        </div>
      </div>

      <div className="columns">
        <div className="column is-2 sticky">
          <aside className="menu mx-3">
            <p className="menu-label is-size-6 ">My Workspaces</p>
            <div
              className="menu-label"
              style={{ cursor: "pointer" }}
              onClick={() => setIsCreatingProject(true)}
            >
              + New Workspace
            </div>
            {projectsStatus !== "loading" &&
              projects &&
              projects.length > 0 && (
                <ul className="menu-list">
                  <li>
                    <a
                      onClick={() => handleSelectProject(0)}
                      className={0 === selectedProjectId ? "is-active" : ""}
                    >
                      <span className="icon">
                        <i
                          className={`fa ${
                            0 === selectedProjectId
                              ? "fa-folder-open"
                              : "fa-folder"
                          }`}
                        ></i>
                      </span>{" "}
                      All Workspaces
                    </a>
                    <ul>
                      {projects.map((project, index) => (
                        <li key={project.id}>
                          <a
                            onClick={() => handleSelectProject(project.id)}
                            className={
                              project.id === selectedProjectId
                                ? "is-active"
                                : ""
                            }
                          >
                            <span className="icon">
                              <i
                                className={`fa ${
                                  project.id === selectedProjectId
                                    ? "fa-folder-open"
                                    : "fa-folder"
                                }`}
                              ></i>
                            </span>{" "}
                            {project.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              )}
          </aside>
        </div>
        <div className="column is-10">
          {lastSelectedStatus !== "loading" &&
            projectsStatus !== "loading" &&
            selectedProjectId && (
              <PushContext.Provider value={isPush}>
                <WrapperTaskBoardView
                  tasksBoardData={boardDataForProject}
                  selectedProjectId={selectedProjectId}
                />
              </PushContext.Provider>
            )}
        </div>
      </div>
      {isCreatingProject && (
        <EditProjectModal onDone={() => setIsCreatingProject(false)} />
      )}
    </>
  );
}
export default requireAuth(TasksPage);
