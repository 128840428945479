import { useState } from "react";
import { requireAuth } from "../../util/auth.js";
import "./onboarding.scss";
import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import { updateUser } from "../../util/db";
import { useAuth } from "./../../util/auth.js";

function StepTwo(props) {
  const { register, handleSubmit, errors } = useForm();
  const auth = useAuth();

  const onSubmit = (data) => {
    console.log("submitted data: ", data);
    updateUser(auth.user.uid, data).then(props.handleNext());
  };

  const mainBlockers = [
    "Anxiety",
    "Lack of motivation",
    "Fear",
    "Boredom",
    "Procrastination",
    "Other",
  ];

  return (
    <>
      <div className="is-size-3 has-text-centered has-text-weight-semibold">
        What are your main productivity blockers?
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          {mainBlockers.map((blocker) => (
            <div key={blocker}>
              <label>
                <input
                  type="checkbox"
                  ref={register()}
                  name="main_blockers"
                  value={blocker}
                  className="mr-2"
                />
                {blocker}
              </label>
            </div>
          ))}
        </div>

        <div className="field is-flex is-justify-content-end">
          <div className="control">
            <button
              className={
                "button is-success is-outlined mx-1 my-1"
                // + (pending ? " is-loading" : "")
              }
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default StepTwo;
