import React from "react";
import "./FormField.scss";

function FormField(props) {
  const { error, type, size, inputRef, options, ...inputProps } = props;

  return (
    <div className="field">
      {props.label && (
        <label className="label" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      <div className="control">
        {type === "textarea" && (
          <textarea
            className={`textarea is-${size} ` + props.addStyle}
            ref={inputRef}
            {...inputProps}
          />
        )}

        {type === "input" && (
          <input
            className={`input is-${size}`}
            ref={inputRef}
            type={type}
            {...inputProps}
          />
        )}

        {type === "password" && (
          <input
            className={`input is-${size}`}
            ref={inputRef}
            type="password"
            {...inputProps}
          />
        )}

        {type === "select" && (
          <div className={`select is-${size}`}>
            <select ref={inputRef} type={type} {...inputProps}>
              <option disabled={true} selected value="">
                Select
              </option>
              {props.options.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {error && <p className="help is-danger">{error.message}</p>}
    </div>
  );
}

export default FormField;
//
