export const lightTheme = {
  bg: "#EAEDEE",
  fg: "#FFF",
  bold: "#d9d9d9",
  bolder: "#6d6d6d",
  text: "#000",
  shadow: "rgba(0,0,0,0.5)",
  shadowInverted: "rgba(255,255,255,1)",
  white: "#FFF",
  black: "#0f0b23",
};
