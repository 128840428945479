import { useState } from "react";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";

function SPutFeelingInQuotes(props) {
  const [feeling, setFeeling] = useState("");
  const [context, setContext] = useState("");

  // Handlers for input changes
  const handleFeelingChange = (event) => {
    setFeeling(event.target.value);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  // Placeholder examples for inspiration could be added here if necessary

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            Feelings, while important, should not be an obstacle to our
            progress. This strategy helps you to acknowledge your feelings,
            understand them within your current context, and create a powerful
            statement that allows you to move forward despite those feelings.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">Identify the Feeling</div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                What is the <b>feeling</b> that's holding you back from starting{" "}
                <i>"{props.stepOrTask}"</i>?
              </div>
              <input
                autoFocus={true}
                className="input"
                type="text"
                placeholder="I feel..."
                value={feeling}
                onChange={handleFeelingChange}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">Understand the Context</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="my-2 p-2">
                Reflect on why you feel <i>"{feeling}"</i> about this task. What
                about the situation makes you feel this way?
              </div>
              <textarea
                className="textarea"
                placeholder="I feel this way because..."
                value={context}
                onChange={handleContextChange}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="boxStepSubtitle">Craft Your Empowering Statement</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="recall p-2 m-2">
                <div>
                  Feeling: <i>"{feeling}"</i>
                </div>
                <div>
                  Context: <i>"{context}"</i>
                </div>
              </div>
              <div className="my-2 p-2">
                Now, create a statement that acknowledges your feeling but also
                <b> affirms your decision to move forward</b>. Use quotation
                marks to encapsulate the feeling, showing that you recognize it
                but choose not to be controlled by it.
              </div>
              <SaveThoughtForm
                defaultValue={
                  `Even though I feel "` +
                  feeling +
                  `", I will not let that stop me from getting into action.`
                }
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SPutFeelingInQuotes;
