import { useState } from "react";
import {
  faTrophy,
  faRoadBarrier,
  faPersonDigging,
  faHeart,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../util/auth.js";
import { createAccomplishment, updateAccomplishment } from "../../util/db.js";

function SaveEditAccomplishmentForm(props) {
  const auth = useAuth();

  const [accomplishment, setAccomplishment] = useState({
    accomplished: props.accomplishInput,
    challenges: props.challengeList,
    efforts: props.effortList,
    abouts: props.aboutList,
  });
  const [savedAccomplishment, setSavedAccomplishment] = useState("none");
  const [pending, setPending] = useState(false);

  const isAccomplishmentSaved = () => {
    return savedAccomplishment === accomplishment;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAccomplishment({ ...accomplishment, [name]: value });
  };

  const handleChallengesChange = (event) => {
    const { name, value } = event.target;
    //build the challenges array
    let challenges = accomplishment.challenges;
    challenges[Number(name)] = value;
    setAccomplishment({ ...accomplishment, challenges: challenges });
  };

  const handleEffortsChange = (event) => {
    const { name, value } = event.target;
    //build the efforts array
    let efforts = accomplishment.efforts;
    efforts[Number(name)] = value;
    setAccomplishment({ ...accomplishment, efforts: efforts });
  };

  const handleAboutsChange = (event) => {
    const { name, value } = event.target;
    //build the abouts array
    let abouts = accomplishment.abouts;
    abouts[Number(name)] = value;
    setAccomplishment({ ...accomplishment, abouts: abouts });
  };

  const saveAccomplishment = (accomplishment) => {
    setPending(true); //to make button appear loading

    const query = createAccomplishment(auth.user.uid, accomplishment);
    query.then(() => {
      setPending(false);
      setSavedAccomplishment(accomplishment);
    });
  };

  const updateSavedAccomplishment = (accomplishment) => {
    setPending(true); //to make button appear loading

    const query = updateAccomplishment(
      auth.user.uid,
      props.accomplishId,
      accomplishment
    );
    query.then(() => {
      setPending(false);
      setSavedAccomplishment(accomplishment);
      props.onDone();
    });
  };

  return (
    <form
      className="m-3"
      onSubmit={(event) => {
        event.preventDefault();
        if (!accomplishment.accomplished || !accomplishment.abouts) return;
        if (!props.updating) {
          saveAccomplishment(accomplishment);
        } else if (props.updating) {
          updateSavedAccomplishment(accomplishment);
        }
      }}
    >
      <div className="columns">
        <div className="column is-3 is-flex mt-2">
          <label className="label">What I accomplished</label>
        </div>
        <div className="column is-1">
          <FontAwesomeIcon
            icon={faTrophy}
            size="xl"
            className="mr-2 mt-2 trophy"
          />
        </div>
        <div className="column">
          <input
            className="input"
            type="text"
            name="accomplished"
            value={accomplishment.accomplished}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-3 is-flex mt-2">
          <label className="label">What I overcame</label>
        </div>
        <div className="column is-1">
          <FontAwesomeIcon
            icon={faRoadBarrier}
            size="xl"
            className="mr-2 mt-2 is-fontcolor-brown"
          />
        </div>
        <div className="column">
          {accomplishment.challenges?.map((challenge, index) => {
            return (
              <input
                className="input mb-1"
                type="text"
                name={index}
                value={challenge}
                onChange={handleChallengesChange}
              />
            );
          })}
        </div>
      </div>

      <div className="columns">
        <div className="column is-3 is-flex mt-2">
          <label className="label">What I did</label>
        </div>
        <div className="column is-1">
          <FontAwesomeIcon
            icon={faPersonDigging}
            size="xl"
            className="mr-2 mt-2 is-fontcolor-green"
          />
        </div>
        <div className="column">
          {accomplishment.efforts?.map((effort, index) => {
            return (
              <input
                className="input mb-1"
                type="text"
                name={index}
                value={effort}
                onChange={handleEffortsChange}
              />
            );
          })}
        </div>
      </div>

      <div className="columns">
        <div className="column is-3 is-flex mt-2">
          <label className="label">What it says about me</label>
        </div>
        <div className="column is-1">
          <FontAwesomeIcon
            icon={faHeart}
            size="xl"
            className="mr-2 mt-2 is-fontcolor-red"
          />
        </div>
        <div className="column">
          {accomplishment.abouts?.map((about, index) => {
            return (
              <input
                className="input mb-1"
                type="text"
                name={index}
                value={about}
                onChange={handleAboutsChange}
              />
            );
          })}
        </div>
      </div>
      <div className="is-flex is-justify-content-center">
        {!props.updating && (
          <button
            className={`button is-small mx-4 my-2 ${
              isAccomplishmentSaved() ? "is-static" : "is-primary"
            } ${pending ? " is-loading" : ""}`}
          >
            {isAccomplishmentSaved()
              ? "Accomplishment Saved"
              : "Save Accomplishment"}
          </button>
        )}
        {props.updating && (
          <button
            className={`button is-small mx-4 my-2 ${
              isAccomplishmentSaved() ? "is-static" : "is-primary"
            } ${pending ? " is-loading" : ""}`}
          >
            {isAccomplishmentSaved()
              ? "Accomplishment Updated"
              : "Update Accomplishment"}
          </button>
        )}
      </div>
    </form>
  );
}

export default SaveEditAccomplishmentForm;
