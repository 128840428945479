import FrameworkTransform from "./FrameworkTransform";

function STransformNotSmart(props) {
  const thoughtToTransform = "I'm not smart enough";

  const examplesTransform = [
    "I can learn and improve my skills with effort and practice.",
    "I will remember that my mindset and beliefs about myself and my abilities can have a big impact on my success.",
    "Raw intelligence is not the most important factor that determines success. I can use my strengths and work on my weaknesses to achieve my goals.",
    "I'll focus on progress, not perfection, and celebrate my small wins along the way.",
    "I'll seek help or guidance from others who can support me in my learning.",
    "I'll remind myself that everyone has areas where they need to improve, and that it's okay to make mistakes and learn from them.",
    "I'll challenge myself to step out of my comfort zone and take on new opportunities to learn and grow.",
    "By focusing on growth and progress, seeking support when needed, and challenging myself to learn and improve, I can overcome self-doubt and achieve my goals.",
    "Intelligence is not a fixed trait, but rather something that can be developed over time.",
    "I'll focus on learning and growth, rather than comparing myself to others.",
    "I'll seek out resources and opportunities to learn and improve, such as online courses or mentorship programs.",
    "I'll remind myself that mistakes and setbacks are a natural part of the learning process, and that I can learn from them and move forward.",
  ];

  return (
    <FrameworkTransform
      thoughtToTransform={thoughtToTransform}
      examplesTransform={examplesTransform}
    />
  );
}

export default STransformNotSmart;
