import FrameworkTransform from "./FrameworkTransform";

function STransformNotFeelLikeIt(props) {
  const thoughtToTransform = "I don't feel like doing this";

  const examplesTransform = [
    "I'll start working on it for just a few minutes and see how I feel. I might find that I get into the flow and want to keep going.",
    "I'll remind myself that I'm capable of completing this task, even if I don't feel like it right now.",
    "I'll make a commitment to myself to work on it for a set amount of time, and then take a break if needed.",
    "I'll remind myself of the benefits of completing the task and use that as motivation to get started.",
    "I'll focus on the satisfaction and sense of accomplishment I'll feel once I've completed the task.",
    "I'll find a way to make the task more enjoyable or engaging, such as by listening to music or breaking it down into smaller, more manageable parts.",
  ];

  return (
    <FrameworkTransform
      thoughtToTransform={thoughtToTransform}
      examplesTransform={examplesTransform}
    />
  );
}

export default STransformNotFeelLikeIt;
