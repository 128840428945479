import { useState } from "react";
import { v4 as uuid } from "uuid";
import DontKnowItem from "./DontKnowItem";

function TaskDontKnow(props) {
  const [name, setname] = useState("");

  function handleAdd() {
    const newList = props.dontKnowList.concat([{ name, id: uuid() }]);
    props.handleSetDontKnowList(newList);
    setname("");
  }

  function handleRemove(index) {
    const newArray = props.dontKnowList.filter((item, i) => i !== index);
    props.handleSetDontKnowList(newArray);
  }

  const handleChange = (event) => {
    setname(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
    }
  };

  return (
    <div className="p-3 my-3 is-themeSecondary">
      <div className="subtitle has-text-weight-semibold">
        What I don't know:
      </div>
      <div className="is-flex is-align-items-center">
        <input
          className="input"
          autoComplete="off"
          autoFocus={true}
          type="text"
          placeholder="Write down the things you need to figure out"
          value={name}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <button className="button is-small mx-2 " onClick={() => handleAdd()}>
          Add
        </button>
      </div>
      {props.dontKnowList.length > 0 && (
        <div className="mt-3 has-text-weight-semibold is-flex is-justify-content-center">
          Step you will take to try to figure it out (see Resources)
        </div>
      )}
      <div className="m-1">
        {props.dontKnowList.map((item, index) => (
          <DontKnowItem
            item={item}
            index={index}
            taskData={props.taskData}
            handleRemove={handleRemove}
          />
        ))}
      </div>
    </div>
  );
}

export default TaskDontKnow;
