import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Mixpanel } from "../../util/mixpanel";

function Motivation(props) {
  return (
    <div
      className={"panel-block m-3 is-flex is-justify-content-space-between"}
      key={props.index}
    >
      <div className="is-flex mr-2">
        <button
          className="button action is-ghost p-1 mr-3"
          onClick={() => {
            Mixpanel.track("Focus motivation clicked");
            props.handleFocusMotivation(props.motivation.content);
          }}
        >
          Focus
        </button>
        <Interweave
          content={props.motivation.content}
          matchers={[new UrlMatcher("url")]}
          newWindow={true}
        />
      </div>
      <div className="is-flex is-align-items-center mr-3">
        <button
          className="button action is-ghost p-1"
          aria-label="update"
          onClick={() =>
            props.handleUpdatingMotivation(props.index, props.isPinned)
          }
        >
          <FontAwesomeIcon icon={faEdit} size="sm" className="" />
        </button>
        <button
          className={"button action is-ghost p-1"}
          aria-label="delete"
          onClick={() =>
            props.handleDeleteMotivation(props.index, props.isPinned)
          }
        >
          <FontAwesomeIcon icon={faTrash} size="sm" className="" />
        </button>
      </div>
    </div>
  );
}

export default Motivation;
