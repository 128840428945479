import { useForm } from "react-hook-form";
import { Mixpanel } from "../util/mixpanel";
import { history } from "../util/router";

function GetStarted(props) {
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    //track with email and source
    Mixpanel.track("getStarted", {
      source: props.source,
      user: email,
    });
    console.log("user got started");
    //redirect to create new account with prefilled email through param
    history.replace("/auth/signup?email=" + email);
  };

  return (
    <>
      {props.subscribed === false && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field has-addons is-justify-content-center">
            <div className="control email">
              <input
                className="input is-medium"
                name="email"
                type="email"
                placeholder={props.inputPlaceholder}
                ref={register({
                  required: "Please enter an email address",
                })}
              />
            </div>
            <div className="control">
              <button
                className={`button is-${props.buttonColor} is-medium`}
                type="submit"
              >
                {props.buttonText}
              </button>
            </div>
          </div>

          {errors.email && (
            <p className="help is-danger has-text-left">
              {errors.email.message}
            </p>
          )}
        </form>
      )}
    </>
  );
}

export default GetStarted;
