import { useState } from "react";
import FormAlert from "../FormAlert";

import { useAuth } from "../../util/auth.js";

import { updateTask } from "../../util/db.js";
import "./CreateThoughtModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/pro-solid-svg-icons";
import CreateYourOwn from "./CreateYourOwn";
import BrowsePopular from "./BrowsePopular";
import { v4 as uuid } from "uuid";

function CreateThoughtModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [isBrowse, setIsBrowse] = useState(false);

  const onSubmit = (data) => {
    setPending(true);
    let newThoughtsData = props.thoughtsData;
    //adding new thought
    newThoughtsData.push({ id: uuid(), ...data });

    const query = updateTask(auth.user.uid, props.taskId, {
      thoughtsData: newThoughtsData,
    });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="CreateThoughtModal__card card">
        <header className="card-header">
          <p className="card-header-title">Add Thought</p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <div className="card-content">
          <div className="tabs is-boxed">
            <ul>
              <li
                className={isBrowse ? "" : "is-active"}
                onClick={() => setIsBrowse(false)}
              >
                <a>
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                  <span className="ml-2">Create new</span>
                </a>
              </li>
              <li
                className={isBrowse ? "is-active" : ""}
                onClick={() => setIsBrowse(true)}
              >
                <a>
                  <FontAwesomeIcon icon={faSearch} size="sm" />
                  <span className="ml-2">Browse popular</span>
                </a>
              </li>
            </ul>
          </div>

          {formAlert && (
            <FormAlert type={formAlert.type} message={formAlert.message} />
          )}

          {!isBrowse && <CreateYourOwn pending={pending} onSubmit={onSubmit} />}
          {isBrowse && <BrowsePopular pending={pending} onSubmit={onSubmit} />}
        </div>
      </div>
    </div>
  );
}

export default CreateThoughtModal;
