import EvidenceFramework from "./FrameworkEvidence";

function SEvidenceCantAsk(props) {
  const thoughtToExamine = "I can't ask for help/make questions";

  // SEE CarouselAffirmationExamples to see how to fetch examples from firestore
  const examplesSupport = [
    [
      "I once asked for help on a task, and the person who helped did it in a way that made me feel stupid for not knowing how to do it myself.",
    ],
    [
      "I remember in a previous job, I asked my manager for clarification on a project and they seemed annoyed with me.",
    ],
    [
      "I once made a mistake on a project and when I asked a colleague for help to fix it, they made a big deal out of it and talked about it with other people.",
    ],

    [
      "I once asked a question in class and the teacher made a sarcastic comment that embarrassed me in front of the other students.",
    ],
    [
      "I recall asking a coworker for help with a task, and they ended up taking over the entire project without giving me a chance to learn or contribute. ",
    ],
    [
      "I once asked a supervisor for feedback on a report I had written, and they responded with harsh criticism without offering any constructive suggestions.",
    ],
  ];

  const examplesNotSupport = [
    [
      "In the last meeting, I asked a question and the team lead said that they appreciated my attention to detail and willingness to ask questions.",
    ],
    [
      "During a code review, I asked a question about a new library we were using, and my colleague explained how it worked and even showed me some examples of how to implement it. I'm glad I asked that question.",
    ],
    [
      "I once asked a coworker for help with a task and they were happy to help me and even showed me some cool tricks.",
    ],
    [
      "I recently emailed my manager about scope clarification and they responded quickly with a thorough explanation, which made me feel relieved and supported.",
    ],
    [
      "I asked my teammate for help with a particularly challenging bug, and they walked me through their process for troubleshooting it. It not only solved the issue but also taught me a new technique.",
    ],
  ];

  const examplesThoughts = [
    [
      "Just because several on my team know the answer to my question doesn't mean they think less of me. It's important to remember that we're all constantly learning and growing.",
    ],
    [
      "While it's true that I am a Senior now and Seniors generally require less help, I have witnessed Seniors and above ask questions plenty of times. I don't think any less of them and neither will they.",
    ],
    [
      "If someone responds negatively to my question, it's possible that they're just having a bad day or feeling overwhelmed. It's not necessarily a reflection of my own abilities or worth.",
    ],
    [
      "Asking questions is a natural part of the learning process, and even if someone reacts negatively, it doesn't mean I shouldn't continue to seek help or clarification.",
    ],
    [
      "It's okay if someone doesn't have an answer to my question right away. I can use that as an opportunity to explore the issue further and perhaps even come up with a solution on my own.",
    ],
    [
      "Although it can be uncomfortable to admit that I don't know something, it's important to remember that everyone has knowledge gaps, and asking questions is how we fill them.",
    ],
    [
      "While it's true that not everyone may respond positively to my questions, there are many people who are willing and happy to help. By seeking out those individuals, I can create a supportive network that encourages learning and growth.",
    ],
    [
      "Although it can be difficult to ask for help, it's important to remember that doing so can actually save time and prevent mistakes in the long run.",
    ],
    [
      "Even if I feel like I'm asking too many questions, it's important to remember that the people around me are there to support my learning and development. By asking questions, I'm showing that I'm committed to improving and contributing to the team.",
    ],
  ];

  return (
    <EvidenceFramework
      thoughtToExamine={thoughtToExamine}
      examplesSupport={examplesSupport}
      examplesNotSupport={examplesNotSupport}
      examplesThoughts={examplesThoughts}
    />
  );
}

export default SEvidenceCantAsk;
