import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { Mixpanel } from "../../../../util/mixpanel";

function CarouselExamples(props) {
  const [exampleIndex, setExampleIndex] = useState(0);

  const handlePrevNext = (move) => {
    Mixpanel.track("Carousel example rotated");
    let newIndex = exampleIndex + move;
    if (newIndex >= props.examples.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = props.examples.length - 1;
    }
    setExampleIndex(newIndex);
  };

  return (
    <>
      {props.examples && props.examples.length > 0 && (
        <>
          <div className="m-3">
            <div className="">
              {props.examples[exampleIndex].map((item) => {
                return <li>{item}</li>;
              })}
            </div>
          </div>

          <div className={"p-3 is-flex is-justify-content-end"}>
            <button
              className="button mx-1 is-light"
              onClick={() => handlePrevNext(-1)}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="s"
                className="mx-2 mt-1"
              />
            </button>
            <button
              className="button mx-1 is-light"
              onClick={() => handlePrevNext(1)}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="s"
                className="mx-2 mt-1"
              />
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default CarouselExamples;
