import { useState } from "react";
import FormAlert from "../FormAlert";
import FormField from "../FormField";
import { useAuth } from "../../util/auth.js";
import { useForm } from "react-hook-form";
import { updateTask } from "../../util/db.js";
import "./EditTaskNotesModal.scss";
import TaskKnow from "./TaskKnow";
import TaskDontKnow from "./TaskDontKnow";
import TaskResources from "./TaskResources";

function EditTaskNotesModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false); //for button loading on saveClose

  const [dontKnowList, setDontKnowList] = useState(
    props.taskData?.dontKnowList || []
  );
  const [knowList, setKnowList] = useState(props.taskData?.knowList || []);

  const [notes, setNotes] = useState(props.taskData?.notes || "");

  const handleSetDontKnowList = (newList) => {
    setDontKnowList(newList);
  };

  const handleSetKnowList = (newList) => {
    const newListWithoutEmpty = newList.filter((item) => item.name);
    setKnowList(newListWithoutEmpty);
  };

  function onSaveClose() {
    setPending(true);
    const query = updateTask(auth.user.uid, props.taskData?.id, {
      knowList: knowList,
      dontKnowList: dontKnowList,
      notes: notes,
    });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
      });
  }

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background" onClick={() => onSaveClose()} />
        <div className="EditTaskNotesModal card">
          <header className="card-header">
            <p className="card-header-title">
              Add Clarity:
              <span className="has-text-weight-light mx-1">
                {props.taskData.name}
              </span>
            </p>
            <span className="card-header-icon">
              <button
                className="delete"
                aria-label="close"
                onClick={() => props.onDone()}
              >
                Done
              </button>
            </span>
          </header>
          <div className="card-content">
            {props.isForceEditTaskNotes && (
              <div className="notification is-link m-3">
                <p className="is-size-5 is-flex is-justify-content-center">
                  You completed a step to gather information!
                </p>

                <p className="is-flex is-justify-content-center">
                  Use the What I Know section below to record the answer to:{" "}
                  <span className="mx-1 is-fontcolor-dark2">
                    {props.stepForInfo}
                  </span>
                </p>
              </div>
            )}
            <div className="columns">
              <div className="column is-8">
                <div className="subtitle has-text-weight-semibold">
                  What exactly do I need to do?
                </div>
                <div className="control">
                  <textarea
                    className="textarea"
                    type="text"
                    placeholder="Write details about the task requirement here"
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                    value={notes}
                  />
                </div>

                <TaskKnow
                  handleSetKnowList={handleSetKnowList}
                  knowList={knowList}
                />

                <TaskDontKnow
                  handleSetDontKnowList={handleSetDontKnowList}
                  dontKnowList={dontKnowList}
                  taskData={props.taskData}
                />
              </div>
              <div className="column is-4 pt-5">
                <TaskResources projectId={props.taskData?.projectId} />
              </div>
            </div>
          </div>
          <footer className="modal-card-foot justify-end">
            <button
              className={
                "button is-medium is-primary" + (pending ? " is-loading" : "")
              }
              onClick={() => onSaveClose()}
            >
              Save and Close
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}

export default EditTaskNotesModal;
