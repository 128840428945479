import { useState, useEffect } from "react";

function TemplateSteps(props) {
  const { selectedTemplate } = props;

  return (
    <>
      <div className="column is-7">
        <div className="block m-4 title">{selectedTemplate.title}</div>
        <div className="block ml-4">
          <button
            className="button is-primary"
            onClick={() => props.handleAddTemplateSteps(selectedTemplate.steps)}
          >
            Add Steps to Task
          </button>
        </div>
        <div className="block ml-4">
          {selectedTemplate.steps.length > 0 ? (
            selectedTemplate.steps.map((step) => (
              <div className="m-2">
                <div className="">{step.content}</div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export default TemplateSteps;
