//import React from "react";
import Section from "./Section";
//import { Link } from "./../util/router.js";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

function Footer(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
      className="footer"
    >
      <div className="FooterComponent__container container">
        {/* <div className="brand left">
          <Link to="/">
            <img src={props.logo} alt="Logo" />
          </Link>
        </div> */}

        {/*<div className="links right">
           <Link to="/about">About</Link>
          <Link to="/faq">FAQ</Link> */}
        {/* <Link to="/contact">Contact</Link> */}
        {/* <a
            target="_blank"
            href="https://medium.com"
            rel="noopener noreferrer"
          >
            Blog
          </a> */}

        {/* <div className="social right">
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-twitter" />
            </span>
          </a>
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-facebook-f" />
            </span>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon">
              <i className="fab fa-instagram" />
            </span>
          </a>
        </div> */}
        <div className="copyright left">{props.copyright}</div>
        <a href="mailto:hello@focusfeel.com" className="right">
          <FontAwesomeIcon icon={faEnvelope} size="s" className="mr-2" />
          hello@focusfeel.com
        </a>
      </div>
    </Section>
  );
}

export default Footer;
