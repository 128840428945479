import { useState } from "react";
import RenderSComponent from "./RenderSComponent.js";
import CarouselExamples from "./utils/CarouselExamples.js";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";
import AddAndDisplayList from "./utils/AddAndDisplayList.js";

function FCorporateMindset(props) {
  const [setbacks, setSetbacks] = useState([]);

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            <b>Separating your personal identity from your work persona</b> can
            reduce anxiety and help you approach tasks and feedback more
            objectively. By thinking of yourself as a corporation, you adopt a
            professional mindset that focuses on growth, solution finding, and
            business outcomes.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">Identify Your Challenges</div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="recall p-2 m-2">
                Feeling: <i>"{props.feelingExpressed}"</i>
              </div>
              <div className="mb-4">
                Identify what specific challenges, feelings, or issues are
                currently holding you back from approaching your tasks
                effectively.
              </div>
              <AddAndDisplayList
                placeholder="Describe the challenge you are facing..."
                list={setbacks}
                setList={setSetbacks}
              />
            </div>
            <div className="column examples">
              <div>Common professional challenges:</div>
              <CarouselExamples
                examples={props.examplesChallenges.map((setback) => [setback])}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">
            Establish Your Corporate Identity
          </div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                What name are you known by professionally?
              </div>
              <input
                autoFocus={true}
                className="input"
                type="text"
                placeholder="Enter your professional name"
                onChange={props.handleCorporateNameChange}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">Your Corporate Approach</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              Let's add <b>", Inc."</b> to the end to establish your corporate
              identity.
              <div className="recall p-2 m-2">
                Your Corporate Identity: <i>{props.corporateName}</i>
                <div>
                  Challenges identified:{" "}
                  {setbacks.map((setback, index) => (
                    <li key={index}>{setback}</li>
                  ))}
                </div>
              </div>
              <div className="my-2 p-2">
                <b>
                  Imagine how your corporation, {props.corporateName}, would
                  professionally approach these issues
                </b>
                . How would a corporation turn these challenges into business
                objectives and actionable plans?
              </div>
              <SaveThoughtForm
                defaultValue={`At '${props.corporateName}', we approach`}
              />
            </div>
            <div className="column examples">
              <div>Corporate strategies examples:</div>
              <CarouselExamples
                examples={props.examplesCorporateApproach.map((approach) => [
                  approach,
                ])}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default FCorporateMindset;
