import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import SaveThoughtForm from "./utils/SaveThoughtForm";
import AddAndDisplayList from "./utils/AddAndDisplayList";

function EvidenceFramework({
  thoughtToExamine,
  examplesSupport,
  examplesNotSupport,
  examplesThoughts,
}) {
  const { prev, next, hasNext, hasPrev } = useSteps();

  const [supportList, setSupportList] = useState([]);
  const [dontSupportList, setDontSupportList] = useState([]);

  return (
    <>
      <div className="block">
        <h1 className="title is-3">Examine the evidence</h1>
      </div>
      <div className="block">
        <i>"{thoughtToExamine}"</i> is a common thought for many in your
        industry. However, it's important to examine the evidence and evaluate
        this thought.
      </div>

      <div className={"box"}>
        <Steps>
          {/* first step, evidence supports */}
          <div>
            <div className="boxStepSubtitle">
              Evidence for the thought: <i>"{thoughtToExamine}"</i>
            </div>
            <div className="m-2">
              What is some <b>evidence</b> that <b>supports</b> this thought:
            </div>
            <div className="columns">
              <div className="column is-6">
                <div className="is-7 m-2">
                  Remember to list <b>facts</b> and not opinions or fears:
                </div>
                <AddAndDisplayList
                  placeholder={"Write down a fact that supports this thought"}
                  list={supportList}
                  setList={setSupportList}
                />
              </div>
              <div className="column examples">
                <div>Get inspired by answers from others in your field:</div>
                <CarouselEvidenceExamples examples={examplesSupport} />
              </div>
            </div>
          </div>

          {/* second step, evidence against */}
          <div>
            <div className="boxStepSubtitle">
              Evidence against the thought: <i>"{thoughtToExamine}"</i>
            </div>
            <div className="m-2">
              What is some <b>evidence</b> that <b>does NOT support</b> this
              thought:
            </div>

            <div className="columns">
              <div className="column is-6">
                <AddAndDisplayList
                  placeholder={
                    "Write down a fact that does NOT support this thought"
                  }
                  list={dontSupportList}
                  setList={setDontSupportList}
                />
              </div>
              <div className="column examples">
                <div>Get inspired by answers from others in your field:</div>
                <CarouselEvidenceExamples examples={examplesNotSupport} />
              </div>
            </div>
          </div>

          {/* 3rd step, balanced thought */}
          <div>
            <div className="boxStepSubtitle">
              Balanced thought to replace the thought:{" "}
              <i>"{thoughtToExamine}"</i>
            </div>
            <div className="columns recall mx-2">
              <div className="column is-6">
                <div>Evidence that supports:</div>
                <div className="p-2">
                  {supportList.map((item) => {
                    return <li>{item}</li>;
                  })}
                </div>
              </div>
              <div className="column is-6">
                <div>Evidence that does NOT support:</div>
                <div className="p-2">
                  {dontSupportList.map((item) => {
                    return <li>{item}</li>;
                  })}
                </div>
              </div>
            </div>
            <div className="subtitle mt-3">
              Write an alternative view of the situation that balances both
              sides of the evidence:
            </div>
            <div className="columns mx-4">
              <div className="column is-6">
                <SaveThoughtForm
                  placeholder={
                    "Make sure your balanced thought is consistent with the evidence."
                  }
                />
              </div>
              <div className="column examples">
                <div>
                  Get inspired by alternative thoughts from others in your
                  industry:
                </div>
                <CarouselEvidenceExamples examples={examplesThoughts} />
              </div>
            </div>
          </div>
        </Steps>
        <div>
          <div className={"p-3 is-flex"}>
            <button
              className="button mx-1 is-light"
              onClick={prev}
              disabled={!hasPrev}
            >
              <i className="fa fa-chevron-left mx-2"></i>
              Prev
            </button>
            <button
              className="button mx-1 is-light"
              onClick={next}
              disabled={!hasNext}
            >
              Next
              <i className="fa fa-chevron-right mx-2"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function CarouselEvidenceExamples(props) {
  const [exampleIndex, setExampleIndex] = useState(0);

  const handlePrevNext = (move) => {
    let newIndex = exampleIndex + move;

    if (newIndex >= props.examples.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = props.examples.length - 1;
    }
    setExampleIndex(newIndex);
  };

  return (
    <>
      {props.examples && props.examples.length > 0 && (
        <>
          <div className="m-3">
            <div className="">
              {props.examples[exampleIndex].map((item) => {
                return <li>{item}</li>;
              })}
            </div>
          </div>

          <div className={"p-3 is-flex is-justify-content-end"}>
            <button
              className="button mx-1 is-light"
              onClick={() => handlePrevNext(-1)}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="s"
                className="mx-2 mt-1"
              />
            </button>
            <button
              className="button mx-1 is-light"
              onClick={() => handlePrevNext(1)}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="s"
                className="mx-2 mt-1"
              />
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default EvidenceFramework;
