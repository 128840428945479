import React, { useState } from "react";
import CardTaskAffirmation from "./Affirmations/CardTaskAffirmation";
import CardEditTaskNotes from "./Tasks/CardEditTaskNotes";
import CardTaskMotivation from "./Motivations/CardTaskMotivation";
import { Link, useRouter } from "../util/router.js";
import { useAuth } from "../util/auth.js";
import "./TaskContent.scss";
import TaskSteps from "./Tasks/TaskSteps";
import { useMount, useSetState } from "react-use";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { Mixpanel } from "../util/mixpanel";

function TaskContent(props) {
  // Set up onboarding tour
  const router = useRouter();
  const isTouring = router.query.tour;

  const [{ run, stepIndex, steps }, setState] = useSetState({
    run: false,
    isModalOpen: false,
    stepIndex: 0,
    steps: [],
  });

  //const createTaskButtonRef = useRef(null);

  useMount(() => {
    setTimeout(() => {
      setState({
        run: isTouring || false, //run only when param says to tour
        steps: [
          {
            content: (
              <div>
                What would be a good first step to make progress towards{" "}
                <i>{props.taskData.name}</i>?
              </div>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: true,
            placement: "top",
            spotlightClicks: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: ".task-writer",
            title: "Add First Step",
          },
          {
            content: (
              <div>
                Click here to explore your resistance to getting started on this
                step
              </div>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter: true,
            placement: "top",
            spotlightClicks: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: ".exploreResistanceStep",
            title: "Explore Resistance",
          },
          {
            content: (
              <div>
                Select the thought that best reflects your current feeling and
                choose a strategy from the right
              </div>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideBackButton: true,
            hideFooter: false,
            placement: "right",
            spotlightClicks: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: ".FeelingsListContainer",
            title: "Process Feeling",
          },
        ],
      });
    }, 0);
  });

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false, stepIndex: 0 });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // go to next step
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      // Update state to advance the tour
      setState({
        stepIndex: nextStepIndex,
      });
      Mixpanel.track("taskTourStep", { step: nextStepIndex });
    }
  };
  function goToNextTourStep() {
    setState({ stepIndex: stepIndex + 1 });
  }
  // End Tour Setup

  //Actual page stuff

  const [isForceEditTaskNotes, setIsForceEditTaskNotes] = useState(false);
  const [stepForInfo, setStepForInfo] = useState("");

  function handleForceEditTaskNotes(value, step) {
    setIsForceEditTaskNotes(value);
    setStepForInfo(step);
  }

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        locale={{
          skip: "Exit tour",
          close: "Exit tour",
          last: "Got it!",
          next: "Next",
        }}
        showProgress={false}
        styles={{
          options: {
            primaryColor: "#7048f4",
          },
        }}
      />
      <div className="column is-7">
        <div className="mx-3">
          <CardTaskMotivation
            taskData={props.taskData}
            handlePreselectFeeling={props.handlePreselectFeeling}
            handleProcessFeeling={props.handleProcessFeeling}
          />
          {/*<CardTaskAffirmation taskData={props.taskData} /> */}
          <CardEditTaskNotes
            taskData={props.taskData}
            isForceEditTaskNotes={isForceEditTaskNotes}
            stepForInfo={stepForInfo}
            handleForceEditTaskNotes={handleForceEditTaskNotes}
          />
          <TaskSteps
            taskId={props.taskData?.id}
            stepsData={props.taskData?.stepsData}
            handleProcessFeeling={props.handleProcessFeeling}
            handlePreselectFeeling={props.handlePreselectFeeling}
            handleForceEditTaskNotes={handleForceEditTaskNotes}
            goToNextTourStep={goToNextTourStep}
          />
        </div>
      </div>
    </>
  );
}

export default TaskContent;
