import firebase from "../../util/firebase";
import { useAuth } from "../../util/auth.js";

import { increment } from "firebase/firestore"; //works? THIS IS V9 - not sure if compatible
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";

// Allows user to heart or like a post
function StrategyHeartButton({ strategyId }) {
  const firestore = firebase.firestore();
  const auth = useAuth();

  //get strategyRef from id
  const strategyRef = firestore.collection("strategies").doc(strategyId);
  // Listen to heart document for currently logged in user
  const strategyLikeRef = strategyRef
    .collection("strategyLikes")
    .doc(auth.user.uid);

  const [heartDoc, loading, error] = useDocument(strategyLikeRef);

  const [realtimeStrategy] = useDocumentData(strategyRef);

  // Create a user-to-post relationship
  const addHeart = async (e) => {
    e.stopPropagation();
    const uid = auth.user.uid;
    const batch = firestore.batch();

    batch.update(strategyRef, { heartCount: increment(1) });
    batch.set(strategyLikeRef, { uid });

    await batch.commit();
  };

  // Remove a user-to-post relationship
  const removeHeart = async (e) => {
    e.stopPropagation();
    const batch = firestore.batch();

    batch.update(strategyRef, { heartCount: increment(-1) });
    batch.delete(strategyLikeRef);

    await batch.commit();
  };

  //if I declare here vs use in component realtimeStrategy.heartCount
  const likesCount = realtimeStrategy?.heartCount
    ? realtimeStrategy?.heartCount
    : 0;

  return heartDoc?.exists() ? (
    <span className="p-4 icon-text">
      <FontAwesomeIcon
        icon={faHeart}
        size="s"
        className="is-urgent mr-2 mt-1"
        onClick={removeHeart}
      />
      <span>{likesCount}</span>
    </span>
  ) : (
    <span className="p-4 icon-text">
      <FontAwesomeIcon
        icon={faHeart}
        size="s"
        className="mr-2 mt-1"
        onClick={addHeart}
      />
      <span>{likesCount}</span>
    </span>
  );
}

export default StrategyHeartButton;
