import { useState } from "react";
import FCorporateMindset from "./FrameworkCoporateMindset";

function SCorporateIncompetent(props) {
  const [corporateName, setCorporateName] = useState("");

  // Handler for corporate name change
  const handleCorporateNameChange = (event) => {
    setCorporateName(event.target.value.trim() + ", Inc.");
  };

  const feelingExpressed = "I'm afraid they will think I'm incompetent";

  const examplesChallenges = [
    "I get tongue-tied in meetings because I'm worried I'll say something that shows I don’t have all the answers.",
    "Taking the spotlight with big projects freaks me out. I just can't shake the thought that everyone's going to critique my every move.",
    "I'm always triple-checking my work. I can't help but think that one little slip-up is going to make everyone doubt I know my stuff.",
    "Asking questions sometimes feels like putting up a giant sign that says 'I don’t get it,' especially with things I think I should already know.",
    "Leading a team sounds cool and all, but what if they figure out I’m not as sharp as they thought? That thought really gets the nerves going.",
    "When it's time to make the call on something important, I freeze up. I keep thinking if I choose wrong, everyone's going to think I’m not cut out for this.",
  ];

  const examplesCorporateApproach = [
    `${corporateName} cheers on its team to tackle tough tasks. We see them as chances to learn and show what we're made of.`,
    `${corporateName} is all about learning as we go. If you need to ask a question to get the job done right, we're all for it.`,
    `${corporateName} has your back by encouraging you to get the training you need, so you can turn 'I don’t know' into 'I’ve got this!'`,
    `${corporateName} is big on making moves and getting things done. If we trip up, no sweat—we learn and get better for next time.`,
  ];

  return (
    <FCorporateMindset
      title={props.title}
      feelingExpressed={feelingExpressed}
      examplesChallenges={examplesChallenges}
      examplesCorporateApproach={examplesCorporateApproach}
      corporateName={corporateName}
      handleCorporateNameChange={handleCorporateNameChange}
    />
  );
}

export default SCorporateIncompetent;
