import { useState } from "react";
import EditableItem from "./EditableItem";
import { v4 as uuid } from "uuid";

function TaskKnow(props) {
  const [name, setname] = useState("");

  const handleAdd = () => {
    const newList = props.knowList.concat({ name: name, id: uuid() });
    props.handleSetKnowList(newList);
    setname("");
  };

  const handleEdit = (value, index) => {
    //update list with slice
    props.handleSetKnowList([
      ...props.knowList.slice(0, index),
      { name: value },
      ...props.knowList.slice(index + 1),
    ]);
  };

  const handleChange = (event) => {
    setname(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
    }
  };

  return (
    <div className="p-3 my-3 is-themeTertiary">
      <div className="subtitle has-text-weight-semibold">What I know:</div>
      <div className="is-flex is-align-items-center">
        <input
          className="input"
          autoComplete="off"
          autoFocus={true}
          type="text"
          placeholder="Write down any fact or idea that might be helpful"
          value={name}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <button className="button is-small mx-2 " onClick={handleAdd}>
          Add
        </button>
      </div>

      <div className="p-2">
        {props.knowList.map((item, index) => {
          return (
            <EditableItem
              item={item}
              handleEdit={(e) => handleEdit(e, index)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default TaskKnow;
