import FrameworkFlipIt from "./FrameworkFlipIt";

function SFlipItNeverBetter(props) {
  const examplesFlips = [
    [
      "Once I start working on implementing the feature in code, I will become a better programmer.",
    ],
    ["Once I start working on building the payments infra, I will know more."],
    ["Once I start working on the design doc, I will feel less anxious."],
    [
      "Once I start working on sending an email to ask for help, I will feel braver.",
    ],
    ["Once I start working on rewriting the tests, I will feel less bored."],
  ];

  const conditions = [
    "know more",
    "feel more interested",
    "feel less anxious",
    "feel more confident",
    "have more energy",
    "feel braver",
    "feel ready",
    "feel less tired",
  ];

  //const behavior = "start working on it";

  const initialDesiredCondition = "become better at it";

  return (
    <FrameworkFlipIt
      title={props.title}
      examplesFlips={examplesFlips}
      excuses={conditions}
      //   behavior={behavior}
      initialDesiredCondition={initialDesiredCondition}
      encourage={
        "Often, the only way to improve at something is to go and try to do it repeatedly."
      }
    />
  );
}
export default SFlipItNeverBetter;
