import { useAuth } from "../../../util/auth.js";
import TaskBoardView from "./TaskBoardView.js";
import LegacyTaskBoardView from "./LegacyTaskBoardView.js";

function WrapperTaskBoardView(props) {
  // if yes data, return regular TaskBoardView with the boardData produced
  // if no data, return legacy to retrieve from db
  return (
    <>
      {props.tasksBoardData && (
        <TaskBoardView
          tasksBoardData={props.tasksBoardData}
          selectedProjectId={props.selectedProjectId}
        />
      )}
      {!props.tasksBoardData && (
        <LegacyTaskBoardView selectedProjectId={props.selectedProjectId} />
      )}
    </>
  );
}

export default WrapperTaskBoardView;
