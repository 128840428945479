import { useState } from "react";
import { useAuth } from "../../util/auth.js";
import { createSuggestion } from "../../util/db.js";
import "./SuggestFeelingModal.scss";

function SuggestFeelingModal(props) {
  const auth = useAuth();
  const email = auth.user.email;
  const [pending, setPending] = useState(false);

  const [feeling, setFeeling] = useState("");
  const [strategy, setStrategy] = useState("");

  function onSendClose() {
    //save feeling and strategy to db
    setPending(true);
    const query = createSuggestion({
      email: email,
      feeling: feeling,
      strategy: strategy,
    });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch(() => {
        // Hide pending indicator
        setPending(false);
      });
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="SuggestFeelingModal__card card">
        <header className="card-header">
          <p className="card-header-title">Suggest a Feeling or Strategy</p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <div className="card-content">
          <div className="m-2">
            <label className="label">Suggest Feeling</label>
            <div className="control">
              <input
                className="input"
                type="text"
                autoComplete="off"
                placeholder="Name or describe the feeling/thought"
                onChange={(e) => {
                  setFeeling(e.target.value);
                }}
                value={feeling}
              />
            </div>
          </div>

          <div className="m-2">
            <label className="label">Suggest/Request a Strategy</label>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                name="strategy"
                placeholder="Describe a strategy that you use for this feeling (you can leave this blank if you would like to request one)."
                onChange={(e) => {
                  setStrategy(e.target.value);
                }}
                value={strategy}
              />
            </div>
          </div>
        </div>
        <footer className="modal-card-foot justify-end">
          <button
            className={
              "button is-medium is-primary" + (pending ? " is-loading" : "")
            }
            onClick={() => onSendClose()}
          >
            Send
          </button>
        </footer>
      </div>
    </div>
  );
}

export default SuggestFeelingModal;
