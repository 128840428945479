import React, { useState } from "react";

import "./FocusStepModal.scss";

function FocusStepModal(props) {
  return (
    <div className="modal is-active">
      <div
        className="modal-background focus blue"
        onClick={() => props.onDone()}
      />
      <div className="FocusStepModal__card card p-3">
        <div className="is-flex is-justify-content-end">
          <button
            className="delete"
            aria-label="close"
            onClick={() => props.onDone()}
          >
            Done
          </button>
        </div>

        <div className="m-2 is-size-3">{props.stepName}</div>
      </div>
    </div>
  );
}

export default FocusStepModal;
