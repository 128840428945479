import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples.js";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";

function SReframeNervousness(props) {
  const [physicalSigns, setPhysicalSigns] = useState("");

  // Handlers for input changes
  const handlePhysicalSignsChange = (event) => {
    setPhysicalSigns(event.target.value);
  };

  // examples for physical signs of anxiety
  const examplesPhysicalSigns = [
    ["I feel my heart pounding and my breath quickening."],
    ["I feel my heart racing and my palms are sweaty."],
    ["I feel butterflies in my stomach. Not in a good way."],
  ];

  // Example reframing narratives for CarouselExamples
  const examplesReframing = [
    [
      "When my heart races and my hands sweat, it's my body telling me that the task before me is important to me. I am excited and fully engaged to tackle what comes.",
    ],
    [
      "When my heart races, it's pumping more blood to prepare me for action. I'm ready!",
    ],
    [
      "Sweaty palms are just a sign that I'm fully engaged and alive in this moment. Let's do this!",
    ],
    [
      "Butterflies in my stomach signal that I'm on the cusp of something great. Excitement is in the air!",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            Physical signs of nervousness—like sweaty hands, a racing heart, or
            stomach butterflies—are often your body's way of preparing you to
            meet a challenge that matters. By reframing these sensations, we can
            view them as signs of excitement and readiness, rather than fear.
            This strategy guides you through noticing these signs and
            consciously assigning them an empowering new meaning.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">
            Recognize the Physical Signs
          </div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                Note the physical sensations you feel when approaching the task{" "}
                <i>"{props.stepOrTask}"</i>. Describe what you feel in your
                body:
              </div>
              <textarea
                autoFocus={true}
                className="textarea"
                placeholder="Describe what you feel..."
                value={physicalSigns}
                onChange={handlePhysicalSignsChange}
              />
            </div>
            <div className="column examples">
              <div>See how others have felt:</div>
              <CarouselExamples examples={examplesPhysicalSigns} />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">
            Transfer Meaning to Empower
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="recall p-2 m-2">
                You noted feeling: <i>"{physicalSigns}"</i>
              </div>
              <div className="my-2 p-2">
                Now, <b>reimagine these physical signs</b> as your body's way of
                gearing up for something important. Muscles tense to give you
                strength, hands sweat and butterflies emerge because you're
                close to achieving something you want. Write a new narrative
                that reinterprets these sensations into symbols of excitement
                and eagerness:
              </div>
              <SaveThoughtForm placeholder="Transform your sensations into a narrative of excitement..." />
            </div>
            <div className="column examples">
              <div>See how others have felt:</div>
              <CarouselExamples examples={examplesReframing} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SReframeNervousness;
