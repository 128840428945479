const feelingsList = [
  {
    id: "overwhelmed",
    title: "I feel overwhelmed",
    strats: [
      "familiarWork", //ok
      "distinguishNoiseSignal", //ok
      "flipItOverwhelmed", //ok
      "worriesIntoVision", //ok
      "overcomeWorstFear",
      "reframeNervousness",
      "easyParts",
      "learningOpportunity",
      "corporateMindset",
    ],
  },
  {
    id: "motivationNeed",
    title: "I need more motivation",
    strats: [
      "motivationSharedGoals", //ok
      "learningOpportunity",
      "easyParts",
      "corporateMindset",
    ],
  },
  // TODO strats: pair reward with step, compassion, remember my why
  {
    id: "fearFailure",
    title: "I'm paralyzed with fear of failure",
    strats: [
      "distinguishCatastropheNoncritical", //ok
      "distinguishNoiseSignal", //ok
      "compassionateFearFailure", //ok
      "worriesIntoVision", //ok
      "rememberPastAccomplishment",
      "overcomeWorstFear",
      "putFeelingInQuotes",
      "reframeNervousness",
      "learningOpportunity",
      "easyParts",
      "corporateMindset",
    ],
  },
  {
    id: "anxious",
    title: "I'm feeling anxious/unsettled",
    strats: [
      "familiarWork", //ok
      "distinguishCatastropheNoncritical", //ok
      "distinguishNoiseSignal", //ok
      "flipItAnxious", //ok
      "worriesIntoVision", //ok
      "overcomeWorstFear",
      "putFeelingInQuotes",
      "reframeNervousness",
      "learningOpportunity",
      "easyParts",
      "corporateMindset",
    ],
  },
  {
    id: "neverBetter",
    title: "I'll never get better at it",
    strats: [
      "flipItNeverBetter", //ok
      "worriesIntoVision", //ok
      "learningOpportunity",
      //evidence //TODO
    ],
    // "transformNeverBetter"
  },
  {
    id: "skillLack",
    title: "I feel like I don't have the knowledge/skills to do this",
    strats: [
      "flipItSkills", //ok
      "learningOpportunity",
      //evidence //TODO
      "compassionateFearFailure", //ok
      "worriesIntoVision", //ok
      "overcomeWorstFear",
      "easyParts",
      "corporateMindset",
    ],
  },
  {
    id: "worried",
    title: "I am worried",
    strats: [
      "distinguishNoiseSignal", //ok
      "distinguishCatastropheNoncritical", //ok
      "familiarWork", //ok
      "worriesIntoVision", //ok
      "overcomeWorstFear",
      "putFeelingInQuotes",
      "learningOpportunity",
      "corporateMindset",
    ],
  },
  // { id: "lonely", title: "I feel lonely" },
  {
    id: "comparingOthers",
    title: "I'm comparing myself to others and I feel bad",
    strats: [
      "traitAdmire", //ok
      "learningOpportunity",
      "corporateMindset",
      // TODO : compare with yourself at your worst
      //"opinion", //TODO
      //compassionate? //TODO
    ],
  },
  {
    id: "later",
    title: "I'll do it later",
    strats: [
      "flipItNotFeelLikeIt", //ok
      "motivationSharedGoals", //ok
      "corporateMindset",
      //"compassionate //TODO
    ],
    // "transformLater",
  },
  //{ id: "whereBegin", title: "I have no idea what to do next/where to begin", strats: [""], },
  {
    id: "physicalAnxiety",
    title:
      "I have physical anxiety symptoms (sweaty hands, racing heart, etc.)",
    strats: [
      "reframeNervousness",
      "flipItAnxious", //ok
      "familiarWork", //ok
      "distinguishNoiseSignal", //ok
      "worriesIntoVision", //ok
      "putFeelingInQuotes",
      "overcomeWorstFear",
      "learningOpportunity",
    ],
  },
  {
    id: "shouldKnow",
    title: "I feel like I should already know this",
    strats: [
      "shouldPreferKnow", //ok
      "learningOpportunity",
      "corporateMindset",
    ],
  },

  {
    id: "tooSlow",
    title: "I am too slow",
    strats: [
      "evidenceSlow", //ok
      "learningOpportunity",
      "corporateMindset",
    ],
  },

  // { id: "evenStart", title: "I can't bring myself to even start" },
  //{ id: "procrastinating", title: "I'm procrastinating", strats: ["flipItNotFeelLikeIt", "familiarWork"] },
  {
    id: "stressed",
    title: "I'm stressed",
    strats: [
      "familiarWork",
      "distinguishNoiseSignal",
      "worriesIntoVision",
      "overcomeWorstFear",
      "learningOpportunity",
      "putFeelingInQuotes",
      "reframeNervousness",
      "easyParts",
      "corporateMindset",
    ],
  },

  {
    id: "tooComplicated",
    title: "This is too complicated for me to do",
    strats: [
      "evidenceComplicated", //ok
      "familiarWork", //ok
      "learningOpportunity",
      "worriesIntoVision", //ok
      "easyParts",
      "corporateMindset",
    ],
    //"transformComplicated",
  },
  {
    id: "thinkIncompetent",
    title: "I'm afraid they will think I'm incompetent",
    strats: [
      //"opinion", //TODO
      "traitAdmire", //ok
      "worriesIntoVision", //ok
      "learningOpportunity",
      "corporateIncompetent",
    ],
  },
  // {
  //   id: "innerResistance",
  //   title: "I feel inner resistance towards this task",
  // },
  {
    id: "cantAsk",
    title: "I feel like I can't ask for help/make questions",
    strats: [
      "evidenceCantAsk", //ok
      "learningOpportunity",
      "corporateHelp",
    ],
  },
  // {
  //   id: "lateLong",
  //   title: "I feel that I am running late/taking too long",
  // TODO strat: examine evidence?
  // },
  {
    id: "shouldFaster",
    title: "I feel like I should be faster",
    strats: [
      "shouldPreferFaster", //ok
      "worriesIntoVision", //ok
      "learningOpportunity",
      "corporateMindset",
    ],
  },
  {
    id: "notSmart",
    title: "I'm not smart enough",
    strats: [
      "evidenceNotSmart", //ok
      "familiarWork", //ok
      "traitAdmire", //ok
      "worriesIntoVision", //ok
      "learningOpportunity",
      "easyParts",
      "corporateMindset",
    ],
    // "transformNotSmart",
  },
  {
    id: "notFeelLikeIt",
    title: "I don't feel like doing this",
    strats: [
      "motivationSharedGoals", //ok
      "flipItNotFeelLikeIt", //ok
      "familiarWork", //ok
      "worriesIntoVision", //ok
      "putFeelingInQuotes",
      "learningOpportunity",
      "easyParts",
      "corporateMindset",
    ],
    // "transformNotFeelLikeIt",
  },
];
// I don't know where to start
// I'm not sure what to do next

export default feelingsList;
