import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm";

function SFlipIt(props) {
  const [shouldInput, setShouldInput] = useState(
    props.initialshould || undefined
  );
  const onChangeShouldHandler = (event) => {
    setShouldInput(event.target.value);
  };

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            <p>
              A "should" in our self-talk is a negative thinking pattern that
              can make us feel guilty and as if we have failed in some way. This
              strategy replaces your "should" statement with a more flexible and
              encouraging thought.
            </p>
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify should statement*/}
          <div className="boxStepSubtitle">Identify your should statement</div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              What are you telling yourself you SHOULD?
              <div className="control mx-2 my-4">
                "I should{" "}
                <input
                  className={`input is-small ${
                    shouldInput ? "" : "is-danger"
                  } autoWidth`}
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeShouldHandler}
                  value={shouldInput}
                />
                ."
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Here are some examples of should statements from others in your
                industry:
              </div>
              <CarouselExamples examples={props.examplesShoulds} />
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: replace with prefer*/}
          <div className="boxStepSubtitle">Reframe the thought</div>
          <div className="columns m-2 mt-3">
            <div className="column is-6 mx-3">
              <div className="mb-3">
                Let's replace the rigid phrase of <i>"I should"</i> with a more{" "}
                <b>flexible</b> and <b>guilt-free</b> alternative:
              </div>

              <SaveThoughtForm
                defaultValue={`I would prefer to ${shouldInput}.`}
              />
              <div className="my-3">
                Some good replacements:
                <li>
                  <i>I would prefer</i>
                </li>
                <li>
                  <i>I could</i>
                </li>
                <li>
                  <i>I wish</i>
                </li>
                <li>
                  <i>I would like</i>
                </li>
              </div>
            </div>

            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by should replacements from others in your
                industry:
              </div>
              <CarouselExamples examples={props.examplesShouldReplacements} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SFlipIt;
