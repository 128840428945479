import { useReducer, useEffect, useRef } from "react";
import firebase from "./firebase";
import { Mixpanel } from "./mixpanel";

const firestore = firebase.firestore();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
export function useUser(uid) {
  return useQuery(uid && firestore.collection("users").doc(uid));
}

// Create a new user
export function createUser(uid, data) {
  return firestore
    .collection("users")
    .doc(uid)
    .set({ uid, ...data }, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return firestore.collection("users").doc(uid).update(data);
}

/**** PROJECTS ****/
// Fetch all projects by owner (hook)
export function useProjectsByOwner(userId) {
  return useQuery(
    userId && firestore.collection("users").doc(userId).collection("projects")
    //.orderBy("createdAt", "desc")
  );
}

export function createProject(userId, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .add({
      ...data,
      createdAt: serverTimestamp(),
    });
}

export function useLastSelectedByOwner(userId) {
  return useQuery(
    userId &&
      firestore
        .collection("users")
        .doc(userId)
        .collection("settings")
        .doc("lastSelected")
  );
}

export function updateLastSelectedByOwner(userId, data) {
  const lastSelectedRef = firestore
    .collection("users")
    .doc(userId)
    .collection("settings")
    .doc("lastSelected");

  lastSelectedRef.get().then((docSnapshot) => {
    if (docSnapshot.exists) {
      lastSelectedRef.onSnapshot((doc) => {
        // do stuff with the data
      });
    } else {
      lastSelectedRef.set({ ...data }); // create the document
    }
  });
  return firestore
    .collection("users")
    .doc(userId)
    .collection("settings")
    .doc("lastSelected")
    .update(data);
}

export function updateProject(userId, projectId, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .update(data);
}

export function useProject(userId, projectId) {
  return useQuery(
    projectId &&
      firestore
        .collection("users")
        .doc(userId)
        .collection("projects")
        .doc(projectId)
  );
}

export function getProject(userId, projectId) {
  const projectRef = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId);

  return projectRef.get().then((doc) => {
    if (doc.exists) {
      return doc.data();
    } else {
      return null;
    }
  });
}

/**** ACCOMPLISHMENTS ****/

// Fetch item data (hook)
export function useAccomplishment(userId, id) {
  return useQuery(
    id &&
      firestore
        .collection("users")
        .doc(userId)
        .collection("accomplishments")
        .doc(id)
  );
}

// Fetch all accomplishments by owner (hook)
export function useAccomplishmentsByOwner(userId) {
  return useQuery(
    userId &&
      firestore.collection("users").doc(userId).collection("accomplishments")
    //.orderBy("createdAt", "desc")
  );
}

// Fetch all accomplishments public (hook) -- There are now copies of owner accomplish.
export function useAccomplishmentsPublic() {
  return useQuery(
    firestore
      .collection("accomplishments")
      .where("public", "==", true)
      .orderBy("createdAt", "desc")
  );
}

// Create a new accomplishment
export function createAccomplishment(userId, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("accomplishments")
    .add({
      ...data,
      createdAt: serverTimestamp(),
    });
}

// Update an accomp
export function updateAccomplishment(userId, id, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("accomplishments")
    .doc(id)
    .update(data);
}

// Delete an accomp
export function deleteAccomplishment(userId, id) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("accomplishments")
    .doc(id)
    .delete();
}

/**** AFFIRMATIONS ****/

// Fetch item data (hook)
export function useAffirmation(id) {
  return useQuery(id && firestore.collection("affirmations").doc(id));
}

// Fetch all affirmations public (hook)
export function useAffirmationsPublic() {
  return useQuery(
    firestore.collection("publicAffirmations")
    //.orderBy("createdAt", "desc")
  );
}
// Fetch all affirmations by owner (hook)
export function useAffirmationsByOwner(owner) {
  return useQuery(
    owner &&
      firestore
        .collection("affirmations")
        .where("owner", "==", owner)
        .orderBy("createdAt", "desc")
  );
}

// Fetch the affirmation for this specific task
export function useAffirmationForTask(userId, taskId) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(taskId);
}

// Create a new affirmation
export async function createAffirmation(userId, taskId, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(taskId)
    .update({ affirmation: { ...data, createdAt: serverTimestamp() } });
}

// Delete an affirmation //and this
export function deleteAffirmation(id) {
  return firestore.collection("affirmations").doc(id).delete();
}

/**** THOUGHTS ****/

// Fetch item data (hook)
export function useThought(userId, id) {
  return useQuery(
    id &&
      firestore.collection("users").doc(userId).collection("thoughts").doc(id)
  );
}

// Fetch all pinned data by owner (hook)
export function usePinnedByOwner(userId) {
  return useQuery(
    userId &&
      firestore
        .collection("users")
        .doc(userId)
        .collection("settings")
        .doc("pinned")
    //  .orderBy("createdAt", "desc")
  );
}

// Fetch all thoughts by owner for a specific task (hook)
export function useThoughtsByOwnerForTask(userId, taskId) {
  return useQuery(
    userId &&
      firestore
        .collection("users")
        .doc(userId)
        .collection("thoughts")
        .where("taskIds", "array-contains", taskId)
    //  .orderBy("createdAt", "desc")
  );
}

// Create a new thought
export function createThought(userId, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("thoughts")
    .add({
      ...data,
      createdAt: serverTimestamp(),
    });
}

// Update a pinned data
export function updatePinned(userId, data) {
  const pinnedRef = firestore
    .collection("users")
    .doc(userId)
    .collection("settings")
    .doc("pinned");

  pinnedRef.get().then((docSnapshot) => {
    if (docSnapshot.exists) {
      //pinnedRef.update(data);; //Had to do the return below to get a promise (didnt know how to return this as promise)
    } else {
      pinnedRef.set({ ...data }); // create the document
    }
  });

  return firestore
    .collection("users")
    .doc(userId)
    .collection("settings")
    .doc("pinned")
    .update(data);
}

// Delete an thought
export function deleteThought(userId, id) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("thoughts")
    .doc(id)
    .delete();
}

// Fetch all public thoughts (hook)
export function usePublicThoughts() {
  return useQuery(
    firestore.collection("publicThoughts")
    //.orderBy("createdAt", "desc") //order by votes eventually or by tags!
  );
}

/**** TASKS ****/

// Fetch item data (hook)
export function useTask(userId, taskId) {
  return useQuery(
    taskId &&
      firestore.collection("users").doc(userId).collection("tasks").doc(taskId)
  );
}

// Fetch all tasks by owner (hook)
export function useTasksByOwner(userId) {
  return useQuery(
    userId && firestore.collection("users").doc(userId).collection("tasks")
    //.orderBy("createdAt")
  );
}

// Create a new task
export function createTask(userId, data) {
  Mixpanel.track("Task created");
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .add({
      ...data,
      createdAt: serverTimestamp(),
    });
}

// Create a new task
export function createTaskWithId(userId, data) {
  Mixpanel.track("Task created with id");
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(data.id)
    .set({
      ...data,
      createdAt: serverTimestamp(),
    });
}

// Create task with given id
export function setNewTask(userId, docId, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(docId)
    .set({
      ...data,
      createdAt: serverTimestamp(),
    });
}

// Update an task
export function updateTask(userId, id, data) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(id)
    .update(data);
}

// Delete an task
export function deleteTask(userId, id) {
  return firestore
    .collection("users")
    .doc(userId)
    .collection("tasks")
    .doc(id)
    .delete();
}

// Update a Strategy
export function updateStrategy(id, data) {
  return firestore.collection("strategies").doc(id).update(data);
}

// Create a new feeling/strategy suggestion
export function createSuggestion(data) {
  return firestore.collection("suggestions").add({
    ...data,
    createdAt: serverTimestamp(),
  });
}
export function createFeedback(data) {
  return firestore.collection("feedback").add({
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function createContactMsg(data) {
  return firestore.collection("contactMessages").add({
    ...data,
    createdAt: serverTimestamp(),
  });
}

/**** HELPERS ****/

// Reducer for useQuery hook state and actions
const reducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", data: undefined, error: undefined };
    case "loading":
      return { status: "loading", data: undefined, error: undefined };
    case "success":
      return { status: "success", data: action.payload, error: undefined };
    case "error":
      return { status: "error", data: undefined, error: action.payload };
    default:
      throw new Error("invalid action");
  }
};

// Custom React hook that subscribes to a Firestore query
function useQuery(query) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState = {
    status: query ? "loading" : "idle",
    data: undefined,
    error: undefined,
  };

  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);

  // Gives us previous query object if query is the same, ensuring
  // we don't trigger useEffect on every render due to query technically
  // being a new object reference on every render.
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: "idle" });
      return;
    }

    dispatch({ type: "loading" });

    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return queryCached.onSnapshot(
      (response) => {
        // Get data for collection or doc
        const data = response.docs
          ? getCollectionData(response)
          : getDocData(response);

        dispatch({ type: "success", payload: data });
      },
      (error) => {
        dispatch({ type: "error", payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes

  return state;
}

// Get doc data and merge doc.id
function getDocData(doc) {
  return doc.exists === true ? { id: doc.id, ...doc.data() } : null;
}

// Get array of doc data from collection
function getCollectionData(collection) {
  return collection.docs.map(getDocData);
}

// Used by useQuery to store Firestore query object reference
function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;

  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}
