import React from "react";

class Collapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cardState: props.open ? true : false };
    this.toggleCardState = this.toggleCardState.bind(this);
  }

  toggleCardState() {
    this.setState({ cardState: !this.state.cardState });
  }

  render() {
    const { title, subtitle, children } = this.props;
    const { cardState } = this.state;

    return (
      <div className="card my-3" aria-hidden={cardState ? "false" : "true"}>
        <nav
          className="level mx-2"
          style={{ cursor: "pointer", margin: 0 }}
          onClick={this.toggleCardState}
        >
          <div className="level-left">
            <div className="level-item">
              <p className="title is-5">{title}</p>
            </div>
          </div>

          <div
            className="level-right"
            style={{
              flexShrink: "1",
            }}
          >
            <div
              className="level-item"
              style={{
                flexShrink: "1",
              }}
            >
              <p className="subtitle is-5 ml-5 my-2">{subtitle}</p>
            </div>
            <p className="level-item">
              <a className="card-header-icon">
                <span
                  className="icon"
                  style={{
                    transform: cardState ? null : "rotate(180deg)",
                    transition: "transform 250ms ease-out",
                  }}
                >
                  <i className="fa fa-angle-up"></i>
                </span>
              </a>
            </p>
          </div>
        </nav>
        <div
          className="card-body"
          style={{
            maxHeight: cardState ? "none" : "0em",
            overflow: "auto",
            transition: "max-height 200ms ease-in-out",
          }}
        >
          <div className="card-content">
            <div>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Collapse;
