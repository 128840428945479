import { useState } from "react";
import { useAuth } from "../../../util/auth.js";
import { useTask } from "../../../util/db.js";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faSignalStream,
} from "@fortawesome/pro-solid-svg-icons";
import AddStepInput from "./utils/AddStepInput.js";
import RenderSComponent from "./RenderSComponent.js";
import CarouselExamples from "./utils/CarouselExamples.js";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light-border.css";

const examplesWorries = [
  [
    "I will not finish on time",
    "They will think I am dumb",
    "I don't have all the information I need",
  ],
  [
    "I will make a mistake that will be embarrassing",
    "If we don't consult X team, they will feel cut out",
  ],
];

function SDistinguishNoiseSignal(props) {
  const auth = useAuth();

  // LIST
  const [worriesList, setWorriesList] = useState([]);
  //temporary input value
  const [name, setname] = useState("");
  const handleAdd = () => {
    if (name.length > 0) {
      setWorriesList(worriesList.concat(name));
      setname("");
    }
  };
  const handleChange = (event) => {
    setname(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
    }
  };

  // Get task info (for steps)
  const { id } = useParams();
  const { data: taskData } = useTask(auth.user.uid, id);

  // RETURN STRATEGY
  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          {/* Although worries can be helpful by motivating us to take action,
            identifying potential risks, and prompting us to seek support when
            needed, other types of worries can lead to anxiety, distract us from
            our tasks, and decrease productivity. */}
          Separate the worries that are helpful in bringing something to your
          attention that requires action, from those that are just noise in your
          head.
        </div>
      }
      steps={[
        <div>
          {/* first step, list all worries*/}
          <div className="columns">
            <div className="column is-6 m-3">
              <div className="mb-4 subtitle">
                Think of <i>"{props.stepOrTask}"</i>. Write down all the{" "}
                <b>worries that come to your mind</b>:
              </div>
              <div className="is-flex is-align-items-center">
                <input
                  className="input"
                  autoComplete="off"
                  autoFocus={true}
                  type="text"
                  placeholder="I worry that... "
                  value={name}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleAdd}
                />
                <button className="button is-small mx-2 " onClick={handleAdd}>
                  Add
                </button>
              </div>

              <div className="p-2">
                {worriesList.map((item) => {
                  return <li>{item}</li>;
                })}
              </div>
            </div>
            <div className="column examples">
              <div>Get inspired by answers from others in your field:</div>
              <CarouselExamples examples={examplesWorries} />
            </div>
          </div>
        </div>,

        <div>
          {/* second step, learn noise vs signal */}
          <div className="subtitle">Noise vs Signal</div>
          <div className="columns">
            <div className="column is-2 has-text-centered"></div>
            <div className="column is-2 has-text-centered is-size-2">
              Signal
            </div>
            <div className="column is-2 has-text-centered is-size-3">vs</div>
            <div className="column is-2 has-text-centered is-size-2">Noise</div>
            <div className="column is-2 has-text-centered"></div>
            <div className="column is-2 has-text-centered"></div>
          </div>
        </div>,

        <div>
          {/* second step, learn noise vs signal */}
          <div className="subtitle">Noise vs Signal</div>
          <div className="columns">
            <div className="column is-2 has-text-centered"></div>
            <div className="column is-2 has-text-centered is-size-2 is-align-content-center is-fontcolor-green">
              Signal
            </div>
            <div className="column is-6 has-text-centered">
              <b>You can take action to address it.</b> This worry can motivate
              you to take action. It can guide you to plan, prepare and act to
              avoid a risk.
            </div>
            <div className="column is-2 has-text-centered"></div>
          </div>
        </div>,

        <div>
          {/* second step, learn noise vs signal */}
          <div className="subtitle">Noise vs Signal</div>
          <div className="columns">
            <div className="column is-2 has-text-centered"></div>
            <div className="column is-6 has-text-centered">
              <b>You can't do anything about it.</b> This worry is about
              something not under your control. It is not helpful, just noise in
              your head.
            </div>
            <div className="column is-2 has-text-centered is-size-2 is-fontcolor-brown">
              Noise
            </div>
            <div className="column is-2 has-text-centered"></div>
          </div>
        </div>,

        <div>
          {/* third step, decide if noise or signal */}
          <div className="subtitle">Mark each worry as Noise or Signal</div>
          <div className="p-3 is-neutralLighter">
            <div className="m-4">
              {worriesList.map((worry) => (
                <WorryRow worry={worry} taskData={taskData} />
              ))}
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

function WorryRow(props) {
  const examplesActions = [
    [
      "Break the task down into smaller, manageable parts with specific deadlines for each part.",
      "Prioritize the most important parts of the task and work on those first.",
    ],
    [
      "Double-check my work, seek feedback and help when needed.",
      "Prepare a plan of what can we do if get over quota.",
    ],
    ["Seek input and feedback from X team before sending out for review."],
  ];

  const [isSignal, setIsSignal] = useState(false);
  const [isNoise, setIsNoise] = useState(false);

  function handleSetSignal() {
    setIsNoise(false);
    setIsSignal(true);
  }

  function handleSetNoise() {
    setIsSignal(false);
    setIsNoise(true);
  }

  return (
    <div className="columns">
      <div className="column is-1">
        <div
          className={`is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered is-flex-wrap-wrap`}
          onClick={() => {
            handleSetNoise();
          }}
        >
          <Tippy
            theme="light-border"
            arrow={true}
            content={
              <div className="m-2">
                <b>Noise: </b>Not under your control, nothing you can do about
                it.
              </div>
            }
          >
            <div className="pointable">
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="l"
                className={`mr-1 mt-1 ${
                  isNoise ? "is-fontcolor-brown" : "obscured"
                }`}
              />
              <div className={`${isNoise ? "has-text-weight-semibold" : ""}`}>
                Noise
              </div>
            </div>
          </Tippy>
        </div>
      </div>
      <div className="column is-1">
        <div
          className={`is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered is-flex-wrap-wrap`}
          onClick={() => {
            handleSetSignal();
          }}
        >
          <Tippy
            theme="light-border"
            content={
              <div className="m-2">
                <b>Signal: </b>You can take action to prevent it.
              </div>
            }
          >
            <div className="pointable">
              <FontAwesomeIcon
                icon={faSignalStream}
                size="l"
                className={`mr-1 mt-1 ${
                  isSignal ? "is-fontcolor-green" : "obscured"
                }`}
              />
              <div className={`${isSignal ? "has-text-weight-semibold" : ""}`}>
                Signal
              </div>
            </div>
          </Tippy>
        </div>
      </div>
      <div className="column">
        <div className="is-flex is-flex-direction-column">
          <div className="is-flex">
            <div className={`${isNoise ? "striked-through" : ""}`}>
              {props.worry}
            </div>
            {isNoise && (
              <div className="mx-3 is-fontcolor-gray">
                <i>Noise to be cleared</i>
              </div>
            )}
          </div>
          {isSignal && (
            <div className="columns">
              <div className="column is-7">
                <AddStepInput
                  taskData={props.taskData}
                  placeholder={"What action can I take to prevent it?"}
                />
              </div>
              <div className="column examples">
                <div>Get inspired by actions from others in your field:</div>
                <CarouselExamples examples={examplesActions} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default SDistinguishNoiseSignal;
