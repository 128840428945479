import EvidenceFramework from "./FrameworkEvidence";

function SEvidenceComplicated(props) {
  const thoughtToExamine = "This is too complicated for me";

  // SEE CarouselAffirmationExamples to see how to fetch examples from firestore
  const examplesSupport = [
    ["I've never done this before", "I don't have the knowledge necessary."],
    [
      "In school I didn't have the best grades.",
      "This is a very large codebase.",
    ],
    [
      "I don't understand many of the terms",
      "I am just a junior engineer and this is a complex component",
    ],
  ];

  const examplesNotSupport = [
    [
      "In the past, I have felt things were too complicated and I end up doing them somehow.",
      "I have a strong support network of colleagues or mentors who can help me if I get stuck.",
    ],
    [
      "I have received positive feedback or recognition for my work on similar tasks in the past.",
      "I have relevant experience and skills that I can apply to this task.",
    ],
    [
      "I have a positive attitude and am willing to put in the work to learn and overcome challenges.",

      "I have already made some progress on this task and feel like I am making headway.",
    ],
  ];

  const examplesThoughts = [
    [
      "Even though there's many things I don't know, I do like learning when I feel motivated.",
    ],
    [
      "While this task is challenging, I am willing to put in the effort to learn and grow from the experience.",
    ],
    [
      "Although I don't have all the necessary skills yet, I am confident in my ability to learn and improve over time.",
    ],
    [
      "I may not know everything about this task, but I am willing to seek out help and collaborate with others to overcome obstacles.",
    ],
    [
      "While I may feel overwhelmed at times, I know that I have the ability to break down complex tasks into smaller, manageable steps.",
    ],
    [
      "Even though I may not have a lot of experience with this particular task, I have successfully tackled similar challenges in the past.",
    ],
    [
      "Although I may not be an expert in this field, I am willing to approach the task with a growth mindset and a willingness to learn and adapt as necessary.",
    ],
  ];

  return (
    <EvidenceFramework
      thoughtToExamine={thoughtToExamine}
      examplesSupport={examplesSupport}
      examplesNotSupport={examplesNotSupport}
      examplesThoughts={examplesThoughts}
    />
  );
}

export default SEvidenceComplicated;
