import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import { useTask } from "../../../util/db.js";
import { useAuth } from "../../../util/auth.js";
import { useParams } from "react-router-dom";
import AddStepInput from "./utils/AddStepInput";
import RenderSComponent from "./RenderSComponent.js";

function STraitAdmire(props) {
  const [nameInput, setNameInput] = useState("");
  const [traitInput, setTraitInput] = useState("");
  const onChangeNameHandler = (event) => {
    setNameInput(event.target.value);
  };
  const onChangeTraitHandler = (event) => {
    setTraitInput(event.target.value);
  };

  // Get task info (for steps)
  const { id } = useParams();
  const auth = useAuth();
  const { data: taskData } = useTask(auth.user.uid, id);

  const examplesTraits = [
    ["Their ability to work under pressure"],
    ["Their knowledge of X subject"],
    ["Their attention to detail"],
    ["Their communication skills"],
    ["Their engineering skills regarding X"],
    ["Their discipline"],
    ["Their charisma"],
    ["Their speed to complete tasks"],
  ];

  const examplesSteps = [
    ["I will be more encouraging of my teammates at the standup meeting"],
    [
      "I will research and read up on X subject. I wilk ask them for recommendations of tutorials.",
    ],
    [
      "In tomorrow's meeting, I will practice active listening and speaking clearly and concisely",
    ],
    [
      "Before sending out for review, I will double-check my work and take the time to review it carefully. I will utilize checklists to ensure I don't miss anything.",
    ],
  ];
  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            Comparing yourself to coworkers can harm your mental well-being by
            generating feelings of self-doubt, inadequacy, and anxiety. However,
            if done with a clear focus of improving your own strenghts, it can
            be a good source of motivation and inspiration.
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify person*/}
          <div className="boxStepSubtitle">
            Identify a trait or skill you admire from someone
          </div>
          <div className="columns">
            <div className="column is-7">
              Whose name comes to mind when you think about comparing yourself
              to others?
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeNameHandler}
                  value={nameInput}
                />
              </div>
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Identify trait*/}
          <div className="boxStepSubtitle">
            Identify a trait or skill you admire from someone
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              What is a particular quality or skill of {nameInput}'s that you
              find admirable?
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeTraitHandler}
                  value={traitInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by thought transformations from others in your
                industry:
              </div>
              <CarouselExamples examples={examplesTraits} />
            </div>
          </div>
        </div>,

        <div>
          {/* 3rd step: Decide how to inject it*/}
          <div className="boxStepSubtitle">
            Analyze the skill and identify one specific way you can inject it
            into this task
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              <div className="m-3">
                Observe {nameInput}'s behavior and and{" "}
                <b>analyze what they do that makes them so effective</b>. Take
                note of their actions, habits, and approaches that you can
                replicate in your own work.
              </div>
              <div className="m-3">
                Based on that, what step can you take to <b>add</b> a little of{" "}
                <b>"{traitInput}"</b> into the task <b>"{taskData?.name}"</b>?
              </div>
              <AddStepInput
                taskData={taskData}
                placeholder={"Just one small step, be as specific as you can"}
              />
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by traits others in your industry admire:
              </div>
              <CarouselExamples examples={examplesSteps} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default STraitAdmire;
