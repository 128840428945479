import { Steps, useSteps } from "react-step-builder";
import { Mixpanel } from "../../../util/mixpanel";

// This component will render the content of the strategy
// and control how all strategies look, their ui for nav,
// abstract the library used for the steps, etc.
function RenderSComponent(props) {
  // STEPS NAV
  const { prev, next, hasNext, hasPrev } = useSteps();

  function handlePrev() {
    Mixpanel.track("Previous step clicked");
    prev();
  }

  function handleNext() {
    Mixpanel.track("Next step clicked");
    next();
  }

  return (
    <div className="m-3">
      <div className="block">
        <h1 className="title is-3">{props.title}</h1>
      </div>
      <div className="block mx-2">{props.intro}</div>

      <div className={"box"}>
        <Steps>
          {props.steps.map((step) => {
            return step;
          })}
        </Steps>
        <div>
          <div className={"p-3 is-flex"}>
            <button
              className="button mx-1 "
              onClick={handlePrev}
              disabled={!hasPrev}
            >
              <i className="fa fa-chevron-left mx-2"></i>
              Prev
            </button>
            <button
              className="button mx-1 "
              onClick={handleNext}
              disabled={!hasNext}
            >
              Next
              <i className="fa fa-chevron-right mx-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderSComponent;
