import FrameworkShouldPrefer from "./FrameworkShouldPrefer";

function SShouldPreferFaster(props) {
  const examplesShoulds = [
    ["I should have finished already."],
    ["I should be able to do this faster."],
    ["I should already know this."],
    ["I should be faster."],
  ];

  const examplesShouldReplacements = [
    ["I wish I had finished already."],
    ["I would love to be able to do this faster."],
    ["I would like to already know this."],
    ["I would prefer to be faster."],
  ];

  // I should:
  const initialshould = "be faster";
  // I would prefer to:

  return (
    <FrameworkShouldPrefer
      title={props.title}
      initialshould={initialshould}
      examplesShoulds={examplesShoulds}
      examplesShouldReplacements={examplesShouldReplacements}
    />
  );
}

export default SShouldPreferFaster;
