import React from "react";
import "./Switch.css";

const Switch = ({ isPush, handleToggle, setPush }) => {
  return (
    <>
      <div
        className={`is-size-5 mx-3 hoverable ${
          isPush ? "unselected" : "workT"
        }`}
        onClick={() => {
          setPush(false);
        }}
      >
        Ease into the flow
      </div>
      <input
        checked={isPush}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className={`react-switch-label ${isPush ? "push" : "work"}`}
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </label>
      <div
        className={`is-size-5 mx-3 hoverable ${
          isPush ? "pushT" : "unselected"
        }`}
        onClick={() => {
          setPush(true);
        }}
      >
        Push myself
      </div>
    </>
  );
};

export default Switch;
