import React, { useEffect, useState } from "react";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { createFeedback } from "../util/db.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import focusfeel from "../resources/logos/focusfeel.svg";

function Navbar(props) {
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav
      // className="navbar is-dark"
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/">
          <img
            className="image m-4"
            src={focusfeel}
            alt="FocusFeel Logo"
            width="180px"
          />
        </Link>

        <div
          className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
        {/* {auth.user && auth.user.planIsActive && ( */}
        {auth.user && (
          <div className="navbar-start">
            <Link to="/app/tasks" className="navbar-item">
              My Tasks
            </Link>
            {/* <Link to="/app/feelings" className="navbar-item">
              Feelings
            </Link>
            <a className="navbar-item">Soft Skills</a> */}

            {/* <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">More</a>

            <div className="navbar-dropdown">
              <a className="navbar-item">About</a>
              <a className="navbar-item">Contact</a>
              <hr className="navbar-divider" />
              <a className="navbar-item">Feedback</a>
            </div>
          </div> */}
          </div>
        )}

        {/* {!auth.user?.planIsActive && (
          <div className="navbar-start">
            <Link to="/pricing" className="navbar-item">
              Pricing
            </Link>
          </div>
        )} */}

        <div className="navbar-end">
          {!auth.user && (
            <Link className="navbar-item" to="/auth/signin">
              Sign in
            </Link>
          )}
          {auth.user && (
            <>
              {/* <Link to="/app/how-it-works" className="navbar-item">
                How It Works
              </Link> */}
              <Link to="/app/tasks?tour=true" className="navbar-link">
                Start guided tour
              </Link>
              <div className="navbar-item">
                <FeedbackDropdown />
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link to="/" className="navbar-link">
                  Account
                </Link>
                <div className="navbar-dropdown is-boxed">
                  <Link to="/settings/general" className="navbar-item">
                    Settings
                  </Link>
                  <hr className="dropdown-divider" />
                  <Link
                    to="/auth/signout"
                    className="navbar-item"
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

function FeedbackDropdown(props) {
  const [pending, setPending] = useState(false);
  const [feedback, setFeedback] = useState();
  const [sent, setSent] = useState(false);

  const auth = useAuth();
  const email = auth.user.email;

  useEffect(() => {
    setSent(sent);
  }, [sent]);

  function onSendClose() {
    //save feeling and strategy to db
    setPending(true);
    const query = createFeedback({
      email: email,
      feedback: feedback,
      isReviewed: false,
      isAddressed: false,
    });

    query
      .then(() => {
        setPending(false);
        setSent(true);
        setTimeout(() => {
          setSent(false);
        }, 700);
        setFeedback("");
      })
      .catch(() => {
        // Hide pending indicator
        setPending(false);
      });
  }

  return (
    <>
      {!sent && (
        <div className="dropdown is-hoverable">
          <div className="dropdown-trigger">
            <button
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu6"
            >
              <span>Quick Feedback</span>
            </button>
          </div>
          <div
            className="dropdown-menu quick-feedback"
            id="dropdown-menu6"
            role="menu"
          >
            <div className="dropdown-content">
              <div className="dropdown-item">
                <div className="subtitle">
                  Please let us know anything we can improve or fix!
                </div>
                <div className="control">
                  <textarea
                    className="textarea is-medium"
                    type="text"
                    name="feedback"
                    autoFocus={true}
                    onChange={(e) => {
                      setFeedback(e.target.value);
                    }}
                    value={feedback}
                  />
                </div>
                <div className="is-flex is-justify-content-end">
                  <button
                    className={
                      " button is-primary mt-2" + (pending ? " is-loading" : "")
                    }
                    onClick={() => onSendClose()}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {sent && (
        <div className="dropdown is-right is-active">
          <div className="dropdown-trigger">
            <button
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu6"
            >
              <span>Quick Feedback</span>
            </button>
          </div>
          <div
            className="dropdown-menu quick-feedback"
            id="dropdown-menu6"
            role="menu"
          >
            <div className="dropdown-content">
              <div className="dropdown-item is-flex is-align-items-center">
                <FontAwesomeIcon
                  icon={faHeart}
                  size="s"
                  className="is-urgent m-2"
                />
                <div className="subtitle has-text-weight-semibold">
                  Thank you for your feedback!
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
