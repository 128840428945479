import FrameworkCompassionate from "./FrameworkCompassionate";

function SCompassionateFearFailure(props) {
  const examplesBehaviors = [
    [
      "I am avoiding writing the doc because I am afraid that they will take one look at it and think I have no idea what I am doing.",
    ],
    [
      "I am avoiding getting started on this task because I am uncomfortable with not knowing exactly what to do before I start.",
    ],
    [
      "I keep putting off this project because I'm worried I won't be able to do it perfectly.",
    ],
    [
      "I don't want to send the email because I'm afraid of saying something stupid or being criticized.",
    ],
    [
      "I haven't started this step because I don't think I'm qualified enough and I'm scared of being judged for it.",
    ],
    [
      "I'm procrastinating on writing this report because I'm worried it won't be good enough and my manager will be disappointed.",
    ],
    [
      "I'm avoiding trying this because it requires a new skill and I'm afraid of looking foolish in front of others.",
    ],
  ];

  const examplesUnderstanding = [
    [
      "That's understandable. Everyone cares about their teammates' opinion of them.",
    ],
    [
      "I can see how the fear of failure is holding me back, and that's okay to feel that way. It's natural to want to avoid the possibility of disappointment or rejection.",
    ],

    [
      "It makes sense that I'm feeling anxious about this task. It's important to me and I want to do well.",
    ],
    [
      "I understand that the uncertainty of this situation is making me feel uncomfortable. Most people would feel like that.",
    ],
    [
      "I can see how the pressure to perform is affecting me. It's okay. I just really care and want to do my best.",
    ],
  ];

  const examplesPlan = [
    [
      "The best plan for me to do a good enough job is to start with a bad attempt at it. Just do it badly to begin with. Then we will take it from there.",
    ],
    [
      "The best plan for me to do a good enough job is to get started now with the next small piece that makes sense. Just one small step at a time.",
    ],
    [
      "Failure is a normal part of the learning process. I will come up with a plan that takes that into account.",
    ],
    [
      "If I think about it, the best plan for me to do a good job is to ACTUALLY START!",
    ],
    [
      "I know that rejection can be painful, but I will remember that it's not a reflection of my worth as a person. I will work on building my resilience and self-confidence.",
    ],
    [
      "I will remember that I have the skills and knowledge to handle this task well enough. I will break it down into smaller, manageable steps.",
    ],
    [
      "I will remember that I have overcome difficult challenges in the past. I will draw on those experiences to help me move forward.",
    ],
  ];

  return (
    <FrameworkCompassionate
      title={props.title}
      examplesBehaviors={examplesBehaviors}
      examplesUnderstanding={examplesUnderstanding}
      examplesPlan={examplesPlan}
      defaultYouBehavior={`You are avoiding to start working on "${props.stepOrTask}" because you are afraid to fail.`}
      defaultIBehavior={`I'm avoiding to start working on "${props.stepOrTask}" because I am afraid to fail.`}
      defaultYouUnderstanding={
        "That's understandable. You care very much about the outcome and want to go a good job."
      }
      defaultIUnderstanding={
        "That's understandable. I care very much about the outcome and want to go a good job."
      }
      defaultYouPlan={
        "If you think about it, the best plan for you to do a good job is..."
      }
      defaultIPlan={
        "If I think about it, the best plan for me to do a good job is..."
      }
    />
  );
}

export default SCompassionateFearFailure;
