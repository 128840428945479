import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import lowercapFirstLetter from "./utils/lowercapFirstLetter";
import AddMotivationForm from "./utils/AddMotivationForm";

function SMotivationSharedGoals(props) {
  const [nameInput, setNameInput] = useState("");
  const [goalInput, setGoalInput] = useState("");

  const onChangeNameHandler = (event) => {
    setNameInput(event.target.value);
  };
  const onChangeGoalHandler = (event) => {
    setGoalInput(event.target.value);
  };

  const examplesLovedOnes = [
    ["family", "self"],
    ["daughter", "friends"],
    ["spouse", "children"],
    ["customers"],
  ];

  const examplesGoals = [
    ["Provide them with financial stability", "Take a family beach vacation"],
    ["Save for their college", "Provide a good example for her"],
    ["Quit my job and work full-time on my own business"],
    ["Pay for her violin lessons"],
    ["Retire early"],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            A big source of motivation can come from our loved ones, family and
            friends who we share a big-picture goal with.
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify loved one*/}
          <div className="boxStepSubtitle">Think of a loved one</div>
          <div className="columns">
            <div className="column is-6">
              I want to succeeed on behalf of my:
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeNameHandler}
                  value={nameInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by loved ones others mentioned:
              </div>
              <CarouselExamples examples={examplesLovedOnes} />
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Identify shared goal*/}
          <div className="boxStepSubtitle">
            Think of a goal you share with your {nameInput}
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              Succeeding on my work will enable us to:
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeGoalHandler}
                  value={goalInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by goals others shared:
              </div>
              <CarouselExamples examples={examplesGoals} />
            </div>
          </div>
        </div>,

        <div>
          {/* 3rd step: Formulate goal*/}
          <div className="boxStepSubtitle">State your motivation</div>
          <div className="columns m-2">
            <div className="column is-7">
              <div className="mb-3">
                Think of your {nameInput} and how much they mean to you.{" "}
                <b>
                  Use this emotion to ignite your motivation and fuel your work
                </b>
                .
              </div>
              <AddMotivationForm
                defaultValue={`I want to succeed on behalf of my ${nameInput}, so that we can ${lowercapFirstLetter(
                  goalInput
                )}.`}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SMotivationSharedGoals;
