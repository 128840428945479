import { Urgency, GoalRelated, InnerResistance, Level } from "../../TaskTypes";

export function getRelevantTagsForTask(task) {
  let relevantTags = [];

  relevantTags = relevantTags.concat(getUrgencyTags(task.urgency));
  relevantTags = relevantTags.concat(getGoalTags(task.goal));

  relevantTags = relevantTags.concat(getFocusTags(task.level));
  relevantTags = relevantTags.concat(getResistanceTags(task.resistance));

  return relevantTags;
}

export function getUrgencyTags(taskUrgency) {
  let tagsToReturn = [];
  const urgentTag = {
    bgcolor: "#dc143c",
    title: "Urgent",
  };

  if (taskUrgency === Urgency.Medium || taskUrgency === Urgency.High) {
    tagsToReturn.push({ ...urgentTag, key: "pushTagU1" });
  }
  if (taskUrgency === Urgency.High) {
    tagsToReturn.push({ ...urgentTag, key: "pushTagU2" });
  }

  return tagsToReturn;
}

export function getGoalTags(taskGoal) {
  let tagsToReturn = [];
  let goalTag = {
    bgcolor: "#FFA500",
    title: "Goal oriented",
    color: "#0a0817",
  };
  if (taskGoal === GoalRelated.Help || taskGoal === GoalRelated.Critical) {
    tagsToReturn.push({ ...goalTag, key: "pushTagG1" });
  }
  if (taskGoal === GoalRelated.Critical) {
    tagsToReturn.push({ ...goalTag, key: "pushTagG2" });
  }
  return tagsToReturn;
}

export function getFocusTags(taskFocus) {
  let tagsToReturn = [];
  let focusTag = {
    bgcolor: "#23C1DA",
    title: "Shallow focus",
    color: "#0a0817",
  };
  if (taskFocus === Level.Shallow) {
    tagsToReturn.push({ ...focusTag, key: "easeTagF1" });
  }
  return tagsToReturn;
}

export function getResistanceTags(taskResistance) {
  let tagsToReturn = [];
  let resistanceTag = {
    bgcolor: "#7048F4",
    title: "Less resistance",
  };
  if (
    taskResistance === InnerResistance.Not ||
    taskResistance === InnerResistance.Forward
  ) {
    tagsToReturn.push({ ...resistanceTag, key: "easeTagR1" });
  }
  if (taskResistance === InnerResistance.Forward) {
    tagsToReturn.push({ ...resistanceTag, key: "easeTagR2" });
  }
  return tagsToReturn;
}
