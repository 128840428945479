import React, { useState, useEffect } from "react";
import firebase from "../../util/firebase";
import "./TemplatesList.scss";

const firestore = firebase.firestore();

function TemplatesList(props) {
  const [loading, setLoading] = useState(true);
  const [templatesList, setTemplateList] = useState([]);

  useEffect(() => {
    const templatesFromFirebase = [];
    const subscriber = firestore
      .collection("templates")
      //.orderBy("stepIndex")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          templatesFromFirebase.push({
            ...doc.data(), //spread operator
            id: doc.id, // `id` given to us by Firebase
          });
        });
        setTemplateList(templatesFromFirebase);
        setLoading(false);
      });

    // return cleanup function
    return () => subscriber();
  }, [loading]); // only run on first render and rerun if loading variable changes
  // empty dependencies array => useEffect only called once

  return (
    <>
      <div className="TemplatesListContainer is-light2 column is-5">
        <nav className="panel">
          {templatesList.length > 0 ? (
            templatesList.map((template) => (
              //<a className="panel-block"> //do I need to make them links?
              <a
                className={`panel-block ${
                  template.id === props.selectedTemplateId
                    ? "is-active is-fontcolor-themeTertiary"
                    : ""
                }`}
                onClick={() => {
                  props.onChangeSelectedTemplate(template);
                }}
              >
                <span className="panel-icon">
                  <i className="fas fa-heart" aria-hidden="true"></i>
                </span>
                {template.title}
              </a>
            ))
          ) : (
            <div></div>
          )}
        </nav>
      </div>
    </>
  );
}

export default TemplatesList;
