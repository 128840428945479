import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import SaveThoughtForm from "./utils/SaveThoughtForm";

function SOpinion(props) {
  const { prev, next, hasNext, hasPrev } = useSteps();

  const [nameInput, setNameInput] = useState("");
  const onChangeHandler = (event) => {
    setNameInput(event.target.value);
  };

  return (
    <>
      <div className="block">
        <h1 className="title is-3">{props.title}</h1>
      </div>
      <div className="block">
        Sometimes our fear of what others may think of our intelligence or
        performance can block us from making <b>progress</b> in a task. Let's
        try this exercise to overcome it together!
      </div>
      <div className="block">
        We often feel anxiety when we <i>overstimate</i> the threat something
        represents to us and <i>underestimate</i> our ability and resources to
        ovecome it.
      </div>

      <div className={"box"}>
        <Steps>
          <div>Let's do an exercise where we put things into perspective.</div>
          <div>
            What's the name of the person whose judgment you fear? (If it's a
            whole team, taking one person at a time helps)
            <div className="control">
              <input
                className="input"
                autoComplete="off"
                autoFocus={true}
                type="text"
                onChange={onChangeHandler}
                value={nameInput}
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              {nameInput}'s opinion of me does not define me.
            </div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                placeholder="Copy the text above here and say it out loud"
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              {nameInput}'s opinion of me doesn't need to affect me at all.
            </div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                placeholder="Copy the text above here and say it out loud"
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              The world is not paying attention to me. Everyone is busy with
              their own tasks.
            </div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                placeholder="Copy the text above here and say it out loud"
              />
            </div>
          </div>
          <div>
            <div className="block">
              You can edit this thought to personalize it and save it to your
              task.
            </div>
            <SaveThoughtForm
              defaultValue={`${nameInput}'s opinion of me does not define me. ${nameInput}'s opinion of me doesn't need to affect me at all. The world is not paying attention to me. Everyone is busy with their own tasks.`}
            />
          </div>
        </Steps>
        <div>
          <div className={"p-3 is-flex is-justify-content-end"}>
            <button
              className="button mx-1 is-light"
              onClick={prev}
              disabled={!hasPrev}
            >
              <i className="fa fa-chevron-left mx-2"></i>
              Prev
            </button>
            <button
              className="button mx-1 is-light"
              onClick={next}
              disabled={!hasNext}
            >
              Next
              <i className="fa fa-chevron-right mx-2"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SOpinion;
