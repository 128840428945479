import React from "react";
import TaskPage from "./task";
import TasksPage from "./tasks";
//import ProcessFeelingPage from "../components/Process/ProcessFeelingPage";
import OnboardingPage from "../pages/onboarding";
import HowItWorks from "./HowItWorks";

import { Switch, Route, Router } from "./../util/router.js";

import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";

function MainApp(props) {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/app/tasks" component={TasksPage} />
          <Route exact path="/app/task/:id" component={TaskPage} />
          {/* <Route
                exact
                path="/app/feelings"
                component={ProcessFeelingPage}
              /> */}
          <Route exact path="/app/onboarding" component={OnboardingPage} />
          <Route exact path="/app/how-it-works" component={HowItWorks} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default MainApp;
