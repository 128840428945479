import React, { useState } from "react";
import FormAlert from "../FormAlert";
import FormField from "../FormField";
import { useAuth } from "../../util/auth.js";
import { useForm } from "react-hook-form";
import { createProject } from "../../util/db.js";
import "./EditProjectModal.scss";

function EditProjectModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();

  // // This will fetch project if props.id is defined
  // // Otherwise query does nothing and we assume
  // // we are creating a new task.
  // const { data: projectData, status: projectStatus } = useProject(
  //   auth.user.uid,
  //   props.id
  // );

  // // If we are updating an existing project
  // // don't show modal until project data is fetched.
  // if (props.id && projectStatus !== "success") {
  //   return null;
  // }

  const onSubmit = (data) => {
    setPending(true);

    const query =
      // props.id? updateTask(auth.user.uid, props.id, data):
      createProject(auth.user.uid, data);

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="EditProjectModal__card card">
        <header className="card-header">
          <p className="card-header-title">
            {props.id && <>Update</>}
            {!props.id && <>Create</>}
            {` `}Workspace
          </p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <div className="card-content">
          {formAlert && (
            <FormAlert type={formAlert.type} message={formAlert.message} />
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Workspace Title</label>
              </div>
              <div className="field-body">
                <FormField
                  name="title"
                  type="input"
                  //placeholder="Project title"
                  //defaultValue={projectData && projectData.name}
                  size="medium"
                  error={errors.name}
                  autoFocus={true}
                  inputRef={register({
                    required: "Please enter a workspace title",
                  })}
                />
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Workspace Goal</label>
              </div>
              <div className="field-body">
                <FormField
                  name="goal"
                  type="textarea"
                  placeholder="Why do I want to do this?"
                  //defaultValue={projectData && projectData.name}
                  size="medium"
                  error={errors.name}
                  autoFocus={false}
                  inputRef={register({
                    required: "Please enter a workspace goal",
                  })}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button
                  className={
                    "button is-medium is-primary" +
                    (pending ? " is-loading" : "")
                  }
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditProjectModal;
