import React, { useState, useEffect } from "react";
import firebase from "../../../util/firebase";
import { Steps, Step, StepsProvider, useSteps } from "react-step-builder";
import FormAlert from "../../FormAlert";
import FormField from "../../FormField";
import { useAuth } from "../../../util/auth.js";
import { useForm } from "react-hook-form";
import { createThought } from "../../../util/db.js";
import { useParams } from "react-router-dom";
import { useTask } from "../../../util/db.js";
import { v4 as uuid } from "uuid";

const firestore = firebase.firestore();

function SFirstStep(props) {
  const auth = useAuth();
  const { prev, next, hasNext, hasPrev } = useSteps();

  const [taskInput, setTaskInput] = useState("");
  const [thoughtsInput, setThoughtsInput] = useState("");
  const [feelingsInput, setFeelingsInput] = useState("");
  const [accurateInput, setAccurateInput] = useState("");
  const [positiveInput, setPositiveInput] = useState("");
  const [stepInput, setStepInput] = useState("");

  const onTaskChange = (event) => {
    setTaskInput(event.target.value);
  };

  const onThoughtsChange = (event) => {
    setThoughtsInput(event.target.value);
  };

  const onFeelingsChange = (event) => {
    setFeelingsInput(event.target.value);
  };

  const onAccurateChange = (event) => {
    setAccurateInput(event.target.value);
  };

  const onPositiveChange = (event) => {
    setPositiveInput(event.target.value);
  };

  const onStepChange = (event) => {
    setStepInput(event.target.value);
  };

  // /// // TODO: Refactor an use SaveThoughtForm instead of all this
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { register, handleSubmit, errors } = useForm();

  // Getting Task Id from params
  const { id } = useParams();

  // Handle creating new thought
  const onSubmit = (data) => {
    setPending(true);

    const query = createThought(auth.user.uid, { taskIds: [id], ...data });

    query
      .then(() => {
        // Let parent know we're done
        setPending(false);
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  // Handle creating new step

  const { data: taskData, status: taskStatus } = useTask(auth.user.uid, id);
  // stepsTree might be undefined at this step with status loading
  if (id && taskStatus !== "success") {
    return null;
  }
  const onAddStep = () => {
    const newStep = {
      checked: false,
      content: stepInput,
      id: uuid(),
    };
    let newStepsData = taskData.stepsData.concat(newStep);
    firestore
      .collection("users")
      .doc(auth.user.uid)
      .collection("tasks")
      .doc(id)
      .update({ stepsData: newStepsData });
  };
  return (
    <>
      <div className="block">
        <h1 className="title is-3">{props.title}</h1>
      </div>
      <div className="block">
        We Procrastinate to avoid feeling bad. Sometimes all we need is a step
        in the right direction to start feeling good about taking action.
      </div>

      <div className={"box"}>
        <Steps>
          <div>
            Let's take a step by step approach where we deal with
            Procrastination.
          </div>
          <div className="columns">
            <div className="column is-6">
              <div className="title is-5">
                What's the specific task that you are putting off?
              </div>
              <div className="control">
                <input
                  className="input"
                  autoComplete="off"
                  autoFocus={true}
                  type="text"
                  onChange={onTaskChange}
                  value={taskInput}
                />
              </div>
            </div>
            <div className="column is-6 is-light2">
              <div className="title is-5 is-fontcolor-dark1">
                Get inspired by example answers from other people in your
                industry:
              </div>
              <div className="block content">
                <ul>
                  <li>"Continue implementing X feature"</li>
                  <li>"Sending Y out for code review"</li>
                  <li>"Asking for help in figuring out bug Z"</li>
                  <li>"Start working on assigned ticket"</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="title is-5">
              Write down what are your negative thoughts about "{taskInput}":
            </div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                placeholder="It's the most boring task in the world."
                onChange={onThoughtsChange}
                value={thoughtsInput}
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              Let's put a label on the feelings you have towards "{taskInput}".
            </div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                placeholder="Fear, anger"
                onChange={onFeelingsChange}
                value={feelingsInput}
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              Examine your thoughts. Are you <i>magnifying</i> some of the
              negative aspects about the task?
            </div>
            <div className="block">Edit your thoughts to be more accurate.</div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                value={accurateInput ? accurateInput : thoughtsInput}
                onChange={onAccurateChange}
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              How will it feel to get {taskInput} done? How is it of value to
              you? Are you <i>minimizing</i> some of the positive aspects?
            </div>
            It will make me feel...
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                value={positiveInput}
                onChange={onPositiveChange}
              />
            </div>
          </div>
          <div>
            <div className="title is-5">
              What is a first tiny actionable step that you can take towards{" "}
              {taskInput}?
            </div>
            <div className="control">
              <textarea
                className="textarea"
                type="text"
                value={stepInput}
                onChange={onStepChange}
              />
            </div>
            <div className="control">
              <button
                className={"button is-small is-primary"}
                onClick={onAddStep}
              >
                Add as Step
              </button>
            </div>
          </div>
          <div>
            <div className="block">
              You can edit this thought to personalize it and save it to your
              task.
            </div>
            {formAlert && (
              <FormAlert type={formAlert.type} message={formAlert.message} />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormField
                name="name"
                type="textarea"
                defaultValue={`Even though I might feel ${feelingsInput} towards ${taskInput}, I will invest a moment of discomfort to take the first step of ${stepInput}. It will make me feel ${positiveInput}`}
                size="normal"
                error={errors.thought}
                autoFocus={true}
                inputRef={register({
                  required: "Please write a thought to save",
                })}
              />

              <div className="field">
                <div className="control">
                  <button
                    className={
                      "button is-small is-primary" +
                      (pending ? " is-loading" : "")
                    }
                    type="submit"
                  >
                    Save Thought
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Steps>
        <div>
          <div className={"p-3 is-flex is-justify-content-start"}>
            <button
              className="button mx-1 is-light"
              onClick={prev}
              disabled={!hasPrev}
            >
              <i className="fa fa-chevron-left mx-2"></i>
              Prev
            </button>
            <button
              className="button mx-1 is-light"
              onClick={next}
              disabled={!hasNext}
            >
              Next
              <i className="fa fa-chevron-right mx-2"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="block">
        Read more about similar strategies:{" "}
        <i>The Adult ADHD Toolkit, J. Ramsell & A. Rostain</i>
      </div>
    </>
  );
}

export default SFirstStep;
