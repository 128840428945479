import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CardForm,
  CardHeader,
  CardRightContent,
  CardTitle,
  CardWrapper,
  Footer,
} from "./styles/Base";
import EditableLabel from "./widgets/EditableLabel";
import Tag from "./Tag";
import { Urgency, GoalRelated, InnerResistance, Level } from "../TaskTypes";
import {
  getFocusTags,
  getGoalTags,
  getResistanceTags,
  getUrgencyTags,
} from "./utils/getTaskTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlarmExclamation,
  faTrophy,
  faFaceSmile,
  faWater,
  faDash,
} from "@fortawesome/pro-solid-svg-icons";

//options (move to file?)
const urgencyOptions = [
  {
    label: "This task is not urgent",
    value: Urgency.Not,
  },
  {
    label: "This task is medium urgent",
    value: Urgency.Medium,
  },
  {
    label: "This task is highly urgent",
    value: Urgency.High,
  },
];

const goalOptions = [
  {
    label: "Not related to my goal",
    value: GoalRelated.Not,
  },
  {
    label: "Helps me accomplish my goal",
    value: GoalRelated.Help,
  },
  {
    label: "Critical to reach my goal",
    value: GoalRelated.Critical,
  },
];

const levelOptions = [
  {
    label: "Requires deep focus",
    value: Level.Deep,
  },
  {
    label: "Does not require deep focus",
    value: Level.Shallow,
  },
];

const resistanceOptions = [
  {
    label: "I feel a big resistance to get started",
    value: InnerResistance.Big,
  },
  {
    label: "I feel some resistance to get started",
    value: InnerResistance.Some,
  },
  {
    label: "I don't feel resistance to get started",
    value: InnerResistance.Not,
  },
  {
    label: "I look forward to get started",
    value: InnerResistance.Forward,
  },
];

function TaskCardForm(props) {
  const [title, setTitle] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  // Initial state with first field in each drop down
  const [fieldsState, setFieldsState] = useState({
    title: "",
    urgency: urgencyOptions[0].value,
    goal: goalOptions[0].value,
    level: levelOptions[0].value,
    resistance: resistanceOptions[0].value,
  });

  const updateField = (field, value) => {
    if (field === "title") {
      setTitle(value);
    }
    setFieldsState({ ...fieldsState, [field]: value });
  };

  const handleAdd = () => {
    setIsAdding(true); // for local state only
    // add tags to fields from form
    props.onAdd({ ...fieldsState, tags: selectedTags });
    setTitle("");
    setIsAdding(false);
  };

  const handleBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    if (title && !isAdding) {
      handleAdd();
    }
    // if no title, do nothing and cancel, close form
    if (!title) {
      props.onCancel();
    }
  };

  // useState for properties
  const [urgencySelected, setUrgencySelected] = useState(null);
  const [goalSelected, setGoalSelected] = useState(null);
  const [resistanceSelected, setResistanceSelected] = useState(null);
  const [levelSelected, setLevelSelected] = useState(null);

  const [selectedTags, setSelectedTags] = useState([]);

  const { onCancel, t } = props;

  useEffect(() => {
    const newSelectedTags = [];
    if (urgencySelected) {
      newSelectedTags.push(...getUrgencyTags(urgencySelected));
    }
    if (goalSelected) {
      newSelectedTags.push(...getGoalTags(goalSelected));
    }
    if (levelSelected) {
      newSelectedTags.push(...getFocusTags(levelSelected));
    }
    if (resistanceSelected) {
      newSelectedTags.push(...getResistanceTags(resistanceSelected));
    }
    setSelectedTags(newSelectedTags);
  }, [urgencySelected, goalSelected, resistanceSelected, levelSelected]);

  return (
    <CardForm onBlur={handleBlur}>
      <CardWrapper>
        <CardHeader>
          <CardTitle>
            <EditableLabel
              placeholder={"task title"}
              onChange={(event) => updateField("title", event.target.value)}
              autoFocus
              onTitleEnter={handleAdd}
            />
          </CardTitle>
        </CardHeader>
        <div>
          <div>
            <div className="is-flex is-align-items-center alarm-exclamation">
              <FontAwesomeIcon
                icon={faAlarmExclamation}
                size="s"
                className="mr-1 mb-1"
              />
              <select
                className="select is-small mb-1"
                type={"select"}
                onChange={(e) => {
                  setUrgencySelected(e.target.value);
                  updateField("urgency", e.target.value);
                }}
              >
                {urgencyOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="is-flex is-align-items-center trophy">
              <FontAwesomeIcon icon={faTrophy} size="s" className="mr-1 mb-1" />
              <select
                className="select is-small mb-1"
                type={"select"}
                onChange={(e) => {
                  setGoalSelected(e.target.value);
                  updateField("goal", e.target.value);
                }}
              >
                {goalOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="is-flex is-align-items-center water">
              <FontAwesomeIcon icon={faWater} size="s" className="mr-1 mb-1" />
              <select
                className="select is-small mb-1"
                type={"select"}
                onChange={(e) => {
                  setLevelSelected(e.target.value);
                  updateField("level", e.target.value);
                }}
              >
                {levelOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="is-flex is-align-items-center face-smile">
              <FontAwesomeIcon
                icon={faFaceSmile}
                size="s"
                className="mr-1 mb-1"
              />
              <select
                className="select is-small mb-1"
                type={"select"}
                onChange={(e) => {
                  setResistanceSelected(e.target.value);
                  updateField("resistance", e.target.value);
                }}
              >
                {resistanceOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <Footer>
          {selectedTags &&
            selectedTags?.map((tag) => (
              <Tag {...tag} /*tagStyle={tagStyle}*/ />
            ))}
        </Footer>
      </CardWrapper>
      <div className="button is-small" onClick={handleAdd}>
        Add
      </div>
      <div className="button mx-1 is-small" onClick={onCancel}>
        Cancel
      </div>
    </CardForm>
  );
}

TaskCardForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TaskCardForm.defaultProps = {};

export default TaskCardForm;
