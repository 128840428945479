// Map our custom plan IDs ("month", "quarter", etc) to Stripe price IDs
const stripePriceIds = {
  month: process.env.REACT_APP_STRIPE_PRICE_MONTH,
  //quarter: process.env.REACT_APP_STRIPE_PRICE_QUARTER,
  //year: process.env.REACT_APP_STRIPE_PRICE_YEAR,
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("month", "quarter", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}
