import { useState, useEffect } from "react";
import "./StrategyBrowser.scss";
import StrategyHeartButton from "./StrategyHeartButton.js";
import strategiesList from "./strategiesListData";

function StrategyBrowser(props) {
  const [strategiesToDisplay, setStrategiesToDisplay] = useState([]);

  useEffect(() => {
    // get strategies for selected feeling
    const strategiesFromData = [];
    props.selectedFeelingStrategies?.forEach((strategyId) => {
      strategiesFromData.push(strategiesList[strategyId]);
    });
    setStrategiesToDisplay(strategiesFromData);
  }, [props.selectedFeelingStrategies]);
  return (
    <>
      <div className="StrategyBrowserContainer column is-7">
        <div className="block m-4 title">{props.selectedFeelingTitle}</div>
        <div className="block m-4">
          The strategies below are a compilation of what has worked best for
          people in your industry.
        </div>

        <div className="block ml-4">
          <b>Select a strategy to begin:</b>
        </div>
        <div className="is-flex is-flex-direction-column mb-3">
          {strategiesToDisplay.length > 0 ? (
            strategiesToDisplay.map((strategy) => (
              <div
                key={strategy?.id}
                className="card strategyCard my-1 mx-3"
                onClick={() => {
                  props.onChangeSelectedStrategy(strategy);
                }}
              >
                <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                  <div className="p-4">{strategy?.title}</div>
                  <div>
                    <StrategyHeartButton strategyId={strategy?.id} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export default StrategyBrowser;
