import React, { useState, useEffect } from "react";
// import firebase from "../../util/firebase";
import "./FeelingsList.scss";
import feelingsList from "./feelingsListData"; // feelingsList now hardcoded instead of DB
//const firestore = firebase.firestore();

function FeelingsList(props) {
  // // // COMMENTED OUT CODE BELOW TAKES FEELINGS FROM FIREBASE, BUT HARDCODING THEM IN JSON FOR NOW
  // const [loading, setLoading] = useState(true);
  // const [feelingsList, setFeelingList] = useState([]);

  // useEffect(() => {
  //   const feelingsFromFirebase = [];
  //   const subscriber = firestore
  //     .collection("feelings")
  //     //.orderBy("stepIndex")
  //     .onSnapshot((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         feelingsFromFirebase.push({
  //           ...doc.data(), //spread operator
  //           id: doc.id, // `id` given to us by Firebase
  //         });
  //       });
  //       setFeelingList(feelingsFromFirebase);
  //       setLoading(false);
  //     });

  //   // return cleanup function
  //   return () => subscriber();
  // }, [loading]); // only run on first render and rerun if loading variable changes
  // // empty dependencies array => useEffect only called once
  // // // END COMMENT OUT

  return (
    <>
      <div className="FeelingsListContainer is-light2 column is-5">
        <nav className="panel">
          {feelingsList.length > 0 ? (
            feelingsList.map((feeling) => (
              //<a className="panel-block"> //do I need to make them links?
              <a
                className={`panel-block ${
                  feeling.id === props.selectedFeelingId
                    ? "is-active is-fontcolor-themeTertiary"
                    : ""
                }`}
                onClick={() => {
                  props.onChangeSelectedFeeling(feeling);
                }}
              >
                {feeling.title}
              </a>
            ))
          ) : (
            <div></div>
          )}
        </nav>
      </div>
    </>
  );
}

export default FeelingsList;
