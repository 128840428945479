import "./onboarding.scss";
import { updateUser } from "../../util/db";
import { useRouter } from "./../../util/router.js";
import { useAuth } from "./../../util/auth.js";

function StepThree(props) {
  const router = useRouter();
  const auth = useAuth();

  const onStart = (data) => {
    console.log("start tour!");
    // set user to onboarded and
    //redirect to tasks? next touring flow
    updateUser(auth.user.uid, { onboarded: true }).then(
      router.replace("/app/tasks?tour=true")
    );
  };

  return (
    <>
      <div className="is-size-3 has-text-centered has-text-weight-semibold">
        Awesome.
      </div>
      <div className="is-size-3 has-text-centered has-text-weight-semibold">
        Let's get started!
      </div>
      <div className="mt-4 field is-flex is-justify-content-center">
        <div className="control">
          <button className={"button is-success mx-1 my-1"} onClick={onStart}>
            Start
          </button>
        </div>
      </div>
    </>
  );
}

export default StepThree;
