import { useEffect, useState } from "react";
//import { requireAuthAndSubscription } from "./../../util/auth.js";
import { requireAuth } from "./../../util/auth.js";
import FeelingsList from "./FeelingsList.js";
import StrategyBrowser from "./StrategyBrowser.js";
import StrategyPane from "./StrategyPane.js";
import { StepsProvider } from "react-step-builder";
import "./ProcessFeelingPage.scss";
import IndustryCategory from "../../pages/IndustryCategory.js";
import {
  updateLastSelectedByOwner,
  useLastSelectedByOwner,
} from "../../util/db.js";
import { useAuth } from "../../util/auth.js";
import { Mixpanel } from "../../util/mixpanel";

function ProcessFeelingPage(props) {
  const auth = useAuth();

  const [selectedFeeling, setSelectedFeeling] = useState(
    props.preselectedFeeling ? props.preselectedFeeling : undefined
  );
  const [selectedStrategy, setSelectedStrategy] = useState(
    props.preselectedStrategy ? props.preselectedStrategy : undefined
  );
  // const [dismissWarning, setDismissWarning] = useState(false);
  // const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false);
  // const [hideDisclaimer, setHideDisclaimer] = useState(true);

  // //get settings to show/hide disclaimer
  // const { data: lastSelected } = useLastSelectedByOwner(auth.user.uid);

  // let isHideDisclaimerNotSet =
  //   (lastSelected && lastSelected.hideDisclaimer) === undefined;

  // Hook to render disclaimer  once data is loaded
  // useEffect(() => {
  //   setHideDisclaimer(!isHideDisclaimerNotSet || lastSelected?.hideDisclaimer);
  // }, [lastSelected]);

  // Hook to run only once
  useEffect(() => {
    props.handlePreselectFeeling(null); //clearing out so legend always shows next
    props.handlePreselectStrategy(null); //clearing out
    Mixpanel.track("ProcessFeelingPage loaded");
  }, []);

  function changeSelectedFeeling(feeling) {
    setSelectedFeeling(feeling);
    Mixpanel.track("Feeling selected", {
      feeling: feeling.title,
    });
  }

  function changeSelectedStrategy(strategy) {
    setSelectedStrategy(strategy);
  }

  function onBackToStrategies() {
    setSelectedStrategy();
  }

  // function onDismiss() {
  //   setDismissWarning(true);
  //   if (isDontShowAgainChecked) {
  //     //save to seetings
  //     updateLastSelectedByOwner(auth.user.uid, {
  //       hideDisclaimer: true,
  //     });
  //   }
  // }

  return (
    <>
      {!selectedStrategy && (
        <>
          <div className="mt-2">
            {/* <IndustryCategory
              taskid={props.taskId}
              taskCategory={props.taskCategory}
            /> */}
            <div>
              <div className="is-superLightPurple p-2 m-1 has-text-centered">
                {/* <strong>Judgment-Free Zone:</strong> We recognize our feelings
                and accept them without judging ourselves for experiencing them. */}
                {/* <strong>Judgment-Free Zone:</strong> Let's acknowledge and
                accept all of our feelings without judgment. */}
                <i>
                  When a task moves us closer to our dreams or what we really
                  want, we tend to feel a stronger sense of resistance towards
                  taking action. <br />
                  By accepting and embracing our feelings without self-judgment
                  or shame, we can work through them and overcome our inner
                  resistance.
                </i>
              </div>
              {/* {!hideDisclaimer && !dismissWarning && (
                <div className="notification is-link m-3">
                  <button
                    className="delete"
                    onClick={() => onDismiss()}
                  ></button>
                  <p className="mt-3">
                    <strong>Disclaimer:</strong> This content is not intended to
                    be a substitute for professional advice, diagnosis, or
                    treatment. Always seek the advice of your mental health
                    professional with any questions you may have.
                  </p>
                  <div className="my-2 mx-5 is-flex is-justify-content-end">
                    <label className="checkbox">
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={isDontShowAgainChecked}
                        onClick={() =>
                          setIsDontShowAgainChecked(!isDontShowAgainChecked)
                        }
                      />
                      {" Don't show this again"}
                    </label>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className="scrollable columns is-gapless feelingsListAndStrategyBrowser">
            <FeelingsList
              selectedFeelingId={selectedFeeling?.id}
              onChangeSelectedFeeling={changeSelectedFeeling}
            />

            {selectedFeeling && (
              <StrategyBrowser
                selectedFeelingId={selectedFeeling?.id}
                selectedFeelingTitle={selectedFeeling?.title}
                selectedFeelingStrategies={selectedFeeling?.strats}
                selectedStrategyId={selectedStrategy?.id}
                onChangeSelectedStrategy={changeSelectedStrategy}
              />
            )}

            {!selectedFeeling && (
              <div className="column">
                <div className="subtitle m-5 p-5 has-text-centered">
                  <div className="my-2">
                    When you think of starting to
                    <div className="my-1">
                      <i>"{props.stepOrTask}"</i>,
                    </div>
                    <span className="has-text-success">
                      what is the thought that
                      <br />
                      <span>best reflects your current feeling</span>
                    </span>
                    ?
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {selectedStrategy && (
        <div className="scrollable lightPurpleContainer">
          <StepsProvider>
            <StrategyPane
              selectedStrategy={selectedStrategy}
              onBackToStrategies={onBackToStrategies}
              stepOrTask={props.stepOrTask}
            />
          </StepsProvider>
        </div>
      )}
    </>
  );
}

export default requireAuth(ProcessFeelingPage);
