import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faThumbTack } from "@fortawesome/pro-solid-svg-icons";
import { Mixpanel } from "../../util/mixpanel";

function Thought(props) {
  return (
    <div
      className={
        "panel-block p-3 is-flex is-justify-content-space-between" +
        (props.thought.featured ? " featured" : "") //featured add??
      }
      key={props.index}
    >
      <Interweave
        content={props.thought.content}
        matchers={[new UrlMatcher("url")]}
        newWindow={true}
      />
      <div className="is-flex is-align-items-center">
        <button
          className="button action is-ghost p-1"
          onClick={() => {
            Mixpanel.track("Focus thought clicked");
            props.handleFocusThought(props.thought.content);
          }}
        >
          Focus
        </button>
        <button
          className={"button action is-ghost"}
          aria-label="star"
          onClick={() => props.handlePinThought(props.thought, props.index)}
        >
          <FontAwesomeIcon
            icon={faThumbTack}
            size="sm"
            className={props.isPinned ? "is-primary" : ""}
          />
        </button>
        <button
          className="button action is-ghost p-1"
          aria-label="update"
          onClick={() =>
            props.handleUpdatingThought(props.index, props.isPinned)
          }
        >
          <FontAwesomeIcon icon={faEdit} size="sm" className="" />
        </button>
        <button
          className={"button action is-ghost p-1"}
          aria-label="delete"
          onClick={() => props.handleDeleteThought(props.index, props.isPinned)}
        >
          <FontAwesomeIcon icon={faTrash} size="sm" className="" />
        </button>
      </div>
    </div>
  );
}

export default Thought;
