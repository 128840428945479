import React, { useState } from "react";
import FormAlert from "../FormAlert";
import EditAffirmationModal from "./EditAffirmationModal";
import { useAuth } from "../../util/auth.js";
import { updateAffirmation, useAffirmationForTask } from "../../util/db.js";
import "./CardAffirmations.scss";
import Collapse from "../Collapse";

function CardTaskAffirmation(props) {
  const auth = useAuth();

  // const {
  //   data: affirmation,
  //   status: affirmationStatus,
  //   error: affirmationError,
  // } = useAffirmationForTask(props.affirmationId);

  const affirmation = props.taskData?.affirmation;

  const [creatingAffirmation, setCreatingAffirmation] = useState(false);

  const affirmationAreEmpty = !affirmation;

  return (
    <>
      <Collapse
        title="Vision"
        subtitle="Can you imagine what success looks like for this task?"
      >
        <div>
          {affirmationAreEmpty && (
            <div className="py-3 px-3">
              <div>Nothing yet.</div>
              <button
                className="button is-outlined is-primary m-3"
                onClick={() => setCreatingAffirmation(true)}
              >
                Transform your worries
              </button>
            </div>
          )}

          {affirmation && (
            <>
              <div
                className={
                  "is-flex is-justify-content-space-between" +
                  (affirmation.featured ? " featured" : "")
                }
              >
                {affirmation.name}
                <div>
                  <button
                    className="button action is-ghost"
                    aria-label="update"
                    onClick={() => setCreatingAffirmation(true)}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-edit" />
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Collapse>

      {creatingAffirmation && (
        <EditAffirmationModal
          onDone={() => setCreatingAffirmation(false)}
          task={props.taskData}
          affirmation={affirmation}
        />
      )}

      {/* {updatingAffirmationId && (
        <EditAffirmationModal
          id={updatingAffirmationId}
          onDone={() => setUpdatingAffirmationId(null)}
        />
      )} */}
    </>
  );
}

export default CardTaskAffirmation;
