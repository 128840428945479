import FrameworkFlipIt from "./FrameworkFlipIt";

function SFlipItAnxious(props) {
  const examplesFlips = [
    ["Once I start working on the design doc, I will feel less anxious."],
    ["Once I start working on building the payments infra, I will know more."],
    [
      "Once I start working on sending an email to ask for help, I will feel braver.",
    ],
    ["Once I start working on rewriting the tests, I will feel less bored."],
  ];

  const conditions = [
    "feel more confident",
    "feel braver",
    "know more",
    "feel more interested",
    "feel less anxious",
    "have more energy",
    "feel ready",
    "feel less tired",
  ];

  //const behavior = "start working on it";

  const initialDesiredCondition = "feel less anxious";

  return (
    <FrameworkFlipIt
      title={props.title}
      examplesFlips={examplesFlips}
      excuses={conditions}
      //   behavior={behavior}
      initialDesiredCondition={initialDesiredCondition}
      encourage={
        "How often does making some progress in a task, however minimal, reduce your anxiety about it?"
      }
    />
  );
}

export default SFlipItAnxious;
