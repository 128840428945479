import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm";

function SDistinguishCatastropheNoncritical(props) {
  const [attemptInput, setAttemptInput] = useState("");
  const [outcomeInput, setOutcomeInput] = useState("");
  const onChangeAttemptHandler = (event) => {
    setAttemptInput(event.target.value);
  };
  const onChangeOutcomeHandler = (event) => {
    setOutcomeInput(event.target.value);
  };

  const examplesAttempts = [
    ["Finish the project within the deadline"],
    ["Write an email asking for help"],
    ["Send out a code review"],
    ["Give a presentation about our new framework"],
    ["Rollout the feature by the end of the month"],
  ];

  const examplesOutcomes = [
    ["I might not finish on time"],
    ["They might ignore me"],
    ["My review may have lots of comments"],
    ["People might not think the presentation is great"],
    ["I might fall short of the goal"],
    ["My prototype might be nonimpressive"],
  ];

  const examplesBalanced = [
    [
      "I am trying to get one paying customer by Friday. If it turns out that I don't get any sales, that would not be ideal, but it is not the end of the world. I can just keep iterating and trying until I get there.",
    ],
    [
      "If I send out the code review and get multiple comments, it is not a big deal. I can learn from them and improve.",
    ],
    [
      "If I write an email asking for help and they ignore me, that would be upsetting but tolerable. I can just ask again during standup.",
    ],
    [
      "Even if I make a mistake during my presentation, it does not define my competence. I can just acknowledge it and carry on with the rest of the presentation.",
    ],
    [
      "I am attempting to finish the project within the deadline. If it turns out that I don't finish on time, it wouldn't be great. but it's not the end of the world. I can just tell my manager what were the setbacks and what is the new expected deadline.",
    ],
    [
      "If I give the presentation and people are not impressed, it would suck but it's not a big deal.",
    ],
    [
      "Although my boss may be disappointed if I don't finish this project on time, it is not the end of the world.",
    ],
    [
      "While it would be disappointing not to get into this program, there are other options available to me.",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            Transform catastrophic thinking about failure into a more realistic
            and balanced thought.
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify the attempted thing*/}
          <div className="boxStepSubtitle">
            Identify what you are attempting to do
          </div>
          <div className="columns">
            <div className="column is-6">
              What is one specific thing you are attempting to do that you are
              afraid to fail at?
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  placeholder="One specific thing I want to do is..."
                  onChange={onChangeAttemptHandler}
                  value={attemptInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by examples from others in your industry:
              </div>
              <CarouselExamples examples={examplesAttempts} />
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Identify the outcome feared*/}
          <div className="boxStepSubtitle">Identify the outcome you fear</div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              When you think of "<i>{attemptInput}</i>",{" "}
              <b>how is your anxious mind telling you that you might fail?</b>{" "}
              Try to be realistic and don't let your anxiety blow it out of
              proportion or exaggerate the potential negative consequences:
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeOutcomeHandler}
                  value={outcomeInput}
                  placeholder={"I fear that..."}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by examples from others in your industry:
              </div>
              <CarouselExamples examples={examplesOutcomes} />
            </div>
          </div>
        </div>,

        <div>
          {/* 3rd step: balanced thinking*/}
          <div className="boxStepSubtitle">
            Generate a more balanced thought
          </div>
          <div className="columns">
            <div className="column is-6">
              <div className="m-3">
                Your anxiety is telling you failing at this task would be
                catastrophic, but more often thant not,{" "}
                <b>
                  failures are noncritical and can even be used as opportunities
                  for learning and growth.
                </b>
              </div>
              <div className="m-3">
                Can you come up with a more <b>realistic</b> and <b>balanced</b>{" "}
                thought about what would happen if you fail?
              </div>
              <div className="m-3">
                <SaveThoughtForm
                  defaultValue={`I am attempting to ${attemptInput}. If it turns out that ${outcomeInput}, that would not be ideal, but it is not the end of the world. I can just...`}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by what others in your industry
              </div>
              <CarouselExamples examples={examplesBalanced} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SDistinguishCatastropheNoncritical;
