import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";

function HowItWorksPage(props) {
  const auth = useAuth();

  return (
    <div class="container p-5">
      {/* {auth.user && auth.user.planIsActive && ( */}
      {auth.user && (
        <button className="button is-success">
          <Link to="/app/tasks?tour=true" className="has-text-light">
            Start guide
          </Link>
        </button>
      )}
    </div>
  );
}

export default HowItWorksPage;
