import StrategyComponents from "./StrategyComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

function StrategyPane(props) {
  return (
    <>
      <div className="StrategyPaneContainer">
        <button
          className="button is-dark is-outlined m-3"
          onClick={() => {
            props.onBackToStrategies();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="s" className="mr-2" />
          Back to Strategies
        </button>
        <div className="pl-3 pr-4">
          {
            <StrategyComponents
              strategy={props.selectedStrategy}
              stepOrTask={props.stepOrTask}
            />
          }
        </div>
      </div>
    </>
  );
}

export default StrategyPane;
