import Section from "../components/Section";
import "./LaPa02.scss";
import processS1 from "../resources/processS1.png"; //hero
import processS2 from "../resources/processS2.png"; //hero
import WorkPush3 from "../resources/WorkPush3.mp4";
import stepbystep2 from "../resources/stepbystep2.mp4";
import identify from "../resources/identify.mp4";
import whatYouKnow from "../resources/whatYouKnow.mp4";
import celebrate from "../resources/celebrate.mp4";
import underlineB from "../resources/logos/underlineB.svg";
import { useState, useEffect, useRef } from "react";
//import Newsletter from "../components/Newsletter";
import SectionHeader from "../components/SectionHeader";
import { Mixpanel } from "../util/mixpanel";
import GetStarted from "../components/GetStarted";
import { useAuth } from "./../util/auth.js";
import { Link } from "./../util/router.js";

function LaPa02() {
  useEffect(() => {
    Mixpanel.track("LaPa02 loaded");
  }, []); //empty dep to run only once

  const auth = useAuth();

  const [subscribed, setSubscribed] = useState(false); // to let all Newsletters know if we already subscribed

  function handleSetSubscribed(value) {
    setSubscribed(value);
  }
  const [isSwitch, setIsSwitch] = useState(false);

  // Hook to sitch on timer
  useEffect(() => {
    setTimeout(() => {
      setIsSwitch(!isSwitch);
    }, 2000);
  }, [isSwitch]);

  return (
    <>
      <Section
        color={"white"}
        size={"large"}
        backgroundImage={""}
        backgroundImageOpacity={""}
      >
        <div className="topContainer">
          <div className="columns is-vcentered is-desktop">
            <div className="column is-5-desktop has-text-centered-touch">
              <header className={""}>
                <h1
                  className={
                    "title has-text-weight-bold is-1 is-size-2-mobile is-spaced"
                  }
                >
                  <span>
                    The only productivity app that understands how you feel.
                  </span>
                  <img
                    src={underlineB}
                    alt="Underline"
                    className="underlining"
                  />
                </h1>

                <div className={"SectionHeader__subtitle subtitle is-4 mb-4"}>
                  Do you suffer imposter syndrome, anxiety and self-doubt at
                  school or work?
                  <br />
                  <div className="mt-2">
                    <strong>We got you.</strong>
                  </div>
                </div>
              </header>
              <div className="mx-5">
                {!auth.user && (
                  <GetStarted
                    buttonText="Get Free Beta Access"
                    buttonColor="danger"
                    buttonInverted={false}
                    inputPlaceholder="Enter your email"
                    handleSetSubscribed={handleSetSubscribed}
                    subscribed={subscribed}
                    source="LaPa02 top"
                  />
                )}
                {/* {auth.user && !auth.user.planIsActive && (
                  <Link to="/pricing" className="button is-danger">
                    Activate my account
                  </Link>
                )} */}

                {/* {auth.user && auth.user.planIsActive && ( */}
                {auth.user && (
                  <Link to="/app/tasks" className="button is-info">
                    Go to my Tasks
                  </Link>
                )}
              </div>
            </div>
            <div className="column m-0 p-0">
              <div className="imageCarousel">
                {isSwitch && (
                  <img
                    src={processS2}
                    alt="Illustration"
                    className="boxShot bottom"
                  />
                )}
                {!isSwitch && (
                  <img
                    src={processS1}
                    alt="Illustration"
                    className="boxShot top"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section className="">
        <div className="is-vcentered right-bkg mt-5 p-4">
          <div className="py-3 textPart px-3">
            <div className="sectionHeading">How You Feel Matters</div>
            <h1 className="sectionMain">
              Let your <span style={{ color: "#3273dc" }}>mood</span> and{" "}
              <span style={{ color: "#3273dc" }}>energy</span> define how to
              optimally organize your work.
            </h1>
          </div>
          <div className="py-3 videoPart">
            <AutoplayingVideo mainVideo={WorkPush3} />
          </div>
        </div>
      </section>

      <section className="">
        <div className="is-vcentered left-bkg mt-5 p-4">
          <div className="py-3 textPart px-3">
            <div className="sectionHeading">
              Inner Resistance Blocks your Productivity
            </div>
            <h1 className="sectionMain">
              Identify the <span style={{ color: "#fc5c65" }}>feelings</span>{" "}
              that cause your{" "}
              <span style={{ color: "#fc5c65" }}>procrastination</span>.
            </h1>
          </div>
          <div className="py-3 videoPart">
            <AutoplayingVideo mainVideo={identify} />
          </div>
        </div>
      </section>

      <section className="">
        <div className="is-vcentered right-bkg mt-5 p-4">
          <div className="py-3 textPart px-3">
            <div className="sectionHeading">
              Others in Your Industry Feel the Same Way
            </div>
            <h1 className="sectionMain">
              Learn step-by-step{" "}
              <span style={{ color: "#23d160" }}>strategies</span> others in
              your industry have used to{" "}
              <span style={{ color: "#23d160" }}>overcome</span> similar
              feelings.
            </h1>
          </div>
          <div className="py-3 videoPart">
            <AutoplayingVideo mainVideo={stepbystep2} />
          </div>
        </div>
      </section>

      <section className="">
        <div className="is-vcentered left-bkg mt-5 p-4">
          <div className="py-3 textPart px-3">
            <div className="sectionHeading">Add Clarity to Your Tasks</div>
            <h1 className="sectionMain">
              Define what you know, what you{" "}
              <span style={{ color: "#3273dc" }}>don't know</span> and{" "}
              <span style={{ color: "#3273dc" }}>how to</span> figure it out.
            </h1>
          </div>
          <div className="py-3 videoPart">
            <AutoplayingVideo mainVideo={whatYouKnow} />
          </div>
        </div>
      </section>

      <section className="">
        <div className="is-vcentered right-bkg mt-5 p-4">
          <div className="py-3 textPart px-3">
            <div className="sectionHeading">We also Learn from Ourselves</div>
            <h1 className="sectionMain">
              <span style={{ color: "#fc5c65" }}>Celebrate</span> your success
              by recording your{" "}
              <span style={{ color: "#fc5c65" }}>learnings</span> for the future
              and share it with others.
            </h1>
          </div>
          <div className="py-3 videoPart">
            <AutoplayingVideo mainVideo={celebrate} />
          </div>
        </div>
      </section>

      <section className="has-text-centered mx-4">
        *FocusFeel is a web app that works best on a desktop or laptop browser
        window. Typically, FocusFeelers keep it open and work alongside it.{" "}
      </section>
      <Section color={"white"} size="medium">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-12 is-10-fullhd">
              <div className="columns is-vcentered">
                <div className="column is-half">
                  <SectionHeader
                    title="Instead of time management, we focus on self-management."
                    subtitle="Join our growing community to increase your productivity and be the opposite of alone."
                    size={3}
                    spaced={true}
                  />
                </div>
                <div className="column is-half">
                  <GetStarted
                    buttonText="Get Free Beta Access"
                    buttonColor="danger"
                    buttonInverted={false}
                    inputPlaceholder="Enter your email"
                    handleSetSubscribed={handleSetSubscribed}
                    subscribed={subscribed}
                    source="LaPa02 bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

function AutoplayingVideo(props) {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <img src={props.mainVideo} alt="Muted Video" />
  ) : (
    <div
      className="box boxVideo"
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          
              width="100%"
        >
        <source src="${props.mainVideo}" type="video/mp4" />
        </video>`,
      }}
    />
  );
}

export default LaPa02;
