import EvidenceFramework from "./FrameworkEvidence";

function SEvidenceNotSmart(props) {
  const thoughtToExamine = "I am not smart enough";

  // SEE CarouselAffirmationExamples to see how to fetch examples from firestore
  const examplesSupport = [
    [
      "I haven't been able to figure this out after spending 2 hours on it",
      "I have a hard time understanding technical jargon or complex terms, which makes me feel like I am not smart enough to understand this topic.",
    ],
    [
      "I often make mistakes in my work which makes me doubt my abilities.",
      "In school I didn't have the best grades.",
    ],
    [
      "I often need to ask for help or clarification, which makes me feel like I am not able to understand things on my own.",
    ],
  ];

  const examplesNotSupport = [
    [
      "I have been successful in figuring out hard things before, which shows that I am capable of it.",
      "I learn from my mistakes and get better over time, which shows that I am capable of improving.",
    ],
    [
      "I have been able to learn things before, which shows that I am able to understand complex ideas.",
      "I can come up with creative solutions to problems, which shows that I have good thinking abilities.",
    ],
    [
      "I work hard and am committed to learning, which is sometimes more important than just being smart.",
      "I am good at communicating with others, which shows that I am socially aware.",
    ],
    [
      "I have a lot of knowledge in many areas, which shows that I am curious and interested in learning.",
      "I can adapt to new situations, which shows that I am flexible and can handle change.",
    ],
  ];

  const examplesThoughts = [
    [
      "While it's true that I haven't been able to figure this out yet, I have done harder things before. All I need to do is keep at it and reach out for help when needed.",
    ],
    [
      "While I may not have all the answers, I know that I am resourceful and can ask for help and collaborate with others to find solutions.",
    ],
    [
      "My intelligence is not a fixed trait, but can be developed through learning and experience.",
    ],
    [
      "I may not be an expert in this area yet, but I am capable of learning and growing my skills and knowledge over time.",
    ],
    [
      "My intelligence is not the only factor in my success; hard work and determination make the biggest difference.",
    ],
    [
      "I can focus on my own progress and development, rather than comparing myself to others.",
    ],
    [
      "I have the potential to learn and succeed, and I can take steps to realize that potential.",
    ],
    [
      "It's okay to make mistakes and experience setbacks; they can be opportunities for growth and learning.",
    ],
  ];

  return (
    <EvidenceFramework
      thoughtToExamine={thoughtToExamine}
      examplesSupport={examplesSupport}
      examplesNotSupport={examplesNotSupport}
      examplesThoughts={examplesThoughts}
    />
  );
}

export default SEvidenceNotSmart;
