export function ConfirmDeleteModal(props) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <div className="box">
          <p>Are you sure you want to delete this task?</p>
          <button
            className="button is-danger mr-1"
            onClick={props.handleConfirm}
          >
            Delete
          </button>
          <button
            className="button"
            onClick={() => props.setIsModalOpen(false)}
          >
            No
          </button>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => props.setIsModalOpen(false)}
      />
    </div>
  );
}
