import React, { useState } from "react";

export default function AddAndDisplayList(props) {
  const [item, setItem] = useState("");

  const handleChange = (event) => {
    setItem(event.target.value);
  };

  const handleAdd = () => {
    if (item.length > 0) {
      props.setList(props.list.concat(item));
      setItem("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
    }
  };

  return (
    <>
      <div className="is-flex is-align-items-center">
        <input
          className="input"
          autoComplete="off"
          autoFocus={true}
          type="text"
          placeholder={props.placeholder}
          value={item}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleAdd}
        />
        <button className="button is-small mx-2 " onClick={handleAdd}>
          Add
        </button>
      </div>

      <div className="p-2">
        {props.list.map((item) => {
          return <li>{item}</li>;
        })}
      </div>
    </>
  );
}
