import React from "react";
import CardThoughts from "./Thoughts/CardThoughts";
import CarouselAccomplishments from "./../components/Accomplishments/CarouselAccomplishments";

function SidePanel(props) {
  return (
    <>
      <div className="sidePanelContainer column is-5">
        <CarouselAccomplishments
          handlePreselectStrategy={props.handlePreselectStrategy}
          handleProcessFeeling={props.handleProcessFeeling}
        />
        <CardThoughts
          thoughtsData={props.taskData?.thoughtsData}
          taskId={props.taskData?.id}
        />
      </div>
    </>
  );
}

export default SidePanel;
