import { updateTask } from "../../../../util/db.js";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useAuth } from "../../../../util/auth.js";
import { Mixpanel } from "../../../../util/mixpanel.js";

function AddMultipleSteps(props) {
  Mixpanel.track("Multiple steps form loaded");
  const auth = useAuth();
  const [stepInput, setStepInput] = useState();
  const [stepsAddedList, setStepsAddedList] = useState([]);

  const handleStepInputChange = (event) => {
    Mixpanel.track("Multiple step changed", {
      step: event.target.value,
    });
    setStepInput(event.target.value);
  };

  const handleStepInputKeyDown = (event) => {
    if (event.key === "Enter") {
      onAddStep();
      event.target.blur();
    }
  };

  const onAddStep = () => {
    Mixpanel.track("Multiple step saved from strategy form", {
      step: stepInput,
    });
    const newStep = {
      checked: false,
      content: stepInput,
      id: uuid(),
      infoTrigger: false,
    };
    let newStepsData = props.taskData.stepsData.concat(newStep);

    const query = updateTask(auth.user.uid, props.taskData.id, {
      stepsData: newStepsData,
    });

    query.then(() => {
      setStepsAddedList(stepsAddedList.concat(newStep));
      if (props.onAdd) {
        props.onAdd(newStep.content);
      }
      setStepInput("");
    });
  };

  return (
    <div className="m-3">
      <input
        className={`input ${stepInput ? "" : "is-danger"}`}
        type="text"
        placeholder={props.placeholder}
        value={stepInput}
        onChange={handleStepInputChange}
        onKeyDown={handleStepInputKeyDown}
        autoComplete="off"
        autoFocus={true}
      />
      <div className="control">
        <button
          className={"button is-small m-2 is-primary"}
          onClick={onAddStep}
        >
          Add as Step
        </button>
      </div>
      <div className="m-3">
        {stepsAddedList.map((item) => {
          return <li>{item.content}</li>;
        })}
      </div>
    </div>
  );
}

export default AddMultipleSteps;
