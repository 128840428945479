import FrameworkFlipIt from "./FrameworkFlipIt";

function SFlipItNotFeelLikeIt(props) {
  const examplesFlips = [
    ["Once I start working on drafting my report, I will feel less lazy."],
    [
      "Once I start working on sending an email to ask for help, I will feel braver.",
    ],
    ["Once I start working on building the payments infra, I will know more."],
    ["Once I start working on the design doc, I will feel less anxious."],
    ["Once I start working on rewriting the tests, I will feel less bored."],
  ];

  const conditions = [
    "feel less tired",
    "have more energy",
    "feel less lazy",
    "feel more interested",
    "feel less anxious",
    "feel more confident",
    "feel braver",
    "master the topic",
    "feel more ready",
  ];

  //const behavior = "start working on it";

  const initialDesiredCondition = "feel more like it";

  return (
    <FrameworkFlipIt
      title={props.title}
      examplesFlips={examplesFlips}
      excuses={conditions}
      //   behavior={behavior}
      initialDesiredCondition={initialDesiredCondition}
      encourage={
        "Getting started is often the hardest part. Once you gain momentum, it becomes easier to keep going and overcome any initial resistance."
      }
    />
  );
}

export default SFlipItNotFeelLikeIt;
