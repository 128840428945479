import "./FocusThoughtModal.scss";

function FocusThoughtModal(props) {
  return (
    <div className="modal is-active">
      <div
        className="modal-background focus thought"
        onClick={() => props.onDone()}
      />
      <div className="FocusThoughtModal__card card p-3">
        <div className="is-flex is-justify-content-end">
          <button
            className="delete"
            aria-label="close"
            onClick={() => props.onDone()}
          >
            Done
          </button>
        </div>

        <div className="m-2 is-size-3">{props.thoughtContent}</div>
      </div>
    </div>
  );
}

export default FocusThoughtModal;
