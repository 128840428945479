import { convertLegacyTasksToBoardData } from "./utils/convertLegacyTasksToBoardData.js";
import { useAuth } from "../../../util/auth.js";
import { useTasksByOwner, updateProject } from "../../../util/db.js";
import TaskBoardView from "./TaskBoardView.js";

function LegacyTaskBoardView(props) {
  const auth = useAuth();

  // will get tasks with a hook (like in tasks) and filter for project
  const {
    data: tasks,
    status: tasksStatus,
    error: tasksError,
  } = useTasksByOwner(auth.user.uid);

  let tasksBoardDataFromLegacy;

  console.log(
    "Hitting legacy view, will get all tasks and save again for projec: ",
    props.selectedProjectId
  );
  if (tasks) {
    const getTasksForProject = (project) => {
      if (project && project !== "0") {
        return tasks?.filter((task) => {
          return task.projectId === project;
        });
      } else {
        return tasks;
      }
    };

    const tasksForProject = getTasksForProject(props.selectedProjectId);

    // will call convertLegacy with tasksforProject
    tasksBoardDataFromLegacy = convertLegacyTasksToBoardData(tasksForProject);

    // save this new produced boardData to selectedProject
    updateProject(auth.user.uid, props.selectedProjectId, {
      boardData: tasksBoardDataFromLegacy,
    });
  }
  // return regular TaskBoardView with the boardData produced
  return (
    <>
      {tasksStatus !== "loading" && tasksBoardDataFromLegacy && (
        <TaskBoardView
          tasksBoardData={tasksBoardDataFromLegacy}
          selectedProjectId={props.selectedProjectId}
        />
      )}
    </>
  );
}

export default LegacyTaskBoardView;
