import { useState } from "react";
import { updateProject } from "../../util/db.js";
import EditableItem from "./EditableItem.js";
import { useAuth } from "../../util/auth.js";

function TaskResourcesSection({
  initialResourceList,
  projectId,
  fieldName,
  title,
  placeholder,
}) {
  const auth = useAuth();

  const [resourceName, setResourceName] = useState();
  const [pendingResource, setPendingResource] = useState(false);
  let resourceList = initialResourceList || []; // DRY: initial value - project?.resources?.experts

  function handleAddResource() {
    //Add resource to projects/id/resources/?
    setPendingResource(true);
    const newList = resourceList?.concat([{ name: resourceName }]);
    const query = updateProject(auth.user.uid, projectId, {
      [fieldName]: newList, //DRY: field name CHECK HERE! is key string?
    });
    query
      .then(() => {
        setPendingResource(false);
      })
      .catch(() => {
        // Hide pending indicator
        setPendingResource(false);
      });
    setResourceName("");
  }

  const handleEdit = (value, index) => {
    //update list with slice
    const newList = [
      ...resourceList.slice(0, index),
      { name: value },
      ...resourceList.slice(index + 1),
    ];

    const newListWithoutEmpty = newList.filter((item) => item.name);

    updateProject(auth.user.uid, projectId, {
      [fieldName]: newListWithoutEmpty,
    });
  };

  const handleChangeResource = (event) => {
    setResourceName(event.target.value);
  };

  const handleKeyDownResource = (event) => {
    if (event.key === "Enter") {
      handleAddResource();
    }
  };

  return (
    <>
      <div className="is-size-5 my-2 has-text-weight-semibold">{title}</div>
      <div className="is-flex is-align-items-center">
        <input
          className="input is-small"
          type="text"
          placeholder={placeholder}
          value={resourceName}
          onChange={handleChangeResource}
          onKeyDown={handleKeyDownResource}
        />
        <button
          className={
            "button is-small mx-2 " + (pendingResource ? " is-loading" : "")
          }
          onClick={() => handleAddResource()}
        >
          Add
        </button>
      </div>
      <div className="m-2">
        {initialResourceList?.map((item, index) => {
          return (
            <EditableItem item={item} index={index} handleEdit={handleEdit} />
          );
        })}
      </div>
    </>
  );
}

export default TaskResourcesSection;
