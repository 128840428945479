import { getProject, updateProject } from "../../../../util/db.js";

export function moveCardToOrFromDone(cardId, projectId, userId, isDone) {
  // Get project.boardData from Firestore
  const projectDataPromise = getProject(userId, projectId);

  // Get the lanes from the boardData
  projectDataPromise.then((projectData) => {
    let cardToMove;
    // Find and remove the card from its current lane
    projectData?.boardData?.lanes?.forEach((lane) => {
      const cardIndex = lane.cards.findIndex((card) => card.id === cardId);
      if (cardIndex > -1) {
        // Card found, remove from current lane and stop searching
        cardToMove = lane.cards.splice(cardIndex, 1)[0];
        return;
      }
    });

    // If the card was found, add it to the 'done' lane if true,
    // otherwise add it to the 'wip' lane
    if (cardToMove) {
      if (isDone) {
        const doneLane = projectData?.boardData?.lanes?.find(
          (lane) => lane.id === "done"
        );
        if (doneLane) {
          doneLane.cards.unshift(cardToMove);
        }
      } else {
        const wipLane = projectData?.boardData?.lanes?.find(
          (lane) => lane.id === "wip"
        );
        if (wipLane) {
          wipLane.cards.unshift(cardToMove);
        }
      }
    }

    // update project.boardData with new boardData
    updateProject(userId, projectId, {
      boardData: projectData.boardData,
    });
  });
}
