import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples.js";
import RenderSComponent from "./RenderSComponent.js";
import AddStepInput from "./utils/AddStepInput.js";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";

function SOvercomeWorstFear(props) {
  const [fearInput, setFearInput] = useState("");
  const [worstCaseScenario, setWorstCaseScenario] = useState("");
  const [likelihood, setLikelihood] = useState("");

  // Handlers for input changes
  const handleFearInputChange = (event) => {
    setFearInput(event.target.value);
  };

  const handleWorstCaseScenarioChange = (event) => {
    setWorstCaseScenario(event.target.value);
  };

  const handleLikelihoodChange = (event) => {
    setLikelihood(event.target.value);
  };

  // Examples for inspiration (Consider these to be placeholders; you might want to fetch real examples or tailor these)
  const examplesFears = [
    [
      "I'm afraid that I will not be able to figure it out quickly and everyone will think I am dumb.",
    ],
    ["I'm afraid of failing the project and disappointing my team."],
    ["I fear that my idea will be rejected."],
  ];

  const examplesWorstCases = [
    [
      "I fail to figure it out, everyone thinks I am incompetent and I get a bad performance review.",
    ],
    ["The project fails, leading to a loss of trust and opportunities."],
    ["My idea is rejected, and I miss out on potential career advancement."],
  ];

  const examplesActionPlans = [
    [
      "If the project fails, I will take the time to understand what went wrong and learn from it. I will then look for a new project to work on.",
    ],
    [
      "If the worst happens and I get fired, I will invest time in my startup. I will work on it for 6 months and give it time to get some sales in. If that doesn't work out, I will spend 3 months studying for interviews and get a higher paying job.",
    ],
    [
      "If my idea is rejected, I will take the feedback and work on it. I will then look for other opportunities to present it.",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            Confronting our fears directly can help us understand them better
            and prepare to overcome them. This strategy guides you through
            articulating your fear, considering the worst-case scenario,
            assessing how likely it is, and developing an action plan.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">Articulate Your Fear</div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                When you think of <i>"{props.stepOrTask}"</i>. What is the{" "}
                <b>fear that comes to your mind</b>:
              </div>
              <input
                autoFocus={true}
                className="input"
                type="text"
                placeholder="I'm afraid that..."
                value={fearInput}
                onChange={handleFearInputChange}
              />
            </div>

            <div className="column examples">
              <div>Get inspired by others in your field:</div>
              <CarouselExamples examples={examplesFears} />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">Worst Case Scenario</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="recall p-2 m-2">
                Fear: <i>"{fearInput}"</i>
              </div>
              <div className="my-2 p-2">
                Based on your fear, <b>describe the worst-case scenario</b> that
                you are worried about. Don't hold back, let it all out:
              </div>
              <div className="control m-2">
                <textarea
                  className="textarea"
                  placeholder="The worst that could happen is..."
                  value={worstCaseScenario}
                  onChange={handleWorstCaseScenarioChange}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Here are some examples from others in your industry:
              </div>
              <CarouselExamples examples={examplesWorstCases} />
            </div>
          </div>
        </div>,

        <div>
          <div className="boxStepSubtitle">Likelihood Assessment</div>
          <div className="recall p-2 m-2">
            Worst case scenario: <i>"{worstCaseScenario}"</i>
          </div>
          <div className="my-2 p-2">
            Now,{" "}
            <b>rate the likelihood of this scenario happening from 1 to 10</b>,
            1 being unlikely and 10 being almost guaranteed to happen:
          </div>
          <div className="control m-2">
            <input
              className="input"
              type="text"
              placeholder="The likelihood from 1 to 10 is..."
              value={likelihood}
              onChange={handleLikelihoodChange}
            />
          </div>
        </div>,

        <div>
          <div className="boxStepSubtitle">Action Plan</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="recall p-2 m-2">
                <div>
                  Worst case scenario: <i>"{worstCaseScenario}"</i>
                </div>
                <div>
                  Likelihood: <i>{likelihood} out of 10</i>
                </div>
              </div>
              <div className="my-2 p-2">
                Now, <b>imagine your worst-case scenario actually happens</b>.
                What do you do next? What are the steps you can take to mitigate
                the situation and get back on track? Create an action plan to
                recover, and be as specific as possible:
              </div>
              <SaveThoughtForm placeholder="If the worst scenario happens, I will..." />
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Here are some examples from others in your industry:
              </div>
              <CarouselExamples examples={examplesActionPlans} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SOvercomeWorstFear;
