import { useState } from "react";
import { requireAuth } from "../../util/auth.js";
import "./onboarding.scss";
import { useForm } from "react-hook-form";
import FormField from "../../components/FormField";
import { updateUser } from "../../util/db";
import { useAuth } from "./../../util/auth.js";

function StepOne(props) {
  const { register, handleSubmit, errors } = useForm();
  const auth = useAuth();

  const onSubmit = (data) => {
    console.log("submitted data: ", data);
    updateUser(auth.user.uid, data).then(props.handleNext());
  };

  const workOptions = [
    { label: "Software Development", value: "Software development" },
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    { label: "Student", value: "Student" },
    { label: "Consultant", value: "Consultant" },
    { label: "Customer Support", value: "Customer Support" },
    { label: "Design", value: "Design" },
    { label: "People Manager", value: "Manager" },
    { label: "Product Management", value: "Product management" },
    { label: "CEO/Founder", value: "CEO/Founder" },
    { label: "Writer/Journalist", value: "Writer/Journalist" },
    { label: "Other", value: "Other" },
  ];

  return (
    <>
      <div className="is-size-3 has-text-centered has-text-weight-semibold">
        Tell us about yourself
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4 field is-horizontal">
          <div className="field-body">
            <FormField
              name="name"
              type="input"
              placeholder="Your name"
              size="medium"
              error={errors.name}
              autoFocus={true}
              autoComplete={false}
              inputRef={register({
                required: "Please enter a name",
              })}
            />
          </div>
        </div>

        <div className="my-4 field is-horizontal">
          <div className="field-body">
            <div className={`select is-medium`}>
              <select
                ref={register({
                  required: "Please enter a type of work",
                })}
                type={"select"}
                name="workType"
                placeholder="What kind of work do you do?"
                error={errors.workType}
                autoFocus={false}
              >
                <option disabled={true} selected value="">
                  What kind of work do you do?
                </option>
                {workOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="field is-flex is-justify-content-end">
          <div className="control">
            <button
              className={
                "button is-success is-outlined mx-1 my-1"
                // + (pending ? " is-loading" : "")
              }
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default StepOne;
