import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import { useTask } from "../../../util/db.js";
import { useAuth } from "../../../util/auth.js";
import { useParams } from "react-router-dom";
import RenderSComponent from "./RenderSComponent.js";
import AddMultipleSteps from "./utils/AddMultipleSteps";

function SFamiliarWork(props) {
  const examplesFamiliarTasks = [
    [
      "Setting up the document's outline",
      "Doing a quick search for information",
      "Brainstorming topics",
    ],
    [
      "Breaking a big task into smaller components",
      "Organizing available resources into lists",
    ],
    [
      "Making questions to clarify the scope of the project",
      "Planning the agenda for meetings",
    ],
    ["Brainstorming ideas for how to approach a problem"],
  ];

  // Get task info (for steps)
  const { id } = useParams();
  const auth = useAuth();
  const { data: taskData } = useTask(auth.user.uid, id);

  // LIST
  const [familiarList, setFamiliarList] = useState([]);
  //temporary input value
  const [famTask, setFamTask] = useState("");
  const handleAdd = () => {
    if (famTask.length > 0) {
      setFamiliarList(familiarList.concat(famTask));
      setFamTask("");
    }
  };
  const handleChange = (event) => {
    setFamTask(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAdd();
    }
  };

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p className="my-3">
            When we're feeling overwhelmed or anxious, it can be difficult to
            get started or make progress. In these moments,{" "}
            <b>
              returning to familiar work can provide a sense of comfort and
              stability
            </b>
            , helping us settle ourselves and get back on track.
          </p>
          {/* <p className="my-3">
            This exercise will guide you through identifying the tasks and
            projects that are most familiar and comfortable to you, and help you
            use them to regain your focus and productivity. By leveraging your
            existing skills and experience, you can build confidence and
            momentum to tackle more challenging work.
          </p> */}
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify familiar tasks*/}
          <div className="mb-4 boxStepSubtitle">
            Identify tasks you are familiar with
          </div>
          <div className="columns">
            <div className="column is-6 m-2">
              Make a list of tasks or activities that you've done before and
              feel comfortable with:
              <div className="is-flex is-align-items-center m-2">
                <input
                  className="input"
                  autoComplete="off"
                  autoFocus={true}
                  type="text"
                  placeholder="A task I feel comfortable doing is..."
                  value={famTask}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleAdd}
                />
                <button className="button is-small mx-2 " onClick={handleAdd}>
                  Add
                </button>
              </div>
              <div className="p-2">
                {familiarList.map((item) => {
                  return <li>{item}</li>;
                })}
              </div>
            </div>
            <div className="column examples">
              <div>Get inspired by others in your field:</div>
              <CarouselExamples examples={examplesFamiliarTasks} />
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Create next steps to regain focus*/}
          <div className="mb-4 boxStepSubtitle">
            Identify the next actions you can take to reorient yourself and
            regain your focus
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="m-2 ">
                Based on the familiar tasks you identified,{" "}
                <b>develop a set of actionable steps</b> you can take to get
                back on track and make progress on the task{" "}
                <i>"{props.stepOrTask}"</i>.
              </div>
              <AddMultipleSteps
                taskData={taskData}
                placeholder={"Add one concrete step, be as specific as you can"}
              />
            </div>
            <div className="column recall">
              <div className="has-text-weight-semibold subtitle is-6">
                Tasks you are familiar with:
              </div>
              {familiarList.map((item) => {
                return <li>{item}</li>;
              })}
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SFamiliarWork;
