import { useAuth } from "../../util/auth.js";
import { useProject } from "../../util/db.js";
import TaskResourcesSection from "./TaskResourcesSection.js";

function TaskResources(props) {
  const auth = useAuth();

  const { data: project, status: projectStatus } = useProject(
    auth.user.uid,
    props.projectId
  );

  // Render
  return (
    <div className="m-3">
      <div className="subtitle has-text-weight-semibold">
        Resources to keep in mind
      </div>

      <TaskResourcesSection
        initialResourceList={project?.resources?.experts}
        projectId={props.projectId}
        fieldName={"resources.experts"}
        title={"Experts to Ask"}
        placeholder={"Add new expert"}
      />

      <TaskResourcesSection
        initialResourceList={project?.resources?.sources}
        projectId={props.projectId}
        fieldName={"resources.sources"}
        title={"Sources to Research"}
        placeholder={"Add new source"}
      />

      <TaskResourcesSection
        initialResourceList={project?.resources?.actions}
        projectId={props.projectId}
        fieldName={"resources.actions"}
        title={"Possible Actions"}
        placeholder={"Add new action"}
      />
    </div>
  );
}

export default TaskResources;
