import FrameworkTransform from "./FrameworkTransform";

function STransformNeverBetter(props) {
  const thoughtToTransform = "I'll never get better";

  const examplesTransform = [
    "I'll focus on PROGRESS and IMPROVEMENT, rather than on achieving perfection or mastery right away.",
    "I'll view obstacles and lack of knowledge/skill as temporary and surmountable, rather than insurmountable roadblocks.",
    "I'll adopt a can-do attitude and remind myself that I have the ability to learn and succeed.",
    "I'll remind myself of times when I've improved in the past, even if progress was slow, and use those experiences as motivation to keep going.",
    "I'll seek help or guidance from others who can support me in my learning and growth.",
    "I'll be patient with myself and show self-compassion, recognizing that progress is not always linear.",
  ];

  return (
    <FrameworkTransform
      thoughtToTransform={thoughtToTransform}
      examplesTransform={examplesTransform}
    />
  );
}

export default STransformNeverBetter;
