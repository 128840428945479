//import LaPa01 from "./LaPa01";
import LaPa02 from "./LaPa02";

function IndexPage(props) {
  //  return <LaPa01 />;

  return <LaPa02 />;
}

export default IndexPage;
