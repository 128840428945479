import { useState } from "react";
import RenderSComponent from "./RenderSComponent.js";
import CarouselExamples from "./utils/CarouselExamples.js";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";
import AddAndDisplayList from "./utils/AddAndDisplayList.js";

function SCorporateMindset(props) {
  const [corporateName, setCorporateName] = useState("");
  const [setbacks, setSetbacks] = useState([]);

  // Handler for corporate name change
  const handleCorporateNameChange = (event) => {
    setCorporateName(event.target.value.trim() + ", Inc.");
  };

  // Placeholder examples for setbacks
  const examplesSetbacks = [
    "I'm afraid to ask for help.",
    "I don't know how to approach this task.",
    "I'm worried about making mistakes.",
  ];

  // Examples for corporate approach to setbacks
  const examplesCorporateApproach = [
    [
      `'${corporateName} sees asking for help as a strategic consultation, seeking expertise to enhance our business solutions.'`,
    ],
    [
      `'${corporateName} approaches new tasks with thorough market research, ensuring every step is data-driven and strategically sound.'`,
    ],
    [
      `'${corporateName} considers mistakes as opportunities for process improvement and innovation within the company.'`,
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            <b>Separating your personal identity from your work persona</b> can
            reduce anxiety and help you approach tasks and feedback more
            objectively. By thinking of yourself as a corporation, you adopt a
            professional mindset that focuses on growth, solution finding, and
            business outcomes.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">Define Your Setbacks</div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                Identify what setbacks, feelings, or issues are currently
                holding you back from approaching your tasks effectively.
              </div>
              <AddAndDisplayList
                placeholder="Add a setback or issue here..."
                list={setbacks}
                setList={setSetbacks}
              />
            </div>
            <div className="column examples">
              <div>Common professional setbacks:</div>
              <CarouselExamples
                examples={examplesSetbacks.map((setback) => [setback])}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">
            Establish Your Corporate Identity
          </div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                What name are you known by professionally?
              </div>
              <input
                autoFocus={true}
                className="input"
                type="text"
                placeholder="Enter your professional name"
                onChange={handleCorporateNameChange}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">Your Corporate Approach</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              Let's add <b>", Inc."</b> to the end to establish your corporate
              identity.
              <div className="recall p-2 m-2">
                Your Corporate Identity: <i>{corporateName}</i>
                <div>
                  Setbacks identified:{" "}
                  {setbacks.map((setback, index) => (
                    <li key={index}>{setback}</li>
                  ))}
                </div>
              </div>
              <div className="my-2 p-2">
                Imagine how your corporation, <b>{corporateName}</b>, would
                professionally approach these issues. How would a corporation
                turn these setbacks into business objectives and actionable
                plans?
              </div>
              <SaveThoughtForm
                defaultValue={`At '${corporateName}', we approach`}
              />
            </div>
            <div className="column examples">
              <div>Corporate strategies for common setbacks:</div>
              <CarouselExamples examples={examplesCorporateApproach} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SCorporateMindset;
