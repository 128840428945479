import { useAuth } from "./../util/auth.js";
import { Link, useRouter } from "./../util/router";

function SettingsGeneral(props) {
  const auth = useAuth();

  return (
    <div>
      <div className="is-flex m-3">
        <div className="mx-2 has-text-weight-bold">Email:</div>
        <div>{auth.user.email}</div>
      </div>
      {/* <div className="is-flex m-3">
        <div className="mx-2 has-text-weight-bold">Subscription Status:</div>
        <div>
          {auth.user.planIsActive && (
            <div className="face-smile has-text-weight-bold">Active</div>
          )}
          {!auth.user.planIsActive && (
            <>
              <div className="alarm-exclamation">Inactive</div>
              <div>
                You can signup for a plan in
                <Link to="/pricing" className="has-text-link ml-1">
                  <strong>pricing</strong>
                </Link>
                .
              </div>
            </>
          )}
        </div>
      </div>
      {auth.user.planIsActive && (
        <>
          <div className="is-flex mx-3">
            <div className="mx-2 has-text-weight-bold">Subscription Plan:</div>
            <div>
              <div>{auth.user.planId?.toUpperCase()}*</div>
            </div>
          </div>
          <div className="mx-5 mt-1">
            * You can make changes to your subscription in
            <Link to="/settings/billing" className="has-text-link ml-1">
              <strong> billing</strong>
            </Link>
            .
          </div>
        </>
      )} */}
    </div>
  );
}

export default SettingsGeneral;
