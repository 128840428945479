const strategiesList = {
  distinguishCatastropheNoncritical: {
    id: "distinguishCatastropheNoncritical",
    title: "Differentiate between catastrophes and noncritical failures",
  },

  distinguishNoiseSignal: {
    id: "distinguishNoiseSignal",
    title: "Worries: Signals vs Noise",
  },
  evidenceCantAsk: {
    id: "evidenceCantAsk",
    title: "Examine the evidence",
  },
  evidenceComplicated: {
    id: "evidenceComplicated",
    title: "Examine the evidence",
  },
  evidenceNotSmart: {
    id: "evidenceNotSmart",
    title: "Examine the evidence",
  },
  evidenceSlow: {
    id: "evidenceSlow",
    title: "Examine the evidence",
  },
  familiarWork: {
    id: "familiarWork",
    title: "Use familiar work to settle yourself",
  },
  flipItNotFeelLikeIt: {
    id: "flipItNotFeelLikeIt",
    title: "Flip It Around",
  },
  flipItOverwhelmed: {
    id: "flipItOverwhelmed",
    title: "Flip It Around",
  },
  flipItNeverBetter: {
    id: "flipItNeverBetter",
    title: "Flip It Around",
  },
  flipItSkills: {
    id: "flipItSkills",
    title: "Flip It Around",
  },
  flipItAnxious: {
    id: "flipItAnxious",
    title: "Flip It Around",
  },
  opinion: {
    id: "opinion",
    title: "Recognize that other people's opinions of me do not define me",
  },
  procrastep: {
    //not ready yet
    id: "procrastep",
    title: "Step by step approach to procrastination",
  },
  traitAdmire: {
    id: "traitAdmire",
    title: "Adopt a trait from a colleague you admire",
  },
  transformComplicated: {
    id: "transformComplicated",
    title:
      "Transform this discouraging thought into one that compels you to take action",
  },
  transformLater: {
    id: "transformLater",
    title:
      "Transform this discouraging thought into one that compels you to take action",
  },
  transformNeverBetter: {
    id: "transformNeverBetter",
    title:
      "Transform this discouraging thought into one that compels you to take action",
  },
  transformNotFeelLikeIt: {
    id: "transformNotFeelLikeIt",
    title:
      "Transform this discouraging thought into one that compels you to take action",
  },
  transformNotSmart: {
    id: "transformNotSmart",
    title:
      "Transform this discouraging thought into one that compels you to take action",
  },
  shouldPreferFaster: {
    id: "shouldPreferFaster",
    title: "Replace the 'should' statement",
  },
  shouldPreferKnow: {
    id: "shouldPreferKnow",
    title: "Replace the 'should' statement",
  },
  motivationSharedGoals: {
    id: "motivationSharedGoals",
    title: "Think of a goal you share with a loved one",
  },
  compassionateFearFailure: {
    id: "compassionateFearFailure",
    title: "Practice self-compassionate talk",
  },
  worriesIntoVision: {
    id: "worriesIntoVision",
    title: "Imagine what success looks like",
    //title: "Turn your worries into your success story",
    //title: "Turn your worries into a visualization of success"
  },
  rememberPastAccomplishment: {
    id: "rememberPastAccomplishment",
    title: "Remember a past accomplishment and what it says about you",
  },
  overcomeWorstFear: {
    id: "overcomeWorstFear",
    title: "Overcome your worst fear",
  },
  putFeelingInQuotes: {
    id: "putFeelingInQuotes",
    title: "Put the feeling in quotes to distance yourself",
  },
  reframeNervousness: {
    id: "reframeNervousness",
    title: "Reframe nervousness as excitement",
  },
  learningOpportunity: {
    id: "learningOpportunity",
    title: "Reframe it as a learning opportunity",
  },
  easyParts: {
    id: "easyParts",
    title: "Tackle the easy parts first",
  },
  corporateMindset: {
    id: "corporateMindset",
    title: "Think of yourself as a corporation",
  },
  corporateHelp: {
    id: "corporateHelp",
    title: "Think of yourself as a corporation",
  },
  corporateIncompetent: {
    id: "corporateIncompetent",
    title: "Think of yourself as a corporation",
  },
};

export default strategiesList;
