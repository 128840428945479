import { useState } from "react";
import { updateTask, useTask } from "../../../../util/db.js";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../util/auth.js";
import { v4 as uuid } from "uuid";
import FocusMotivationModal from "../../../Motivations/FocusMotivationModal.js";
import { Mixpanel } from "../../../../util/mixpanel.js";

function AddMotivationForm(props) {
  Mixpanel.track("Motivation form loaded", {
    defaultValue: props.defaultValue,
  });
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [motivationInput, setMotivationInput] = useState(props.defaultValue);
  const [savedMotivation, setSavedMotivation] = useState("none");
  const [focusingMotivation, setFocusingMotivation] = useState(null);
  const [focusChecked, setFocusChecked] = useState(true);

  const { id } = useParams();
  const { data: taskData, status: taskStatus } = useTask(auth.user.uid, id);

  const isMotivationSaved = () => {
    return savedMotivation === motivationInput;
  };

  const onChangeMotivation = (event) => {
    setMotivationInput(event.target.value);
    Mixpanel.track("Motivation changed", {
      motivation: event.target.value,
    });
  };

  const onAddMotivation = () => {
    Mixpanel.track("Motivation saved from strategy form", {
      motivation: motivationInput,
    });
    setPending(true); //to make button appear loading
    if (id && taskStatus !== "success") {
      return null;
    }

    let newMotivationsData = taskData?.motivationsData || [];
    //adding new motivation
    newMotivationsData?.push({ id: uuid(), content: motivationInput });

    const query = updateTask(auth.user.uid, id, {
      motivationsData: newMotivationsData,
    });

    query.then(() => {
      setSavedMotivation(motivationInput);
      setPending(false);
      setFocusingMotivation(motivationInput);
    });
  };

  return (
    <>
      <div className="m-3">
        <textarea
          className="textarea"
          placeholder={props.placeholder}
          type="text"
          autoComplete="off"
          autoFocus={true}
          onChange={onChangeMotivation}
          value={motivationInput}
        />

        <div className="control is-flex flex-direction-row">
          <button
            className={`button is-small m-2 ${
              isMotivationSaved() ? "is-static" : "is-primary"
            } ${pending ? " is-loading" : ""}`}
            onClick={onAddMotivation}
          >
            {isMotivationSaved() ? "Motivation Saved" : "Save Motivation"}
          </button>
          <label className="is-flex is-flex-direction-row is-align-items-center ml-3">
            <input
              type="checkbox"
              defaultChecked={focusChecked}
              onChange={() => setFocusChecked(!focusChecked)}
            />
            <div className="mx-1 mt-1">Focus after save</div>
          </label>
        </div>
      </div>
      {isMotivationSaved() && focusingMotivation && focusChecked && (
        <FocusMotivationModal
          motivationContent={focusingMotivation}
          onDone={() => setFocusingMotivation(null)}
        />
      )}
    </>
  );
}

export default AddMotivationForm;
