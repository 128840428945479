import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples.js";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";

function SLearningOpportunity(props) {
  const [fearInput, setFearInput] = useState("");
  const [pastExperience, setPastExperience] = useState("");

  // Handlers for input changes
  const handleFearInputChange = (event) => {
    setFearInput(event.target.value);
  };

  const handlePastExperienceChange = (event) => {
    setPastExperience(event.target.value);
  };

  const examplesPastLearnings = [
    [
      "I didn't know how to implement the program at first, and it took me a while to figure it out. But now I understand the area deeply, better than I would have if it had been easy from the start.",
    ],
    [
      "On the midterm, every question I couldn't answer taught me what I needed to focus on learning next. Using that as a guide, I aced the final exam.",
    ],
    [
      "The feedback on my presentation skills from the Demo Day led me to seek out workshops, significantly improving my public speaking.",
    ],
    [
      "Not getting the result I wanted pushed me to innovate and try new approaches, leading to breakthroughs I hadn't considered.",
    ],
  ];

  const examplesOpportunities = [
    [
      "Even though I'm concerned about completing this project on time, engaging in it will enhance my time management skills and deepen my understanding of the topic, making me the go-to expert on my team.",
    ],
    [
      "Facing the possibility of receiving critical feedback, I will learn to view critiques as constructive, helping me refine my ideas and improve my work quality.",
    ],
    [
      "While the task requires me to use software I'm not familiar with, this is a perfect opportunity to expand my technical skills and adaptability to new tools.",
    ],
    [
      "Although I'm nervous about presenting my findings, this task will improve my public speaking and presentation skills, making me more confident in communicating complex ideas.",
    ],
    [
      "This project might push me out of my comfort zone due to its collaborative nature, but it will also teach me valuable lessons in teamwork, leadership, and conflict resolution.",
    ],
    [
      "I'm worried I might not find the solution right away, but this challenge will hone my problem-solving skills, encouraging creative thinking and innovation.",
    ],
    [
      "Even if I face setbacks, this experience will teach me resilience and persistence, showing me that perseverance is key to overcoming obstacles.",
    ],
    [
      "Engaging in this task might require seeking help from others, providing a great chance to expand my professional network and improve my ability to gather resources efficiently.",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            Viewing challenges as opportunities for growth{" "}
            <b>transforms how we approach tasks and interpret outcomes</b>. This
            strategy not only helps in recognizing the potential for learning in
            every situation but also in valuing the journey of improvement that
            comes with each attempt, regardless of its immediate success.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">
            Acknowledge Your Current Feeling
          </div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                Reflect on your current task <i>"{props.stepOrTask}"</i> and the
                feelings it evokes. What concerns or fears arise?
              </div>
              <textarea
                autoFocus={true}
                className="textarea"
                placeholder="I'm worried that..."
                value={fearInput}
                onChange={handleFearInputChange}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">
            Recall a Past Learning Moment
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="mb-2 p-2">
                Think back to a{" "}
                <b>time when an initial failure led to eventual success</b>. How
                did that experience contribute to your growth or success later
                on?
              </div>
              <textarea
                className="textarea"
                placeholder="A past failure that turned into a learning opportunity was..."
                value={pastExperience}
                onChange={handlePastExperienceChange}
              />
            </div>
            <div className="column examples">
              <div>Examples of learnings from challenges:</div>
              <CarouselExamples examples={examplesPastLearnings} />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">
            Identify the Learning Opportunity
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="recall p-2 m-2">
                <div>
                  Facing the challenge: <i>"{props.stepOrTask}"</i>
                </div>
                <div>
                  Acknoledging the fear: <i>"{fearInput}"</i>
                </div>
              </div>
              <div className="my-2 p-2">
                Considering your current challenge,{" "}
                <b>
                  what specifically could you learn from engaging in this task
                </b>
                , even if the results aren't perfect? How can this opportunity
                enhance your skills or knowledge?
              </div>
              <div className="recall p-2 m-2">
                <div>
                  Remember a similar learning: <i>"{pastExperience}"</i>
                </div>
              </div>

              <SaveThoughtForm placeholder="Even though this task is challenging, it will help me learn..." />
            </div>
            <div className="column examples">
              <div>Examples of learnings from challenges:</div>
              <CarouselExamples examples={examplesOpportunities} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SLearningOpportunity;
