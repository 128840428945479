import React, { useState } from "react";
import FormAlert from "../FormAlert";
import FormField from "../FormField";
import { useAuth } from "../../util/auth.js";
import { useForm } from "react-hook-form";
import { createAffirmation } from "../../util/db.js";
import "./EditAffirmationModal.scss";
import CarouselAffirmationExamples from "./CarouselAffirmationExamples.js";
import IndustryCategory from "../../pages/IndustryCategory";

function EditAffirmationModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    setPending(true);

    const query = createAffirmation(auth.user.uid, props.task.id, {
      ...data,
    });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="EditAffirmationModal__card card">
        <header className="card-header">
          <p className="card-header-title">
            {props.affirmation && <>Update</>}
            {!props.affirmation && <>Create</>}
            {` `}Visualization:{" "}
            <span className="has-text-weight-light mx-1">
              {props.task.name}
            </span>
          </p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <div className="card-content columns">
          <div className="column is-half">
            {formAlert && (
              <FormAlert type={formAlert.type} message={formAlert.message} />
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <h4 className="subtitle is-4 has-text-weight-semibold">
                Worries
              </h4>
              <label className="subtitle mb-2">
                Write down everything that you are worried might go wrong in
                this task
              </label>
              <FormField
                name="worry"
                type="textarea"
                defaultValue={props.affirmation && props.affirmation.worry}
                size="medium"
                error={errors.worry}
                autoFocus={true}
                inputRef={register({
                  required: false,
                })}
              />
              <h4 className="subtitle is-4 has-text-weight-semibold">
                Visualization
              </h4>
              <label className="subtitle mb-1">
                Now write in the past tense how you managed to do it
                successfully. Use your imagination and give as much detail as
                you can
              </label>
              <FormField
                name="name"
                type="textarea"
                defaultValue={props.affirmation && props.affirmation.name}
                size="medium"
                error={errors.name}
                inputRef={register({
                  required: "Please enter a visualization",
                })}
              />
              <div className="field">
                <div className="control">
                  <button
                    className={
                      "button is-medium is-primary" +
                      (pending ? " is-loading" : "")
                    }
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="column examples">
            <div className="subtitle">
              Get inspired by browsing example answers from other people in your
              industry
            </div>
            {/* <IndustryCategory
              taskId={props.task.id}
              taskCategory={props.task.category}
            /> */}
            <CarouselAffirmationExamples />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAffirmationModal;
