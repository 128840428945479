import React, { useState, useEffect } from "react";
import Collapse from "../Collapse";
import EditTaskNotesModal from "./EditTaskNotesModal";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";

function CardEditTaskNotes(props) {
  const [editingNotes, setEditingNotes] = useState(props.isForceEditTaskNotes);

  useEffect(() => {
    setEditingNotes(props.isForceEditTaskNotes);
  }, [props.isForceEditTaskNotes]);

  // If we are updating an existing task
  // don't show modal until task data is fetched.
  if (!props.taskData) {
    return null;
  }

  const notes = props.taskData?.notes;
  const knowList = props.taskData?.knowList;
  const clarityEmpty = !notes && !knowList;

  return (
    <>
      <Collapse
        title="Clarity"
        subtitle="Do you know exactly what you need to do and how to do it?"
      >
        {clarityEmpty && (
          <div className="py-3 px-3">
            <div>Nothing yet.</div>
            <button
              className="button is-outlined is-primary m-3"
              onClick={() => setEditingNotes(true)}
            >
              Add Clarity
            </button>
          </div>
        )}

        {!clarityEmpty && (
          <div className="is-flex is-justify-content-space-between">
            <div>
              {notes && (
                <div className="">
                  <div className="has-text-weight-semibold">I need to do:</div>
                  <div className="mx-4 content">
                    {
                      <Interweave
                        content={notes}
                        matchers={[new UrlMatcher("url")]}
                        newWindow={true}
                      />
                    }
                  </div>
                </div>
              )}
              {knowList.length > 0 && (
                <div className="mt-4">
                  <div className="has-text-weight-semibold">What I know:</div>
                  <div>
                    {knowList.map((item) => {
                      return (
                        <li key={item.id}>
                          {
                            <Interweave
                              content={item.name}
                              matchers={[new UrlMatcher("url")]}
                              newWindow={true}
                            />
                          }
                        </li>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div>
              <button
                className="button action is-ghost"
                aria-label="update"
                onClick={() => setEditingNotes(true)}
              >
                <span className="icon is-small">
                  <i className="fas fa-edit" />
                </span>
              </button>
            </div>
          </div>
        )}
      </Collapse>

      {editingNotes && (
        <EditTaskNotesModal
          onDone={() => {
            setEditingNotes(false);
            props.handleForceEditTaskNotes(false, "");
          }}
          taskData={props.taskData}
          isForceEditTaskNotes={props.isForceEditTaskNotes}
          stepForInfo={props.stepForInfo}
        />
      )}
    </>
  );
}

export default CardEditTaskNotes;
