import FrameworkShouldPrefer from "./FrameworkShouldPrefer";

function SShouldPreferKnow(props) {
  const examplesShoulds = [
    ["I should know how to do this without help."],
    ["I should have finished already."],
    ["I should not take this long."],
    ["I should be faster."],
  ];

  const examplesShouldReplacements = [
    ["I would love to know how to do this without help."],
    ["I wish I had finished already."],
    ["I would like to have finished already."],
    ["I would prefer to be faster."],
  ];

  // I should:
  const initialshould = "already know this";
  // I would prefer to:

  return (
    <FrameworkShouldPrefer
      title={props.title}
      initialshould={initialshould}
      examplesShoulds={examplesShoulds}
      examplesShouldReplacements={examplesShouldReplacements}
    />
  );
}

export default SShouldPreferKnow;
