import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples.js";
import RenderSComponent from "./RenderSComponent.js";
import AddMultipleSteps from "./utils/AddMultipleSteps.js";
import { useTask } from "../../../util/db.js";
import { useAuth } from "../../../util/auth.js";
import { useParams } from "react-router-dom";
import SaveThoughtForm from "./utils/SaveThoughtForm.js";

function SEasyParts(props) {
  // Get task info (for steps)
  const { id } = useParams();
  const auth = useAuth();
  const { data: taskData } = useTask(auth.user.uid, id);

  const [easyParts, setEasyParts] = useState([]);

  // Handler to pass to the AddMultipleSteps component to keep track of items
  const handleAddEasyPart = (part) => {
    setEasyParts([...easyParts, part]);
  };

  // Examples for comfortable task aspects
  const examplesComfortableAspects = [
    "Organizing resources and references needed for the project.",
    "Creating a basic outline or draft of the project structure.",
    "Setting up meetings or discussions to clarify project goals.",
    "Researching and collecting data on similar projects for inspiration.",
  ];

  const examplesMotivatingThoughts = [
    [
      "By starting with tasks I'm comfortable with, like organizing resources, I'll build confidence and use that positive energy to tackle more complex aspects, reminding myself that progress in any form is valuable.",
    ],
    [
      "Focusing on the parts I'm confident about will give me a strong foundation, making it easier to approach the challenging sections with a mindset of curiosity and learning, rather than fear of failure.",
    ],
    [
      "Completing the easier tasks first will not only give me a sense of achievement but also clarify the next steps, making the overall project seem less daunting and more manageable.",
    ],
    [
      "The momentum from finishing the initial, less intimidating tasks will propel me forward, turning apprehension into action. Each completed task is a step closer to mastering the entire challenge.",
    ],
    [
      "By acknowledging what I can do now, I set the stage for tackling more difficult tasks later. This approach will help me maintain focus and momentum, transforming overwhelm into accomplishment.",
    ],
    [
      "Tackling the easy aspects first is like gathering steam for the uphill journey. With each small win, I'm not only closer to my goal but also better equipped mentally and emotionally to face tougher challenges.",
    ],
    [
      "The confidence gained from completing simpler tasks will serve as a reminder that I'm capable of more than I think. This thought will guide me as I approach the more demanding parts of the task with renewed vigor and determination.",
    ],
    [
      "Addressing the less challenging aspects initially allows me to create a positive feedback loop. As I progress, this loop reinforces my belief in my ability to overcome obstacles and reach the finish line, no matter how challenging the rest of the task may seem.",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <p>
            Feeling overwhelmed or intimidated by a task can hinder our ability
            to start and maintain momentum. Identifying and beginning with parts
            of the task that feel more manageable can help build confidence and
            propel us forward. This strategy guides you through pinpointing
            these aspects and leveraging them to overcome the initial hurdle.
          </p>
        </div>
      }
      steps={[
        <div>
          <div className="mb-4 boxStepSubtitle">
            Identify Manageable Aspects
          </div>
          <div className="columns">
            <div className="column is-6 m-2">
              <div className="mb-4">
                Think about the task <i>"{props.stepOrTask}"</i>. What are the
                <b> parts of it that you don't feel anxious about</b>? These can
                be small, simple tasks or aspects you're familiar with.
              </div>
              <AddMultipleSteps
                taskData={taskData}
                placeholder="Add a manageable aspect or step here..."
                onAdd={handleAddEasyPart}
              />
            </div>

            <div className="column examples">
              <div>Examples of manageable task aspects:</div>
              <CarouselExamples
                examples={examplesComfortableAspects.map((aspect) => [aspect])}
              />
            </div>
          </div>
        </div>,

        <div>
          <div className="mb-4 boxStepSubtitle">Plan Your Approach</div>
          <div className="columns m-2 mt-3">
            <div className="column is-7">
              <div className="recall p-2 m-2">
                Easy parts of the task that you identified:{" "}
                {easyParts.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </div>
              <div className="my-2 p-2">
                Now, craft a thought that will guide your approach.{" "}
                <b>
                  How will you use the momentum from tackling these easy aspects
                  to address the rest of the task?
                </b>{" "}
                This thought should motivate and remind you of your strategy to
                navigate through the challenge.
              </div>
              <SaveThoughtForm placeholder="By focusing on these easier parts first, I will..." />
            </div>
            <div className="column examples">
              <div>Examples of manageable task aspects:</div>
              <CarouselExamples
                examples={examplesMotivatingThoughts.map((thought) => [
                  thought,
                ])}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SEasyParts;
