import EvidenceFramework from "./FrameworkEvidence";

function SEvidenceSlow(props) {
  const thoughtToExamine = "I am too slow";

  // SEE CarouselAffirmationExamples to see how to fetch examples from firestore
  const examplesSupport = [
    [
      "I am consistently behind schedule on my work assignments.",
      "I take longer to complete tasks than my colleagues or peers.",
    ],
    [
      "I am easily distracted and find it hard to stay focused for long periods of time.",
      "I often need to reread or review information multiple times before it sinks in.",
    ],
    [
      "I tend to overthink or second-guess my decisions, which slows me down.",
      "I am not as efficient or organized as I would like to be.",
    ],
    [
      "I tend to get stuck on minor details and have trouble seeing the big picture.",
      "I am not as quick to learn new skills or techniques as I would like to be.",
    ],
  ];

  const examplesNotSupport = [
    [
      "I have been getting positive feedback and reviews.",
      "I haven't caused a project to fail because of deadline issues.",
    ],
    [
      "I am able to complete tasks accurately and thoroughly, even if it takes me longer than others.",
      "I may take a bit longer to get started, but once I'm in the zone, I can work quickly and efficiently.",
    ],
    [
      "I am someone who takes the time to understand things deeply, which can sometimes make me appear slow, but ultimately makes me more effective.",
      "Other people are not super fast either, and I still consider them great and effective professionals.",
    ],
    [
      "I prioritize attention to detail and accuracy, which can sometimes mean taking a bit more time to complete tasks.",
      "I may not be the fastest learner, but I am diligent and persistent in my efforts to master new skills.",
    ],
    [
      "I work at a steady and consistent pace, which may not be the fastest, but ensures that I complete tasks thoroughly and to the best of my ability.",
      "I am someone who values thoughtfulness and reflection, and takes the time to consider all options before making decisions.",
    ],
  ];

  const examplesThoughts = [
    [
      "While it's true that I sometimes feel like others finish before me and that I can lose time pondering things, it's important to also consider that my manager has not expressed concerns about my speed in a long time and I've been getting good reviews. This suggests that I may be performing adequately and that my concerns about falling behind may be influenced by self-doubt or an unrealistic expectation of myself.",
    ],
    [
      "While I may not be the most efficient worker, I am willing to work smarter, not harder, to find ways to streamline my work processes and increase my productivity.",
    ],
    [
      "I acknowledge that there may be areas where I need to improve my speed, but I am confident in my ability to identify and address these areas over time.",
    ],
    [
      "Although I sometimes feel like I am working too slowly, I know that taking the time to understand things thoroughly is important for producing good results.",
    ],
    [
      "While I may not be the fastest worker, I am dedicated to producing high-quality work that meets or exceeds expectations.",
    ],
    [
      "I recognize that I may take longer to complete tasks than others, but I am willing to put in the effort to improve my efficiency and productivity.",
    ],
    [
      "I understand that speed is important in some contexts, but I also value precision and accuracy in my work.",
    ],
    [
      "Although I sometimes feel like I am falling behind, I know that everyone works at their own pace, and it is important to focus on my own progress and improvement.",
    ],
    [
      "While speed is important, I also recognize that taking breaks and recharging my energy can be beneficial for my overall productivity and well-being.",
    ],
    [
      "I am willing to seek out feedback from colleagues and mentors to identify areas where I can improve my efficiency and effectiveness at work.",
    ],
    [
      "I know that I have valuable skills and strengths to offer, and I am committed to finding ways to leverage these strengths in my work, even if it takes me a bit longer to complete tasks.",
    ],
  ];

  return (
    <EvidenceFramework
      thoughtToExamine={thoughtToExamine}
      examplesSupport={examplesSupport}
      examplesNotSupport={examplesNotSupport}
      examplesThoughts={examplesThoughts}
    />
  );
}

export default SEvidenceSlow;
