import React, { useState } from "react";
import FormAlert from "../FormAlert";
import EditAccomplishmentModal from "./EditAccomplishmentModal";
import { useAuth } from "../../util/auth.js";
import {
  updateAccomplishment,
  deleteAccomplishment,
  useAccomplishmentsByOwner,
} from "../../util/db.js";
import "./CardAccomplishments.scss";

import { faTrophy, faHeart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import strategiesList from "../Process/strategiesListData";

function CarouselAccomplishments(props) {
  const auth = useAuth();

  const {
    data: accomplishments,
    status: accomplishmentsStatus,
    error: accomplishmentsError,
  } = useAccomplishmentsByOwner(auth.user.uid);

  const [creatingAccomplishment, setCreatingAccomplishment] = useState(false);

  const [updatingAccomplishmentId, setUpdatingAccomplishmentId] =
    useState(null);

  const [accompIndex, setAccompIndex] = useState(0);

  const accomplishmentsAreEmpty =
    !accomplishments || accomplishments.length === 0;

  const handlePrevNext = (move) => {
    let newIndex = accompIndex + move;

    if (newIndex >= accomplishments.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = accomplishments.length - 1;
    }
    setAccompIndex(newIndex);
  };

  const accomplishmentStrategy = strategiesList.rememberPastAccomplishment;

  return (
    <>
      <div className="box m-3">
        {accomplishmentsError && (
          <div className="mb-3 limitedWidth">
            <FormAlert type="error" message={accomplishmentsError.message} />
          </div>
        )}
        <label className="title is-4">Accomplishments to Remember</label>

        <button
          className="button is-outlined is-primary mx-3 my-1"
          onClick={() => {
            props.handlePreselectStrategy(accomplishmentStrategy);
            props.handleProcessFeeling(true);
          }}
        >
          Add
        </button>

        {(accomplishmentsStatus === "loading" || accomplishmentsAreEmpty) && (
          <div className="py-5 px-3">
            {accomplishmentsStatus === "loading" && (
              <div className="loader is-loading mx-auto" />
            )}

            {accomplishmentsStatus !== "loading" && accomplishmentsAreEmpty && (
              <div className="has-text-centered">
                Nothing yet. Click the button to add your first accomplishment.
              </div>
            )}
          </div>
        )}

        {accomplishmentsStatus !== "loading" &&
          accomplishments &&
          accomplishments.length > 0 && (
            <>
              <div className={"columns m-2"} key={accompIndex}>
                <div className="column is-6">
                  <div className="is-flex is-flex-direction-row">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      size="xl"
                      className="mr-2 mt-2 trophy"
                    />
                    {accomplishments[accompIndex].accomplished}
                  </div>
                </div>
                <div className="column is-5">
                  <div className="is-flex is-flex-direction-row">
                    <FontAwesomeIcon
                      icon={faHeart}
                      size="xl"
                      className="mr-2 mt-2 is-fontcolor-red"
                    />
                    <div className="is-flex is-flex-direction-column">
                      {accomplishments[accompIndex].abouts?.map((about) => {
                        return <div className="my-1">{about}</div>;
                      })}
                    </div>
                  </div>
                </div>
                <div className="column">
                  <button
                    className="button action is-ghost"
                    aria-label="view"
                    onClick={() =>
                      setUpdatingAccomplishmentId(
                        accomplishments[accompIndex].id
                      )
                    }
                  >
                    <span className="icon is-small">
                      <i className="fas fa-eye" />
                    </span>
                  </button>
                </div>
              </div>
              <div className={"p-3 is-flex is-justify-content-end"}>
                <button
                  className="button mx-1 is-light"
                  onClick={() => handlePrevNext(-1)}
                >
                  <i className="fa fa-chevron-left mx-2"></i>
                  Prev
                </button>
                <button
                  className="button mx-1 is-light"
                  onClick={() => handlePrevNext(1)}
                >
                  Next
                  <i className="fa fa-chevron-right mx-2"></i>
                </button>
              </div>
            </>
          )}
      </div>

      {creatingAccomplishment && (
        <EditAccomplishmentModal
          onDone={() => setCreatingAccomplishment(false)}
        />
      )}

      {updatingAccomplishmentId && (
        <EditAccomplishmentModal
          id={updatingAccomplishmentId}
          onDone={() => setUpdatingAccomplishmentId(null)}
        />
      )}
    </>
  );
}

export default CarouselAccomplishments;
