import FormField from "../FormField";
import { useForm } from "react-hook-form";

function CreateYourOwn(props) {
  const { register, handleSubmit, errors } = useForm();
  return (
    <>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <FormField
          name="content"
          type="textarea"
          placeholder="Thought"
          // defaultValue={
          //   "something when browsing, nothing when create your own"
          // }
          size="medium"
          error={errors.name}
          autoFocus={true}
          inputRef={register({
            required: "Please enter a thought",
          })}
        />
        <div className="field">
          <div className="control">
            <button
              className={
                "button is-medium is-primary" +
                (props.pending ? " is-loading" : "")
              }
              type="submit"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateYourOwn;
