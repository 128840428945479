import { useState } from "react";
import FCorporateMindset from "./FrameworkCoporateMindset";

function SCorporateHelp(props) {
  const [corporateName, setCorporateName] = useState("");

  // Handler for corporate name change
  const handleCorporateNameChange = (event) => {
    setCorporateName(event.target.value.trim() + ", Inc.");
  };

  const feelingExpressed = "I feel like I can't ask for help/make questions";

  const examplesChallenges = [
    "I kinda freeze up about asking for help, thinking it'll make me look like I don’t have my act together.",
    "I keep thinking that if I ask for help, I'll just be bugging my teammates or piling more on their plate.",
    "Asking questions feels like I'm waving a big 'I don’t get it' flag, and I really don’t want to come off as clueless.",
    "I worry my questions will throw a wrench in the works or bug my team when they’re swamped.",
    "Reaching out for a hand makes me feel like I'm not pulling my weight or I can't hack it on my own.",
    "I get super stressed about asking for help. Don’t want to seem needy or like I'm leaning too hard on others.",
  ];

  const examplesCorporateApproach = [
    `${corporateName} sees asking for help as a strategic consultation, seeking expertise to enhance our business solutions.`,
    `${corporateName} is all about hitting up the team for insights—it's like brainstorming with experts to make our stuff even better.`,
    `In ${corporateName}, helping out is part of the vibe. Nobody’s keeping score on who needs a hand.`,
    `${corporateName} digs getting different perspectives. It’s like adding secret sauce to what we do.`,
    `${corporateName} is big on not going it alone. We’re all about tapping into each other's strengths to get the job done right.`,
    `${corporateName} is all about learning as we go. If you need to ask a question to get the job done right, we're all for it.`,
    `${corporateName} loves questions. It’s how we learn and level up together, turning 'huh?' moments into 'aha!' moments.`,
    `${corporateName} is teamwork central. Asking for advice? That’s just us keeping our edge and staying fresh.`,
  ];

  return (
    <FCorporateMindset
      title={props.title}
      feelingExpressed={feelingExpressed}
      examplesChallenges={examplesChallenges}
      examplesCorporateApproach={examplesCorporateApproach}
      corporateName={corporateName}
      handleCorporateNameChange={handleCorporateNameChange}
    />
  );
}

export default SCorporateHelp;
