import React, { useState } from "react";
import { lightTheme } from "../../AppStyles";
import StepStyles from "./StepStyles";
import ConfettiExplosion from "react-confetti-explosion";
import throttle from "lodash.debounce";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import { Mixpanel } from "../../util/mixpanel";

interface StepComponentProps {
  step: any; // add props here
  updateCheckedStatus;
  deleteStep;
  focusStep;
  handleProcessFeeling;
  handlePreselectFeeling;
  handleForceEditTaskNotes;
  goToNextTourStep;
}

//import removeIcon from "@assets/removeIcon.svg";

function Step({
  step,
  updateCheckedStatus,
  deleteStep,
  focusStep,
  handleProcessFeeling,
  handlePreselectFeeling,
  handleForceEditTaskNotes,
  goToNextTourStep,
}: StepComponentProps) {
  const [beingRemoved, setBeingRemoved] = useState("");
  const [checked, setChecked] = useState(step.checked);

  const [isExploding, setIsExploding] = React.useState(false);

  function handleRemoveStep(id: string) {
    setBeingRemoved(id);
    waitForAnimationAndRemove(id); //done here
  }

  const waitForAnimationAndRemove = (id: string) => {
    setTimeout(() => {
      deleteStep(id);
    }, 250);
  };

  const throttledConfetti = throttle(
    function () {
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
      }, 1000);
    },
    4000,
    { leading: true }
  );

  function handleCheckedStatus() {
    if (!checked) {
      throttledConfetti();

      if (step.infoTrigger) {
        setTimeout(() => {
          handleForceEditTaskNotes(true, step.content);
        }, 600);
      }
    }
    setChecked(!checked);
    updateCheckedStatus(step, !checked);
  }

  return (
    <StepStyles
      beingRemoved={beingRemoved === step.id}
      checked={step.checked}
      theme={lightTheme}
      //key={step.id}
    >
      <div className="left">
        <button
          className="button action pr-0"
          onClick={() => {
            Mixpanel.track("Focus clicked from Step");
            focusStep(step.content);
          }}
        >
          Focus
        </button>

        <label>
          <input
            type="checkbox"
            defaultChecked={checked}
            onChange={() => handleCheckedStatus()}
          />
          {isExploding && (
            <ConfettiExplosion
              force={0.5}
              duration={2000}
              particleCount={50}
              floorHeight={800}
              floorWidth={500}
            />
          )}
          <div
            className={`checkbox-div ${step.infoTrigger ? "infoStep" : ""}`}
          />
        </label>
        <h3>
          <Interweave
            content={step.content}
            matchers={[new UrlMatcher("url")]}
            newWindow={true}
          />
        </h3>
      </div>

      <div className="right">
        <button
          className="button is-small exploreResistanceStep"
          onClick={() => {
            goToNextTourStep();
            handleProcessFeeling(true, step.content);
            Mixpanel.track("Explore Resistance clicked from Step");
            // handlePreselectFeeling({
            //   id: "innerResistance",
            //   title: "I feel inner resistance towards this task",
            // });
          }}
        >
          Explore Resistance
        </button>
        <button
          className="button action is-ghost"
          aria-label="delete"
          onClick={() => handleRemoveStep(step.id)}
        >
          <span className="icon is-small">
            <i className="fas fa-trash" />
          </span>
        </button>
      </div>
    </StepStyles>
  );
}

export default Step;
