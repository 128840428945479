import { useState } from "react";
import { v4 as uuid } from "uuid";
import { updateTask } from "../../util/db.js";
import { useAuth } from "../../util/auth.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

function DontKnowItem(props) {
  const auth = useAuth();
  const initialStep = `Figure out ${props.item.name} by `;
  const [savedStep, setSavedStep] = useState("none");
  const [stepInput, setStepInput] = useState(initialStep);
  const [pending, setPending] = useState(false);

  const handleChange = (event) => {
    setStepInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onAddStep();
      event.target.blur();
    }
  };

  const onAddStep = () => {
    setPending(true); //to make button appear loading

    const newStep = {
      checked: false,
      content: stepInput,
      id: uuid(),
      infoTrigger: true,
    };
    let newStepsData = props.taskData.stepsData.concat(newStep);

    const query = updateTask(auth.user.uid, props.taskData.id, {
      stepsData: newStepsData,
    });

    query.then(() => {
      setSavedStep(stepInput);
      setPending(false);
    });
  };

  const isStepSaved = () => {
    return savedStep === stepInput;
  };

  return (
    <div key={props.item.id} className="is-flex mb-3 is-align-items-center">
      <button
        className="button is-ghost"
        aria-label="delete"
        onClick={() => props.handleRemove(props.index)}
      >
        <FontAwesomeIcon icon={faTrash} size="s" className="mx-2 mt-1" />
      </button>
      <div className="mr-4">{props.item.name}</div>

      <input
        className={`input ${stepInput ? "" : "is-danger"}`}
        type="text"
        value={stepInput}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <div className="control">
        <button
          className={`button is-small m-2 ${
            isStepSaved() ? "is-static" : "is-primary"
          } ${pending ? " is-loading" : ""}`}
          onClick={onAddStep}
        >
          {isStepSaved() ? "Step Saved" : "Add as Step"}
        </button>
      </div>
    </div>
  );
}

export default DontKnowItem;
