import Board from "react-trello";
import { createContext, useState } from "react";
import TaskCard from "./TaskCard.js";
import TaskCardForm from "./TaskCardForm.js";
import { useAuth } from "../../../util/auth.js";
import { history } from "../../../util/router";
import {
  updateTask,
  createTaskWithId,
  updateProject,
  deleteTask,
} from "../../../util/db.js";
import { ConfirmDeleteModal } from "./widgets/ConfirmDeleteModal.js";

export const DoneTasksContext = createContext([]);

function TaskBoardView(props) {
  const auth = useAuth();
  const [doneTasks, setDoneTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteCallback, setDeleteCallback] = useState(null);

  function handleCardAdd(card, laneId) {
    createTaskWithId(auth.user.uid, {
      id: card.id,
      name: card.title,
      laneId: laneId, //status?
      completed: Boolean(false),
      urgency: card.urgency,
      goal: card.goal,
      resistance: card.resistance,
      level: card.level,
      projectId: props.selectedProjectId,
    });
    console.log("TaskBoardView handleCardAdd triggered, card: ", card);
  }

  function handleCardMoveAcrossLanes(fromLaneId, toLaneId, cardId, index) {
    if (toLaneId !== "done") {
      updateTask(auth.user.uid, cardId, { completed: false, laneId: toLaneId });
    }
    if (toLaneId === "done") {
      updateTask(auth.user.uid, cardId, { completed: true, laneId: toLaneId });
    }
  }

  function handleDataChange(newData) {
    updateProject(auth.user.uid, props.selectedProjectId, {
      boardData: newData,
    });
    console.log("TaskBoardView handleDataChange triggered, newData: ", newData);
    // populate doneCards in context and keep it up to date
    const doneCards = newData.lanes[2].cards;
    const doneCardIds = doneCards.map((card) => card.id);
    setDoneTasks(doneCardIds);
  }

  function handleDeleteCard(cardId, laneId) {
    deleteTask(auth.user.uid, cardId);
  }

  // function with callback to delete card
  function onBeforeCardDelete(deleteCallback) {
    setIsModalOpen(true);
    setDeleteCallback(() => deleteCallback);
  }

  function handleConfirm() {
    if (deleteCallback) {
      deleteCallback();
    }
    setIsModalOpen(false);
  }

  return (
    <>
      <DoneTasksContext.Provider value={doneTasks}>
        <Board
          editable
          components={{ Card: TaskCard, NewCardForm: TaskCardForm }}
          data={props.tasksBoardData}
          onCardAdd={handleCardAdd}
          onCardClick={(cardId) => {
            history.push({ pathname: "/app/task/" + cardId });
          }}
          onCardMoveAcrossLanes={handleCardMoveAcrossLanes}
          style={{ backgroundColor: "#f8f8ff", padding: "20px 20px" }}
          onDataChange={handleDataChange}
          onCardDelete={handleDeleteCard}
          onBeforeCardDelete={onBeforeCardDelete}
        />
      </DoneTasksContext.Provider>
      {isModalOpen && (
        <ConfirmDeleteModal
          setIsModalOpen={setIsModalOpen}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default TaskBoardView;
