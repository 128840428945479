import { useState } from "react";
import { useAuth } from "../../util/auth.js";
import Collapse from "../Collapse";
import { updateTask } from "../../util/db.js";
import Motivation from "./Motivation";
import UpdateMotivationModal from "./UpdateMotivationModal";
import FocusMotivationModal from "./FocusMotivationModal";
import feelingsList from "../Process/feelingsListData";
import { Mixpanel } from "../../util/mixpanel.js";

function CardTaskMotivation(props) {
  const auth = useAuth();

  const [focusingMotivation, setFocusingMotivation] = useState(false);
  //const [creatingMotivation, setCreatingMotivation] = useState(false);
  const [updatingMotivationIndex, setUpdatingMotivationIndex] = useState(null);
  const [updatingMotivationsData, setUpdatingMotivationsData] = useState([]);

  // const {
  //   data: affirmation,
  //   status: affirmationStatus,
  //   error: affirmationError,
  // } = useAffirmationForTask(props.affirmationId);

  //const goal = props.taskData?.projectGoal;
  const motivationsData = props.taskData?.motivationsData;

  //const [creatingAffirmation, setCreatingAffirmation] = useState(false);

  function handleUpdatingMotivation(index) {
    setUpdatingMotivationIndex(index);
    let dataToUpdate = motivationsData;

    setUpdatingMotivationsData(dataToUpdate);
  }

  function handleFocusMotivation(thoughtContent) {
    Mixpanel.track("Motivation focused", {
      motivation: thoughtContent,
    });
    setFocusingMotivation(thoughtContent);
  }

  function handleDeleteMotivation(index) {
    let newMotivationsData = motivationsData;
    newMotivationsData.splice(index, 1);
    updateTask(auth.user.uid, props.taskData?.id, {
      motivationsData: newMotivationsData,
    });
  }

  const motivationIsNotEmpty = motivationsData && motivationsData.length > 0;

  const motivationFeeling = feelingsList.find(
    (feeling) => feeling.id === "motivationNeed"
  );

  return (
    <>
      <Collapse
        title="Motivation"
        subtitle="Do you remember why you are doing this?"
        open={motivationIsNotEmpty}
      >
        <div>
          {motivationIsNotEmpty && (
            <>
              {motivationsData.map((motivation, index) => (
                <Motivation
                  key={motivation.id}
                  motivation={motivation}
                  index={index}
                  handleFocusMotivation={handleFocusMotivation}
                  handleDeleteMotivation={handleDeleteMotivation}
                  handleUpdatingMotivation={handleUpdatingMotivation}
                />
              ))}
            </>
          )}
        </div>
        <button
          className="button is-outlined is-primary"
          onClick={() => {
            props.handlePreselectFeeling(motivationFeeling);
            props.handleProcessFeeling(true);
          }}
        >
          Add Motivation
        </button>
      </Collapse>

      {updatingMotivationIndex !== null && (
        <UpdateMotivationModal
          motivationsData={updatingMotivationsData}
          onDone={() => setUpdatingMotivationIndex(null)}
          updatingMotivationIndex={updatingMotivationIndex}
          taskId={props.taskData?.id}
        />
      )}

      {focusingMotivation && (
        <FocusMotivationModal
          motivationContent={focusingMotivation}
          onDone={() => setFocusingMotivation(null)}
        />
      )}

      {/* {creatingAffirmation && (
        <EditAffirmationModal
          onDone={() => setCreatingAffirmation(false)}
          taskId={props.taskData?.id}
          affirmation={affirmation}
        />
      )} */}

      {/* {updatingAffirmationId && (
        <EditAffirmationModal
          id={updatingAffirmationId}
          onDone={() => setUpdatingAffirmationId(null)}
        />
      )} */}
    </>
  );
}

export default CardTaskMotivation;
