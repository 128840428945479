import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import SaveThoughtForm from "./utils/SaveThoughtForm";
import { faFaceThinking } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lowercapFirstLetter from "./utils/lowercapFirstLetter";

function SFlipIt(props) {
  const [taskInput, setTaskInput] = useState("");
  const onChangeTaskHandler = (event) => {
    setTaskInput(event.target.value);
  };

  const [desiredConditionInput, setDesiredConditionInput] = useState(
    props.initialDesiredCondition || undefined
  );
  const onChangeExcuseHandler = (event) => {
    setDesiredConditionInput(event.target.value);
  };

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            <p>
              Sometimes we <b>want to wait</b> for conditions to be{" "}
              <b>perfect before taking action</b>. We want to have all the
              answers and abilities needed before we feel safe to take even the
              first step. This exercise is designed to help you challenge those
              thoughts by <b>reversing</b> your thought pattern.
            </p>
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify specific action*/}
          <div className="boxStepSubtitle">What are you procrastinating?</div>
          <div className="columns">
            <div className="column is-7">
              Write down the specific task or step that you are putting off:
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  placeholder="I need to start working on..."
                  onChange={onChangeTaskHandler}
                  value={taskInput}
                />
              </div>
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Identify feeling*/}
          <div className="boxStepSubtitle">
            What are you telling yourself you need before taking action?
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              When you think of getting to work on "<i>{taskInput}</i>",{" "}
              <b>what do you tell yourself?</b>
              <div className="control mx-2 my-4">
                "Once I{" "}
                <input
                  className={`input is-small ${
                    desiredConditionInput ? "" : "is-danger"
                  } autoWidth`}
                  type="text"
                  autoComplete="off"
                  onChange={onChangeExcuseHandler}
                  value={desiredConditionInput}
                />
                , I will start making progress on{" "}
                {lowercapFirstLetter(taskInput)}."
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Here are some examples of preconditions others in your industry
                have set for themselves before they can begin:
              </div>
              <ul>
                {props.excuses.map((excuse) => {
                  return <li key={excuse}>{excuse}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>,

        <div>
          {/* 3rd step: flip it around*/}
          <div className="boxStepSubtitle">
            Flip around the cause and consequence
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6 mx-3">
              <div>
                Instead of waiting for the conditions to be perfect,{" "}
                <b>can taking action bring the desired condition?</b>{" "}
                {props.encourage}
              </div>
              <div className="my-3">
                Experiment now,{" "}
                <b>
                  flip it around and hold the thought on your mind to see if it
                  rings true
                </b>
                :
              </div>
              <SaveThoughtForm
                defaultValue={`Once I start making progress on ${lowercapFirstLetter(
                  taskInput
                )}, I will ${desiredConditionInput}.`}
              />
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by flips from others in your industry:
              </div>
              <CarouselExamples examples={props.examplesFlips} />
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default SFlipIt;
