import { useState } from "react";
import CreateThoughtModal from "./CreateThoughtModal";
import { updateTask, usePinnedByOwner, updatePinned } from "../../util/db.js";
import { useAuth } from "../../util/auth.js";
import "./CardThoughts.scss";
import FocusThoughtModal from "./FocusThoughtModal";
import Thought from "./Thought";
import UpdateThoughtModal from "./UpdateThoughtModal";
import { Mixpanel } from "../../util/mixpanel";

function CardThoughts({ thoughtsData, taskId }) {
  const auth = useAuth();

  const {
    data: pinned,
    status: pinnedStatus,
    error: pinnedError,
  } = usePinnedByOwner(auth.user.uid);

  const [focusingThought, setFocusingThought] = useState(false);

  const [creatingThought, setCreatingThought] = useState(false);
  const [updatingThoughtIndex, setUpdatingThoughtIndex] = useState(null);
  const [updatingThoughtIsPinned, setUpdatingThoughtIsPinned] = useState(false);
  const [updatingThoughtsData, setUpdatingThoughtsData] = useState([]);

  const [pending, setPending] = useState(false);

  // TODO test when pinned thoughts are empty AND new user
  const handlePinThought = (thought, index) => {
    // (if here, means thought is unpinned)
    // 1. create new pinned thought
    let pinnedThoughts =
      pinned?.pinnedThoughts?.length > 0 ? pinned?.pinnedThoughts : [];
    pinnedThoughts.push(thought);
    updatePinned(auth.user.uid, {
      pinnedThoughts: pinnedThoughts,
    });

    // 2. remove local task copy
    let newThoughtsData = thoughtsData;
    newThoughtsData.splice(index, 1);
    updateTask(auth.user.uid, taskId, {
      thoughtsData: newThoughtsData,
    });
  };

  const handleUnpinThought = (thought, index) => {
    // (if here, means thought is pinned)
    // 1. create local copy
    let newThoughtsData = thoughtsData?.length > 0 ? thoughtsData : [];
    newThoughtsData.push(thought);
    updateTask(auth.user.uid, taskId, {
      thoughtsData: newThoughtsData,
    });

    // 2. remove from pinned thoughts
    let pinnedThoughts =
      pinned?.pinnedThoughts?.length > 0 ? pinned?.pinnedThoughts : [];
    pinnedThoughts.splice(index, 1);
    updatePinned(auth.user.uid, {
      pinnedThoughts: pinnedThoughts,
    });
  };

  function handleUpdatingThought(index, isPinned) {
    setUpdatingThoughtIndex(index);
    setUpdatingThoughtIsPinned(isPinned);

    //data depends on whether thoughts comes from pin or not
    let dataToUpdate;
    if (isPinned) {
      dataToUpdate = pinned?.pinnedThoughts;
    } else {
      dataToUpdate = thoughtsData;
    }
    setUpdatingThoughtsData(dataToUpdate);
  }

  function handleFocusThought(thoughtContent) {
    Mixpanel.track("Thought focused", {
      thoughtContent: thoughtContent,
    });
    setFocusingThought(thoughtContent);
  }

  function handleDeleteThought(index, isPinned) {
    let newThoughtsData;
    if (isPinned) {
      newThoughtsData = pinned?.pinnedThoughts;
    } else {
      newThoughtsData = thoughtsData;
    }

    newThoughtsData.splice(index, 1);

    if (isPinned) {
      updatePinned(auth.user.uid, {
        pinnedThoughts: newThoughtsData,
      });
    } else {
      updateTask(auth.user.uid, taskId, {
        thoughtsData: newThoughtsData,
      });
    }
  }

  return (
    <div className="box m-3">
      <label className="title is-4">Thoughts to Hold</label>
      <button
        className="button is-outlined is-primary mx-3 my-1"
        onClick={() => setCreatingThought(true)}
      >
        Add
      </button>

      {pinned?.pinnedThoughts && pinned?.pinnedThoughts.length > 0 && (
        <>
          {pinned?.pinnedThoughts.map((thought, index) => (
            <Thought
              key={thought.id}
              thought={thought}
              index={index}
              handleFocusThought={handleFocusThought}
              handleDeleteThought={handleDeleteThought}
              handleUpdatingThought={handleUpdatingThought}
              handlePinThought={handleUnpinThought}
              isPinned={true}
            />
          ))}
        </>
      )}

      {thoughtsData && thoughtsData.length > 0 && (
        <>
          {thoughtsData.map((thought, index) => (
            <Thought
              key={thought.id}
              thought={thought}
              index={index}
              handleFocusThought={handleFocusThought}
              handleDeleteThought={handleDeleteThought}
              handleUpdatingThought={handleUpdatingThought}
              handlePinThought={handlePinThought}
              isPinned={false}
            />
          ))}
        </>
      )}

      {creatingThought && (
        <CreateThoughtModal
          thoughtsData={thoughtsData || []}
          onDone={() => setCreatingThought(false)}
          taskId={taskId}
        />
      )}

      {updatingThoughtIndex !== null && (
        <UpdateThoughtModal
          thoughtsData={updatingThoughtsData}
          onDone={() => setUpdatingThoughtIndex(null)}
          updatingThoughtIndex={updatingThoughtIndex}
          taskId={taskId}
          updatingThoughtIsPinned={updatingThoughtIsPinned}
        />
      )}

      {focusingThought && (
        <FocusThoughtModal
          thoughtContent={focusingThought}
          onDone={() => setFocusingThought(null)}
        />
      )}
    </div>
  );
}

export default CardThoughts;
