import React, { useState } from "react";
import FormAlert from "../FormAlert";
import FormField from "../FormField";
import { useAuth } from "../../util/auth.js";
import { useForm } from "react-hook-form";
import {
  useAccomplishment,
  updateAccomplishment,
  createAccomplishment,
} from "../../util/db.js";
import "./EditAccomplishmentModal.scss";
import SaveEditAccomplishmentForm from "./SaveEditAccomplishmentForm";

function EditAccomplishmentModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();

  // This will fetch accomplishment if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new accomplishment.
  const { data: accomplishmentData, status: accomplishmentStatus } =
    useAccomplishment(auth.user.uid, props.id);

  // If we are updating an existing accomplishment
  // don't show modal until accomplishment data is fetched.
  if (props.id && accomplishmentStatus !== "success") {
    return null;
  }

  // const onSubmit = (data) => {
  //   setPending(true);

  //   const query = props.id
  //     ? updateAccomplishment(auth.user.uid, props.id, data)
  //     : createAccomplishment(auth.user.uid, data);

  //   query
  //     .then(() => {
  //       // Let parent know we're done so they can hide modal
  //       props.onDone();
  //     })
  //     .catch((error) => {
  //       // Hide pending indicator
  //       setPending(false);
  //       // Show error alert message
  //       setFormAlert({
  //         type: "error",
  //         message: error.message,
  //       });
  //     });
  // };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="EditAccomplishmentModal__card card">
        <header className="card-header">
          <p className="card-header-title">
            {props.id && <>Update</>}
            {!props.id && <>Create</>}
            {` `}Accomplishment
          </p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <div className="card-content">
          <SaveEditAccomplishmentForm
            accomplishInput={accomplishmentData.accomplished}
            challengeList={accomplishmentData.challenges}
            effortList={accomplishmentData.efforts}
            aboutList={accomplishmentData.abouts}
            updating={true}
            accomplishId={props.id}
            onDone={() => props.onDone()}
          />
        </div>
      </div>
    </div>
  );
}

export default EditAccomplishmentModal;
