import { useState } from "react";
import CarouselExamples from "./utils/CarouselExamples";
import RenderSComponent from "./RenderSComponent.js";
import lowercapFirstLetter from "./utils/lowercapFirstLetter";
import AddMotivationForm from "./utils/AddMotivationForm";
import SaveEditAccomplishmentForm from "../../Accomplishments/SaveEditAccomplishmentForm";

function SRememberPastAccomplishment(props) {
  const [temp, setTemp] = useState("");

  // Accomplishment/Praise
  const [accomplishInput, setAccomplishInput] = useState("");
  const onChangeAccomplishHandler = (event) => {
    setAccomplishInput(event.target.value);
  };

  // Challenges
  const [challengeList, setChallengeList] = useState([]);
  const handleAddChallenge = () => {
    if (temp.length > 0) {
      setChallengeList(challengeList.concat(temp));
      setTemp("");
    }
  };
  const handleChangeChallenge = (event) => {
    setTemp(event.target.value);
  };
  const handleKeyDownChallenge = (event) => {
    if (event.key === "Enter") {
      handleAddChallenge();
    }
  };

  // Efforts
  const [effortList, setEffortList] = useState([]);
  const handleAddEffort = () => {
    if (temp.length > 0) {
      setEffortList(effortList.concat(temp));
      setTemp("");
    }
  };
  const handleChangeEffort = (event) => {
    setTemp(event.target.value);
  };
  const handleKeyDownEffort = (event) => {
    if (event.key === "Enter") {
      handleAddEffort();
    }
  };

  // Abouts
  const [aboutList, setAboutList] = useState([]);
  const handleAddAbout = () => {
    if (temp.length > 0) {
      setAboutList(aboutList.concat(temp));
      setTemp("");
    }
  };
  const handleChangeAbout = (event) => {
    setTemp(event.target.value);
  };
  const handleKeyDownAbout = (event) => {
    if (event.key === "Enter") {
      handleAddAbout();
    }
  };

  const examplesAccomplishments = [
    ["I created a prototype of the new feature"],
    ["I finished the project on time"],
    [
      "The GM praised me for my presentation on the challenges and solutions for the new technology",
    ],
    ["I got a promotion"],
  ];
  const examplesChallenges = [
    ["I didn't know the technology at all"],
    [
      "There was no spec",
      "I was the only engineer working on it",
      "Nobody in the team knew about this new technology",
    ],
  ];

  const examplesEfforts = [
    [
      "I designed it myself",
      "I worked long hours",
      "I researched and learned about the technology on my own",
    ],
    [
      "I asked for help",
      "I studied for hours about possible alternatives",
      "I worked with the team to find a solution",
    ],
  ];

  const examplesAbouts = [
    ["I am creative", "I am hard working", "I am resourceful"],
    ["I can learn fast", "I am dedicated", "I am smart"],
    [
      "I am a good problem solver",
      "I am a good communicator",
      "I am a good team player",
    ],
    [
      "I am an effective leader",
      "I am a good listener",
      "I can teach complex topics",
    ],
  ];

  return (
    <RenderSComponent
      title={props.title}
      intro={
        <div>
          <div className="block">
            Remembering your past accomplishments and giving yourself credit for
            achieving something you thought you couldn't do is empowering and
            confidence-boosting.
          </div>
        </div>
      }
      steps={[
        <div>
          {/* 1st step: Identify the accomplishment or praise*/}
          <div className="boxStepSubtitle">
            What did you <b>accomplish</b>?
          </div>
          <div className="columns">
            <div className="column is-6">
              Write down the <b>activity</b> you accomplished or the{" "}
              <b>praise</b> that you received:
              <div className="control m-2">
                <input
                  className="input"
                  type="text"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={onChangeAccomplishHandler}
                  value={accomplishInput}
                />
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by accomplishments from others in your industry:
              </div>
              <CarouselExamples examples={examplesAccomplishments} />
            </div>
          </div>
        </div>,

        <div>
          {/* 2nd step: Identify the challenges overcome*/}
          <div className="boxStepSubtitle">
            What <b>challenges</b> did you overcome?
          </div>
          <div className="recall my-2 mx-4 px-5 py-2">
            <div className="has-text-centered">
              <span className="has-text-weight-semibold">Accomplishment:</span>{" "}
              <i>{accomplishInput}</i>
            </div>
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              Write down the <b>obstacles</b> that were against you and the{" "}
              <b>challenges you had to overcome</b> to accomplish{" "}
              <i>{accomplishInput}</i>:
              <div className="is-flex is-align-items-center mt-2">
                <input
                  className="input"
                  autoComplete="off"
                  autoFocus={true}
                  type="text"
                  //placeholder=""
                  value={temp}
                  onChange={handleChangeChallenge}
                  onKeyDown={handleKeyDownChallenge}
                  onBlur={handleAddChallenge}
                />
                <button
                  className="button is-small mx-2 "
                  onClick={handleAddChallenge}
                >
                  Add
                </button>
              </div>
              <div className="p-2">
                {challengeList.map((item) => {
                  return <li>{item}</li>;
                })}
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by challenges others shared:
              </div>
              <CarouselExamples examples={examplesChallenges} />
            </div>
          </div>
        </div>,

        <div>
          {/* 3nd step: Identify the effort and work put in*/}
          <div className="boxStepSubtitle">
            How much <b>work and effort</b> did you have to put in?
          </div>
          <div className="recall my-2 mx-4 px-5 py-2">
            <div className="has-text-centered">
              <span className="has-text-weight-semibold">Accomplishment:</span>{" "}
              <i>{accomplishInput}</i>
            </div>
            <div className="columns mt-1">
              <div className="column is-6">
                <div className="has-text-weight-semibold">Challenges:</div>
                <div className="p-2 is-italic">
                  {challengeList.map((item) => {
                    return <li>{item}</li>;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              Write down <b>what you did</b> to overcome the challenges and be
              able to succeed:
              <div className="is-flex is-align-items-center mt-2">
                <input
                  className="input"
                  autoComplete="off"
                  autoFocus={true}
                  type="text"
                  //placeholder="I..."
                  value={temp}
                  onChange={handleChangeEffort}
                  onKeyDown={handleKeyDownEffort}
                  onBlur={handleAddEffort}
                />
                <button
                  className="button is-small mx-2 "
                  onClick={handleAddEffort}
                >
                  Add
                </button>
              </div>
              <div className="p-2">
                {effortList.map((item) => {
                  return <li>{item}</li>;
                })}
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by efforts others shared:
              </div>
              <CarouselExamples examples={examplesEfforts} />
            </div>
          </div>
        </div>,

        <div>
          {/* 4th step: Identify what is says about you*/}
          <div className="boxStepSubtitle">
            What does this say <b>about your character</b> and your{" "}
            <b>strengths</b>?
          </div>
          <div className="recall my-2 mx-4 px-5 py-2">
            <div className="has-text-centered">
              <span className="has-text-weight-semibold">Accomplishment:</span>{" "}
              <i>{accomplishInput}</i>
            </div>
            <div className="columns mt-1">
              <div className="column is-6">
                <div className="has-text-weight-semibold">Challenges:</div>
                <div className="p-2 is-italic">
                  {challengeList.map((item) => {
                    return <li>{item}</li>;
                  })}
                </div>
              </div>
              <div className="column is-6">
                <div className="has-text-weight-semibold">
                  Effort/work put in:
                </div>
                <div className="p-2 is-italic">
                  {effortList.map((item) => {
                    return <li>{item}</li>;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="columns m-2 mt-3">
            <div className="column is-6">
              Think about what accomplishing this, despite the challenges and
              hard work required,{" "}
              <b>reveals about your character and strengths</b>:
              <div className="is-flex is-align-items-center mt-2">
                <input
                  className="input"
                  autoComplete="off"
                  autoFocus={true}
                  type="text"
                  //placeholder="I..."
                  value={temp}
                  onChange={handleChangeAbout}
                  onKeyDown={handleKeyDownAbout}
                  onBlur={handleAddAbout}
                />
                <button
                  className="button is-small mx-2 "
                  onClick={handleAddAbout}
                >
                  Add
                </button>
              </div>
              <div className="p-2">
                {aboutList.map((item) => {
                  return <li>{item}</li>;
                })}
              </div>
            </div>
            <div className="column examples">
              <div className="has-text-weight-semibold subtitle is-6">
                Get inspired by what others shared:
              </div>
              <CarouselExamples examples={examplesAbouts} />
            </div>
          </div>
        </div>,
        <div>
          {/* 5th step: Save Accomplishment*/}
          <div className="boxStepSubtitle">Save your accomplishment</div>

          <SaveEditAccomplishmentForm
            accomplishInput={accomplishInput}
            challengeList={challengeList}
            effortList={effortList}
            aboutList={aboutList}
          />
        </div>,
      ]}
    />
  );
}

export default SRememberPastAccomplishment;
