import FrameworkTransform from "./FrameworkTransform";

function STransformComplicated(props) {
  const thoughtToTransform = "This is too complicated for me";

  // SEE CarouselAffirmationExamples to see how to fetch examples from firestore
  //   const examplesGiveUp = [
  //     ["This it too difficult for me."],
  //     ["I don't feel like doing this."],
  //     ["I will never figure this out."],
  //     ["I'll do it later."],
  //   ];

  const examplesTransform = [
    "I will remind myself that I have the capability to learn and grow. I got this.",
    "I may not understand this now, but I can start by researching and asking questions. I can break it down into smaller steps and take it one at a time.",
    "I will figure this out. I can learn the skills and gain the knowledge I need. If I start walking in the right direction, eventually I will get there. I can ask for help if I need to.",
    "I say that because I don't feel like doing this hard task, but that doesn't have to get in my way. I CAN do things that I don't feel like doing by taking one small step after the other.",
    "Telling myself that this is too complex for me prevents me from even trying. I have done lots of things that I thought I couldn't do. I'll start taking action now, one small step at a time to see if I can.",
    "I have anxiety about tackling this challenging task, but I can remind myself that making mistakes is part of the learning process. I will take it one step at a time, and with focus and determination, I will be able to learn and succeed.",
    "I can break this down into smaller, manageable tasks. I can ask for help or resources if needed. I have tackled difficult tasks before and I can do it again.",
    "I can find examples of people who have done this before and learn from their experiences. I can also practice and experiment until I figure it out.",
    "If I'm feeling overwhelmed, I can take a break and come back to it later with a fresh mind. I can also seek feedback and use it to improve my approach.",
    "I can learn this with effort and persistence.",
  ];

  return (
    <FrameworkTransform
      thoughtToTransform={thoughtToTransform}
      //   examplesGiveUp={examplesGiveUp}
      examplesTransform={examplesTransform}
    />
  );
}

export default STransformComplicated;
