import React from "react";
import { Mixpanel } from "../../util/mixpanel";
import SOpinion from "./Strategies/SOpinion";
import SFirstStep from "./Strategies/SFirstStep";
import SEvidenceComplicated from "./Strategies/SEvidenceComplicated";
import STransformComplicated from "./Strategies/STransformComplicated";
import STransformLater from "./Strategies/STransformLater";
import STransformNotFeelLikeIt from "./Strategies/STransformNotFeelLikeIt";
import STransformNotSmart from "./Strategies/STransformNotSmart";
import STransformNeverBetter from "./Strategies/STransformNeverBetter";
import SEvidenceSlow from "./Strategies/SEvidenceSlow";
import SEvidenceNotSmart from "./Strategies/SEvidenceNotSmart";
import SDistinguishNoiseSignal from "./Strategies/SDistinguishNoiseSignal";
import STraitAdmire from "./Strategies/STraitAdmire";
import SEvidenceCantAsk from "./Strategies/SEvidenceCantAsk";
import SFamiliarWork from "./Strategies/SFamiliarWork";
import SDistinguishCatastropheNoncritical from "./Strategies/SDistinguishCatastropheNoncritical";
import SFlipItOverwhelmed from "./Strategies/SFlipItOverwhelmed";
import SFlipItNotFeelLikeIt from "./Strategies/SFlipItNotFeelLikeIt";
import SFlipItSkills from "./Strategies/SFlipItSkills";
import SFlipItAnxious from "./Strategies/SFlipItAnxious";
import SShouldPreferFaster from "./Strategies/SShouldPreferFaster";
import SShouldPreferKnow from "./Strategies/SShouldPreferKnow";
import SMotivationSharedGoals from "./Strategies/SMotivationSharedGoals";
import SCompassionateFearFailure from "./Strategies/SCompassionateFearFailure";
import SFlipItNeverBetter from "./Strategies/SFlipItNeverBetter";
import SWorriesIntoVision from "./Strategies/SWorriesIntoVision";
import SRememberPastAccomplishment from "./Strategies/SRememberPastAccomplishment";
import SOvercomeWorstFear from "./Strategies/SOvercomeWorstFear";
import SPutFeelingInQuotes from "./Strategies/SPutFeelingInQuotes";
import SReframeNervousness from "./Strategies/SReframeNervousness";
import SLearningOpportunity from "./Strategies/SLearningOpportunity";
import SEasyParts from "./Strategies/SEasyParts";
import SCorporateMindset from "./Strategies/SCorporateMindset";
import SCorporateHelp from "./Strategies/SCorporateHelp";
import SCorporateIncompetent from "./Strategies/SCorporateIncompetent";

// ID of strategy (needs to be added to StrategiesListData.js) :: REACT COMPONENT for strat
const components = {
  opinion: SOpinion,
  procrastep: SFirstStep,
  distinguishNoiseSignal: SDistinguishNoiseSignal,
  evidenceComplicated: SEvidenceComplicated,
  evidenceSlow: SEvidenceSlow,
  evidenceNotSmart: SEvidenceNotSmart,
  transformComplicated: STransformComplicated,
  transformLater: STransformLater,
  transformNotFeelLikeIt: STransformNotFeelLikeIt,
  transformNotSmart: STransformNotSmart,
  transformNeverBetter: STransformNeverBetter,
  traitAdmire: STraitAdmire,
  evidenceCantAsk: SEvidenceCantAsk,
  familiarWork: SFamiliarWork,
  distinguishCatastropheNoncritical: SDistinguishCatastropheNoncritical,
  flipItOverwhelmed: SFlipItOverwhelmed,
  flipItNotFeelLikeIt: SFlipItNotFeelLikeIt,
  flipItSkills: SFlipItSkills,
  flipItAnxious: SFlipItAnxious,
  flipItNeverBetter: SFlipItNeverBetter,
  shouldPreferFaster: SShouldPreferFaster,
  shouldPreferKnow: SShouldPreferKnow,
  motivationSharedGoals: SMotivationSharedGoals,
  compassionateFearFailure: SCompassionateFearFailure,
  worriesIntoVision: SWorriesIntoVision,
  rememberPastAccomplishment: SRememberPastAccomplishment,
  overcomeWorstFear: SOvercomeWorstFear,
  putFeelingInQuotes: SPutFeelingInQuotes,
  reframeNervousness: SReframeNervousness,
  learningOpportunity: SLearningOpportunity,
  easyParts: SEasyParts,
  corporateMindset: SCorporateMindset,
  corporateHelp: SCorporateHelp,
  corporateIncompetent: SCorporateIncompetent,
};

function StrategyComponents(props) {
  Mixpanel.track("Strategy loaded", {
    strategy: props.strategy?.title,
  });
  if (props.strategy?.id) {
    const SpecificStrategy = components[props.strategy?.id];
    return (
      <SpecificStrategy
        title={props.strategy?.title}
        stepOrTask={props.stepOrTask}
      />
    );
  } else {
    return <div>Strategy creation in progress.</div>;
  }
}

export default StrategyComponents;
