import { useState, useEffect } from "react";
import { requireAuth, useAuth } from "../../util/auth.js";
import "./onboarding.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useRouter } from "./../../util/router.js";
import StepOne from "./StepOne.js";
import StepTwo from "./StepTwo.js";
import StepThree from "./StepThree.js";

function OnboardingCard(props) {
  // Get authenticated user
  const auth = useAuth();
  const router = useRouter();
  useEffect(() => {
    // Redirect if already onboarded
    if (auth.user.onboarded === true) {
      router.replace("/app/tasks?tour=true");
    }
  }, [auth]);

  const [step, setStep] = useState(1);

  function handleNext() {
    setStep(step + 1);
  }

  return (
    <div class="onboardingContainer is-flex is-justify-content-start is-align-items-center is-flex-direction-column">
      <article class="message is-info mobile-only">
        <div class="message-body">
          <strong>FocusFeel may work better in a larger window.</strong> We are
          currently in beta and our layout works better in a desktop browser
          window for now. Improved mobile layout coming soon!
        </div>
      </article>
      <div className="is-flex is-flex-direction-row mt-5 mx-3 has-text-dark has-text-centered">
        {step > 1 && (
          <FontAwesomeIcon icon={faCheck} size="s" className="mt-1 mr-2" />
        )}
        <div className={`mr-3 ${step === 1 ? "has-text-weight-semibold" : ""}`}>
          About yourself
        </div>
        <div className="mr-3">></div>
        {step > 2 && (
          <FontAwesomeIcon icon={faCheck} size="s" className="mt-1 mr-2" />
        )}
        <div className={`mr-3 ${step === 2 ? "has-text-weight-semibold" : ""}`}>
          About your experiences
        </div>
        <div className="mr-3">></div>
        <div className={`${step === 3 ? "has-text-weight-semibold" : ""}`}>
          Getting started with FocusFeel
        </div>
      </div>
      <div className="card onboardingCard m-4">
        <div className="card-content">
          {step === 1 && <StepOne handleNext={handleNext} />}
          {step === 2 && <StepTwo handleNext={handleNext} />}
          {step === 3 && <StepThree handleNext={handleNext} />}
        </div>
      </div>
    </div>
  );
}

export default requireAuth(OnboardingCard);
