import { useState } from "react";
import FormField from "../FormField";
import { useForm } from "react-hook-form";
import { usePublicThoughts } from "../../util/db";

function BrowsePopular(props) {
  const {
    data: publicThoughts,
    status: publicThoughtsStatus,
    error: publicThoughtsError,
  } = usePublicThoughts();

  const [thoughtIndex, setThoughtIndex] = useState(0);

  const handlePrevNext = (move) => {
    let newIndex = thoughtIndex + move;

    if (newIndex >= publicThoughts.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = publicThoughts.length - 1;
    }
    setThoughtIndex(newIndex);
  };

  const { register, handleSubmit, errors } = useForm();
  return (
    <>
      <div className={"p-3 is-flex is-justify-content-center"}>
        <button
          className="button mx-1 is-light"
          onClick={() => handlePrevNext(-1)}
        >
          <i className="fa fa-chevron-left mx-2"></i>
          Prev
        </button>
        <button
          className="button mx-1 is-light"
          onClick={() => handlePrevNext(1)}
        >
          Next
          <i className="fa fa-chevron-right mx-2"></i>
        </button>
      </div>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <>
          <FormField
            name="content"
            type="textarea"
            placeholder="Thought"
            defaultValue={
              publicThoughts && publicThoughts[thoughtIndex]?.content
            }
            size="medium"
            error={errors.name}
            autoFocus={true}
            inputRef={register({
              required: "Please enter a thought",
            })}
          />

          <div className="field">
            <div className="control">
              <button
                className={
                  "button is-medium is-primary" +
                  (props.pending ? " is-loading" : "")
                }
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </>
      </form>
    </>
  );
}

export default BrowsePopular;
