import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import FocusStepModal from "./FocusStepModal";
import { useAuth } from "./../../util/auth.js";
import debounce from "lodash.debounce";
import firebase from "../../util/firebase";
import { lightTheme } from "../../AppStyles";
import MainStyles from "./MainStyles";
import Step from "./Step";
import { v4 as uuid } from "uuid";
import Collapse from "../Collapse";
import BrowseTemplatesModal from "./BrowseTemplatesModal";
import { Mixpanel } from "../../util/mixpanel";

const firestore = firebase.firestore();

function TaskSteps(props) {
  const auth = useAuth();
  const [stepsData, setStepsData] = useState(props.stepsData || []);

  const debouncedSaveStepsDataToDb = useCallback(
    debounce((newStepsData) => {
      firestore
        .collection("users")
        .doc(auth.user.uid)
        .collection("tasks")
        .doc(props.taskId)
        .update({ stepsData: newStepsData });
    }, 1000),
    []
  );

  //hook that runs when tree data changes and updates task data
  useEffect(() => {
    debouncedSaveStepsDataToDb(stepsData || []);
  }, [stepsData]);

  // //hook that re-renders once db is updated from somewhere else
  //(such as in a process feelings exercise)
  useEffect(() => {
    setStepsData(props.stepsData);
  }, [props.stepsData]);

  const [focusingStep, setFocusingStep] = useState(null);

  const [browsingTemplates, setBrowsingTemplates] = useState(null);

  const [checked, setChecked] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  function handleAddStep(e) {
    e.preventDefault();
    const content = inputRef.current?.value;
    if (!content) return;
    const newStep = {
      checked: checked,
      content: content,
      id: uuid(),
    };
    setStepsData((stepsData) => [...stepsData, newStep]); //temp
    Mixpanel.track("Step added", {
      step: content,
    });
    props.goToNextTourStep(); // for tour
    formRef.current?.reset();
  }

  function handleUpdateCheckedStatus(updatedStep, checked) {
    setStepsData((prevSteps) =>
      prevSteps.map((step) => {
        if (step.id === updatedStep.id) {
          return {
            ...updatedStep,
            checked: checked,
          };
        }
        return step;
      })
    );
  }

  function handleAddTemplateSteps(templateSteps) {
    const newSteps = templateSteps.map((step) => {
      return {
        checked: false,
        content: step.content,
        id: uuid(),
      };
    });
    setStepsData((stepsData) => [...stepsData, ...newSteps]);
  }

  function handleDeleteStep(id) {
    setStepsData((prevSteps) => prevSteps.filter((step) => step.id !== id));
  }

  function handleFocusStep(stepContent) {
    Mixpanel.track("Step focused", {
      step: stepContent,
    });
    setFocusingStep(stepContent);
  }

  function handleChangeStepsOrder(result) {
    if (!result.destination) return;

    const items = Array.from(stepsData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    changeStepsOrder(items);
  }

  const changeStepsOrder = (result) => {
    var index = 0;
    setStepsData(
      stepsData.map((step) => {
        step = result[index];
        index++;
        return step;
      })
    );
  };

  return (
    <>
      <Collapse
        title="Actionable Steps"
        subtitle="What is the next right thing to do?"
        open={true}
      >
        <div className="is-flex is-flex-direction-column">
          {/* <div className="py-3 px-4 is-flex is-justify-content-right is-align-items-center">
            <div className="mx-2 is-size-6">Need inspiration?</div>
            <button
              className="button is-primary"
              onClick={() => setBrowsingTemplates(true)}
            >
              Browse Steps Templates
            </button>
          </div> */}

          <MainStyles theme={lightTheme} checked={checked}>
            <div id="tasks-area-wrapper">
              <div
                id="task-writer"
                className="task-writer"
                onClick={() => inputRef.current?.focus()}
              >
                <div className="left">
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <div className="checkbox-div" />
                  </label>
                  <form noValidate onSubmit={handleAddStep} ref={formRef}>
                    <input
                      type="text"
                      autoFocus={true}
                      placeholder="Add the next tiniest possible step..."
                      ref={inputRef}
                    />
                  </form>
                </div>
              </div>
              {stepsData && (
                <DragDropContext onDragEnd={handleChangeStepsOrder}>
                  <Droppable droppableId="droppable-tasks">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {stepsData.map((step, index) => (
                          <div key={step.id}>
                            <Draggable
                              key={step.id}
                              draggableId={step.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                >
                                  <Step
                                    step={step}
                                    updateCheckedStatus={
                                      handleUpdateCheckedStatus
                                    }
                                    deleteStep={handleDeleteStep}
                                    focusStep={handleFocusStep}
                                    handleProcessFeeling={
                                      props.handleProcessFeeling
                                    }
                                    handlePreselectFeeling={
                                      props.handlePreselectFeeling
                                    }
                                    handleForceEditTaskNotes={
                                      props.handleForceEditTaskNotes
                                    }
                                    goToNextTourStep={props.goToNextTourStep}
                                  />
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
          </MainStyles>
        </div>
      </Collapse>

      {focusingStep && (
        <FocusStepModal
          stepName={focusingStep}
          onDone={() => setFocusingStep(null)}
        />
      )}

      {browsingTemplates && (
        <BrowseTemplatesModal
          onDone={() => setBrowsingTemplates(null)}
          handleAddTemplateSteps={handleAddTemplateSteps}
        />
      )}
    </>
  );
}

export default TaskSteps;
