import "./BrowseTemplatesModal.scss";
import BrowseTemplatesPage from "./BrowseTemplatesPage";

function BrowseTemplatesModal(props) {
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="BrowseTemplatesModal__card card">
        <header className="card-header">
          <p className="card-header-title">Browse Steps Templates</p>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>
        <BrowseTemplatesPage
          onDone={props.onDone}
          handleAddTemplateSteps={props.handleAddTemplateSteps}
        />

        <footer className="modal-card-foot justify-end">
          <button className="button is-success" onClick={() => props.onDone()}>
            I'm ready to get back to task!
          </button>
        </footer>
      </div>
    </div>
  );
}

export default BrowseTemplatesModal;
