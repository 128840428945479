import FrameworkFlipIt from "./FrameworkFlipIt";

function SFlipItSkills(props) {
  const examplesFlips = [
    [
      "Once I start working on building the payments infra, I will acquire the skills and knowledge I need.",
    ],
    ["Once I start working on the design doc, I will feel less anxious."],
    [
      "Once I start working on sending an email to ask for help, I will feel braver.",
    ],
    ["Once I start working on rewriting the tests, I will feel less bored."],
  ];

  const conditions = [
    "know more",
    "learn X",
    "feel more interested",
    "feel less anxious",
    "feel more confident",
    "have more energy",
    "feel braver",
    "feel ready",
    "feel less tired",
  ];

  //const behavior = "start working on it";

  const initialDesiredCondition = "acquire the skills";

  return (
    <FrameworkFlipIt
      title={props.title}
      examplesFlips={examplesFlips}
      excuses={conditions}
      //   behavior={behavior}
      initialDesiredCondition={initialDesiredCondition}
      encourage={
        "Often, the best way to acquire new skills is to just try it out and learn on the go."
      }
    />
  );
}

export default SFlipItSkills;
