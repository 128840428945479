import FrameworkTransform from "./FrameworkTransform";

function STransformLater(props) {
  const thoughtToTransform = "I'll do it later";

  const examplesTransform = [
    "I'll do it now. I need to remind myself of all the reasons why this needs to be done. It cannot wait. If I do it later, then everything I want to gain cannot happen.",
    "The sooner I start, the sooner I'll finish. I'll feel a sense of accomplishment once it's done.",
    "I'll start working on it now, even if it's just a small step.",
    "I'll remind myself of the benefits of completing the task and use that as motivation to start now.",
    "I'll remind myself that the discomfort I'm feeling towards getting started is only temporary, but the benefits of starting and completing the task will last longer.",
    "Procrastinating won't make the task go away. I'll feel better once I've completed it.",
    "I can break the task down into smaller, manageable parts and start working on them.",
    "I'll set a specific deadline for myself and work towards meeting it.",
  ];

  return (
    <FrameworkTransform
      thoughtToTransform={thoughtToTransform}
      examplesTransform={examplesTransform}
    />
  );
}

export default STransformLater;
