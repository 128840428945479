import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faArrowRight,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";
import SaveThoughtForm from "./utils/SaveThoughtForm";

function FrameworkTransform({ thoughtToTransform, examplesTransform }) {
  const { prev, next, hasNext, hasPrev } = useSteps();

  return (
    <>
      <div className="block">
        <h1 className="title is-3">
          Transform a discouraging thought into one that compels you to take
          action
        </h1>
      </div>
      <div className="block">
        <b>"{thoughtToTransform}"</b> is a common thought for many in your
        industry.{" "}
        <p>
          But let's replace that negative thought that holds you back with a
          more helpful one that drives you to action:
        </p>
      </div>

      <div className={"box"}>
        <Steps>
          {/* first step, analyze thought*/}
          <div>
            <div className="subtitle">Thought: "{thoughtToTransform}"</div>
            <div className="m-2">
              Let's examine how this thought is <b>holding you back</b>. Check
              all that apply:
            </div>

            <div className="ml-3">
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" className="mx-2" />
                    Holds a pessimistic view of the future, which drains my
                    motivation to take action
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" className="mx-2" />
                    Makes me feel like taking action is futile{" "}
                    <i>"Why even bother?"</i>
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" className="mx-2" />
                    It is based in fear{" "}
                    <i>"Something bad will happen if I try."</i>
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" className="mx-2" />
                    Encourages me to avoid taking action
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd step, question */}

          {/* 3rd step, transform thought */}
          <div>
            <div className="subtitle">Thought: "{thoughtToTransform}"</div>
            <div className="m-3">
              <div className="subtitle mt-3">
                Write a replacement thought that{" "}
                <b>compels you to take action</b>:
              </div>
              <div className="p-3 is-neutralLighter"></div>
              <div className="columns m-2 mt-3">
                <div className="column is-6">
                  <div className="mb-2">
                    Make sure your replacement thought drives you to action and
                    helps you reach your goals:
                  </div>
                  <SaveThoughtForm
                    defaultValue={`Every time I think "${thoughtToTransform}" I will instead think`}
                  />
                </div>
                <div className="column examples">
                  <div className="has-text-weight-semibold subtitle is-6">
                    Get inspired by thought transformations from others in your
                    industry:
                  </div>
                  <CarouselEvidenceExamples
                    examples={examplesTransform}
                    thoughtToTransform={thoughtToTransform}
                  />
                </div>
              </div>
            </div>
          </div>
        </Steps>
        <div>
          <div className={"p-3 is-flex"}>
            <button
              className="button mx-1 is-light"
              onClick={prev}
              disabled={!hasPrev}
            >
              <i className="fa fa-chevron-left mx-2"></i>
              Prev
            </button>
            <button
              className="button mx-1 is-light"
              onClick={next}
              disabled={!hasNext}
            >
              Next
              <i className="fa fa-chevron-right mx-2"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function CarouselEvidenceExamples(props) {
  const [exampleIndex, setExampleIndex] = useState(0);

  const handlePrevNext = (move) => {
    let newIndex = exampleIndex + move;

    if (newIndex >= props.examples.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = props.examples.length - 1;
    }
    setExampleIndex(newIndex);
  };

  return (
    <>
      {props.examples && props.examples.length > 0 && (
        <>
          <div className="m-3">
            <div className="">
              <div>
                <span className="has-text-weight-light">
                  {props.thoughtToTransform}
                </span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="s"
                  className="mx-2 mt-1"
                />{" "}
                {props.examples[exampleIndex]}
              </div>
            </div>
          </div>

          <div className={"p-3 is-flex is-justify-content-end"}>
            <button
              className="button mx-1 is-light"
              onClick={() => handlePrevNext(-1)}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="s"
                className="mx-2 mt-1"
              />
            </button>
            <button
              className="button mx-1 is-light"
              onClick={() => handlePrevNext(1)}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="s"
                className="mx-2 mt-1"
              />
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default FrameworkTransform;
