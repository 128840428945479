import { useState } from "react";
import "./ProcessFeelingModal.scss";
import ProcessFeelingPage from "./ProcessFeelingPage";
import SuggestFeelingModal from "./SuggestFeelingModal";

function ProcessFeelingModal(props) {
  const [isSuggesting, setIsSuggesting] = useState(false);

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => props.onDone()} />
      <div className="ProcessFeelingModal__card card">
        <header className="card-header">
          <div className="card-header-title">
            Process feeling for {props.stepToProcess && "step"}
            {!props.stepToProcess && "task"}:
            <span className="has-text-weight-light mx-1 has-text-info">
              {props.stepToProcess || props.taskName}
            </span>
          </div>
          <span className="card-header-icon">
            <button
              className="delete"
              aria-label="close"
              onClick={() => props.onDone()}
            >
              Done
            </button>
          </span>
        </header>

        <ProcessFeelingPage
          preselectedFeeling={props.preselectedFeeling}
          preselectedStrategy={props.preselectedStrategy}
          handlePreselectFeeling={props.handlePreselectFeeling}
          handlePreselectStrategy={props.handlePreselectStrategy}
          taskCategory={props.taskCategory}
          taskId={props.taskId}
          stepOrTask={props.stepToProcess || props.taskName}
        />
        <footer className="modal-card-foot justify-end is-justify-content-space-between">
          <button
            className="button is-info is-justify-content-start"
            onClick={() => setIsSuggesting(true)}
          >
            Suggest a Feeling or Strategy
          </button>
          <button className="button is-primary" onClick={() => props.onDone()}>
            I'm ready to get back to task!
          </button>
        </footer>
      </div>
      {isSuggesting && (
        <SuggestFeelingModal onDone={() => setIsSuggesting(false)} />
      )}
    </div>
  );
}

export default ProcessFeelingModal;
