import React from "react";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <PricingSection
      color="white"
      size="medium"
      backgroundImage=""
      backgroundImageOpacity={1}
      title="Get FocusFeel and boost your productivity"
      //title="Unleash your Productivity by Harnessing your Emotions"
      //subtitle="Overcome Anxiety and Boost your Productivity"
      subtitle="Procrastination is about avoiding a feeling"
      subtitle2="Free 14-day trial · Full functionality"
    />
  );
}

export default PricingPage;
