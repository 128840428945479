export const Urgency = {
  Not: "1",
  Medium: "2",
  High: "3",
};

export const GoalRelated = {
  Not: "1",
  Indirect: "2",
  Help: "3",
  Critical: "4",
};

export const InnerResistance = {
  Big: "1",
  Some: "2",
  Not: "3",
  Forward: "4",
};

export const Level = {
  Deep: "1",
  Shallow: "3",
};
