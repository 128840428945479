import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import "./EditableItem.scss";

function EditableItem({ item, index, handleEdit }) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(item.name);

  const handleClick = () => {
    setIsEditing(true);
  };

  const onChange = (event) => {
    setTempValue(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onFinishEdit(e.target.value);
    }
  };

  function onFinishEdit(value) {
    setIsEditing(false);
    handleEdit(value, index);
  }

  return (
    <>
      {isEditing ? (
        <textarea
          autoFocus
          value={tempValue}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => onFinishEdit(e.target.value)}
          type="text"
        />
      ) : (
        <div onClick={handleClick}>
          <FontAwesomeIcon icon={faCheck} size="xs" className="mx-2 mt-1" />
          <Interweave
            content={item.name}
            matchers={[new UrlMatcher("url")]}
            newWindow={true}
          />
        </div>
      )}
    </>
  );
}

export default EditableItem;
